import * as React from 'react';

import { MarketingCard } from '@/components/marketing';
import { method } from '@/data/method';

export const TheMethod = React.memo(() => {
  return (
    <div className='max-w-7xl mx-auto px-6 md:px-12 gap-8 sm:gap-12 lg:gap-x-20 grid sm:grid-cols-2 lg:grid-cols-3'>
      {method.map((item) => (
        <MarketingCard copy={item.copy} heading={item.heading} image={item.image} key={item.heading} />
      ))}
    </div>
  );
});

TheMethod.displayName = 'TheMethod';
