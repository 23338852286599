import * as React from 'react';

export const Period = React.memo<React.ComponentPropsWithoutRef<'svg'> & { size?: string | number | undefined }>(
  ({ size, width, height, ...rest }) => (
    <svg
      fill='none'
      height={size || height || 24}
      viewBox='0 0 24 24'
      width={size || width || 24}
      xmlns='http://www.w3.org/2000/svg'
      {...rest}
    >
      <path
        clipRule='evenodd'
        d='M20.7524 12.0003C20.7524 16.8134 16.8455 20.7332 12.0359 20.7525H12C7.17396 20.7525 3.24764 16.8253 3.24764 12.0002C3.24764 11.0736 3.3926 10.1805 3.66034 9.3426C4.30776 9.26218 4.80824 8.71053 4.80824 8.04121C4.80824 7.78014 4.73152 7.5379 4.60079 7.33466C6.15436 4.87914 8.88993 3.24797 12.0001 3.24797C16.8261 3.24797 20.7524 7.17519 20.7524 12.0003ZM2 12C2 10.9135 2.1752 9.86693 2.49686 8.88581C2.30366 8.6597 2.18435 8.36584 2.18435 8.03998C2.18435 7.31526 2.77111 6.7285 3.49583 6.7285L3.5083 6.72856C5.27403 3.89414 8.41807 2 12 2H12.5263V2.01374C17.7971 2.28829 22 6.66203 22 12C22 17.5146 17.5145 22 12 22C6.48546 22 2 17.5148 2 12ZM17.8518 12.4971C17.6975 12.3374 17.6014 12.12 17.6014 11.877C17.6014 11.6471 17.6879 11.4394 17.8287 11.2822C17.4742 8.38097 14.9951 6.12588 12.0003 6.12588C8.76216 6.12588 6.12719 8.76162 6.12719 12C6.12719 15.2242 8.73919 17.8511 11.9579 17.8739H12.0005C15.0701 17.8739 17.5971 15.5042 17.8518 12.4971ZM18.8579 11.9643C18.8613 11.9504 18.8638 11.9363 18.8655 11.9219C18.8669 11.91 18.8677 11.898 18.8679 11.8858C18.8674 11.913 18.8639 11.9393 18.8578 11.9647L18.8579 11.9643ZM19.099 12.5418C18.8202 16.2158 15.7453 19.1207 12.0003 19.1207C8.0736 19.1207 4.87957 15.9267 4.87957 11.9999C4.87957 8.07321 8.0736 4.87919 12.0003 4.87919C15.6542 4.87919 18.6707 7.64468 19.0736 11.1927C19.269 11.3565 19.3942 11.6022 19.3942 11.8789C19.3942 12.1437 19.2794 12.379 19.099 12.5418ZM14.75 12C14.75 13.5188 13.5188 14.75 12 14.75C10.4812 14.75 9.25 13.5188 9.25 12C9.25 10.4812 10.4812 9.25 12 9.25C13.5188 9.25 14.75 10.4812 14.75 12ZM16 12C16 14.2091 14.2091 16 12 16C9.79086 16 8 14.2091 8 12C8 9.79086 9.79086 8 12 8C14.2091 8 16 9.79086 16 12Z'
        fill='currentColor'
        fillRule='evenodd'
      />
    </svg>
  ),
);

Period.displayName = 'Period';
