import * as React from 'react';

import { MobileMenu } from '@/components/layout/MobileMenu';
import { useUIDispatch } from '@/components/ui';

export const MobileMenuButton = React.memo(() => {
  const uiDispatch = useUIDispatch();

  return (
    <button
      className='lg:hidden focus:outline-none'
      onClick={() =>
        uiDispatch({
          type: 'DRAWER_OPEN',
          content: <MobileMenu />,
          className: 'flex flex-col justify-center bg-gradient-purple w-full',
        })
      }
      title='menu'
    >
      <svg fill='none' height='18' viewBox='0 0 24 18' width='24' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M1 5C1 5 3.13401 1 7 1C10.866 1 13.134 5 17 5C20.866 5 23 1 23 1'
          stroke='#45525F'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1.25'
        />
        <path
          d='M1 11C1 11 3.13401 7 7 7C10.866 7 13.134 11 17 11C20.866 11 23 7 23 7'
          stroke='#45525F'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1.25'
        />
        <path
          d='M1 17C1 17 3.13401 13 7 13C10.866 13 13.134 17 17 17C20.866 17 23 13 23 13'
          stroke='#45525F'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1.25'
        />
      </svg>
    </button>
  );
});

MobileMenuButton.displayName = 'MobileMenuButton';
