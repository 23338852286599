import * as React from 'react';

import clsx from 'clsx';
import NextImage from 'next/image';

import { PlainText } from '@/components/common';
import { imgixLoader } from '@/utils/images';

interface PhaseInfoCardProps {
  variant?: 'cover' | 'default';
  icon?: React.ReactNode;
  title: string;
  copy?: string;
  image?: string;
  kicker?: string;
  list?: string[];
  className?: string;
}

export const PhaseInfoCard = React.memo<PhaseInfoCardProps>(
  ({ variant = 'default', icon, title, copy, image, kicker, list, className }) => {
    return (
      <div
        className={clsx(
          'relative z-0 flex flex-col min-w-[290px] max-w-[290px] !min-h-[233px] p-8 overflow-hidden rounded-2xl bg-khaki-1',
          variant === 'cover' ? 'text-khaki-1' : '',
          className,
        )}
      >
        {image && (
          <NextImage
            alt={title}
            className='-z-10 object-cover'
            draggable={false}
            fill
            loader={imgixLoader}
            sizes='290px'
            src={image}
          />
        )}
        <div className='grow'>
          {kicker && <div className='font-josefin text-sm tracking-tight'>{kicker}</div>}
          <h2 className='font-josefin tracking-tight font-bold flex gap-2 items-center mb-2'>
            {icon}
            {title}
          </h2>
          {copy && (
            <div className='text-sm mt-2 text-slate-1'>
              <PlainText content={copy} />
            </div>
          )}
        </div>
        <div className='flex flex-wrap gap-1.5 mt-4'>
          {list?.map((item) => (
            <div
              className='text-slate tracking-tight text-xs font-josefin font-bold py-2 px-4 bg-khaki-2 rounded-full  whitespace-nowrap'
              key={item}
            >
              {item}
            </div>
          ))}
        </div>
      </div>
    );
  },
);

PhaseInfoCard.displayName = 'PhaseInfoCard';
