import { create } from 'zustand';
import { persist } from 'zustand/middleware';

import { DEFAULT_AGE, DEFAULT_HEIGHT, DEFAULT_WEIGHT, DETAILS_QUESTION_ID } from '@/lib/constants';

export type UserName = {
  first?: string;
  last?: string;
};

export type UserDetailsValue = {
  age?: number;
  heightInInches?: number;
  weightInPounds?: number;
};

export type QuestionValue = UserName | UserDetailsValue | string[] | null;

export type OnboardingQuestion = {
  //? ID will double as the name for constant questions
  id: string;
  text: string;
  presentationOrder: number;
  value: QuestionValue;
  type: 'SELECT' | 'TEXT' | 'USER_DETAILS';
};

export type OnboardingSelectAnswer = {
  id: string;
  text?: string;
  /// @deprecated
  value?: boolean;
  isNullifying?: boolean;
};

export type OnboardingSelectQuestion = Omit<OnboardingQuestion, 'value' | 'type'> & {
  onboardingAnswers: OnboardingSelectAnswer[];
  type: 'SELECT';
  isMultiSelect: boolean;
  isRating?: boolean;
  value: string[];
};

export type SelectUserAnswer = {
  type: 'SELECT';
  questionId: string;
  value: string[];
} | null;

export type TextUserAnswer = {
  type: 'TEXT';
  questionId: string;
  value: UserName;
} | null;

export type DetailsUserAnswer = {
  type: 'USER_DETAILS';
  questionId: string;
  value: UserDetailsValue;
} | null;

export type UserAnswer = SelectUserAnswer | TextUserAnswer | DetailsUserAnswer;

type CycleData = {
  cycleDuration: number;
  periodDuration: number;
  cycleStart: string | null;
  cycleLunar: boolean;
};

interface OnboardingStore {
  userAnswers: UserAnswer[];
  // addUserAnswer: (u: UserAnswer | UserAnswer[]) => void;
  setUserAnswers: (ua: UserAnswer[]) => void;
  cycleData?: CycleData;
  setCycleData: (cd: CycleData) => void;
  resetUserAnswers: () => void;
  resetCycleData: () => void;
}

export const DEFAULT_USER_DETAILS: DetailsUserAnswer = {
  type: 'USER_DETAILS',
  questionId: DETAILS_QUESTION_ID,
  value: {
    age: DEFAULT_AGE,
    heightInInches: DEFAULT_HEIGHT,
    weightInPounds: DEFAULT_WEIGHT,
  },
};

const defaultState = {
  userAnswers: [],
  cycleData: undefined,
};

export const useOnboardingStore = create<OnboardingStore>()(
  persist(
    (set) => ({
      userAnswers: defaultState.userAnswers,
      setUserAnswers: (ua: UserAnswer[]) => set({ userAnswers: ua }),
      cycleData: defaultState.cycleData,
      setCycleData: (cd: CycleData) => set({ cycleData: cd }),
      resetUserAnswers: () => set({ userAnswers: defaultState.userAnswers }),
      resetCycleData: () => set({ cycleData: defaultState.cycleData }),
    }),
    {
      name: 'onboarding-data',
      partialize: (state) => ({
        userAnswers: state.userAnswers,
        cycleData: state.cycleData,
      }),
    },
  ),
);
