import * as React from 'react';

import { useForm } from 'react-hook-form';

import { StructuredModal } from '@/components/common';
import { Button, Checkbox } from '@/components/ui';

export interface UserDeleteConfirmationProps {
  mode: 'self-delete' | 'user-delete';
  deleteUser: () => void;
  isLoading: boolean;
}

export const UserDeleteConfirmation = React.memo<UserDeleteConfirmationProps>((props) => {
  const mode = props.mode;

  const {
    register,
    watch,
    formState: { errors },
  } = useForm();

  const noUndo = watch('no_undo');

  const title = React.useMemo(
    () => `Are you sure you want to delete ${mode === 'self-delete' ? 'your' : 'this'} account?`,
    [mode],
  );

  const disclaimer = React.useMemo(
    () =>
      `${mode === 'self-delete' ? 'Your' : 'This'} account will be permanently deleted and ${
        mode === 'self-delete' ? 'you' : ''
      } will no longer have access to ${
        mode === 'self-delete' ? 'your' : ''
      } daily workouts, insights, and nutrition tips.`,
    [mode],
  );

  const handleDeleteUser = React.useCallback<React.MouseEventHandler<HTMLButtonElement>>(
    (event) => {
      event.preventDefault();
      props.deleteUser();
    },
    [props],
  );

  return (
    <StructuredModal
      footer={
        <Button className='mr-8' disabled={props.isLoading || !noUndo} onClick={handleDeleteUser} variant='danger'>
          Delete User
        </Button>
      }
      title={title}
    >
      <div className='mb-5'>{disclaimer}</div>
      <Checkbox errors={errors} {...register('no_undo')} label='I acknowledge this action can not be undone' />
    </StructuredModal>
  );
});

UserDeleteConfirmation.displayName = 'UserDeleteConfirmation';
