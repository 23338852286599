import * as React from 'react';

import { ErrorMessage as RHFErrorMessage } from '@hookform/error-message';
import { has } from 'lodash-es';

import { ErrorMessage } from '@/components/ui';

export const FormErrorMessage = React.memo<React.ComponentProps<typeof RHFErrorMessage> & { className?: string }>(
  ({ className, ...props }) => {
    return has(props.errors, props.name) ? (
      <ErrorMessage className={className}>
        <RHFErrorMessage {...props} />
      </ErrorMessage>
    ) : null;
  },
);

FormErrorMessage.displayName = 'FormErrorMessage';
