import * as React from 'react';

import { ButtonLink } from '@/components/common';
import { PhaseProgress } from '@/components/phases';
import { PhaseColorText } from '@/components/ui';

import { RouterOutput } from '../../../trpc/router';

export const AccountCycle = React.memo<{ dbUser: RouterOutput['users']['byId']['data'] }>(({ dbUser }) => {
  return (
    <div className='bg-khaki-1 rounded-xl px-4 py-5 sm:py-8 sm:px-6'>
      <h2 className='font-josefin text-lg lg:text-xl text-slate font-bold mb-4'>My Cycle</h2>
      <div>
        <div className='text-sm sm:text-base'>
          You are{' '}
          {dbUser.cycleLunar
            ? 'following the lunar cycle'
            : `on day ${dbUser.phasedayNumberRaw} of ${dbUser.cycleDuration}`}{' '}
          in the{' '}
          <PhaseColorText className='text-sm font-bold uppercase' phaseName={dbUser.phaseName}>
            {dbUser.phaseName}
          </PhaseColorText>{' '}
          phase.
        </div>
        <PhaseProgress
          animate={false}
          className='max-w-md mt-5 mb-8'
          currentPhase={dbUser.phaseName}
          currentPhaseDay={dbUser.phasedayNumber}
          dynamicColors={false}
        />
      </div>
      <ButtonLink href='/account/settings/cycle' size='sm' variant='secondary'>
        Adjust my cycle
      </ButtonLink>
    </div>
  );
});

AccountCycle.displayName = 'AccountCycle';
