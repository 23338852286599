import * as React from 'react';

import clsx from 'clsx';

import { Carousel } from '@/components/ui';
import { WorkoutMegaCard, WorkoutTeaserCard } from '@/components/workouts';
import { useAnalyticsDispatch } from '@/context/analytics';
import { PhaseName } from '@/types/28';
import { trpc } from '@/utils/trpc';

import { RouterOutput } from '../../../trpc/router';

type DashboardWorkoutsProps = {
  dbUser: RouterOutput['users']['byId']['data'];
  featuredWorkout: RouterOutput['users']['workout']['featuredWorkout'];
  phaseName: string;
};

export const DashboardWorkouts = React.memo<DashboardWorkoutsProps>(({ dbUser, featuredWorkout, phaseName }) => {
  const analyticsDispatch = useAnalyticsDispatch();

  const { data: recommendedWorkouts, isLoading } = trpc.workouts.recommended.useQuery({
    currentPhase: phaseName as PhaseName,
  });

  return (
    <>
      {featuredWorkout && (
        <WorkoutMegaCard
          className='mb-6 md:mt-8 mx-auto'
          dbUser={dbUser}
          kicker="Today's Workout"
          workout={featuredWorkout}
        />
      )}
      <section className='md:mt-8'>
        <header className={clsx('max-w-7xl px-6 md:px-12 mx-auto', featuredWorkout ? 'block' : 'hidden')}>
          <h2 className='text-sm tracking-tight font-josefin mb-4'>
            Not feeling it?{' '}
            <strong>
              More recommended <span className='uppercase'>{phaseName}</span> workouts
            </strong>
          </h2>
        </header>
        <Carousel itemGap={16}>
          {recommendedWorkouts?.data
            .filter((workout) => (featuredWorkout ? workout.id !== featuredWorkout.id : true))
            .map((workout) => (
              <WorkoutTeaserCard
                key={workout.id}
                onClickHandler={() =>
                  analyticsDispatch({
                    type: 'WORKOUT_VIEWED',
                    payload: {
                      dbUser,
                      workout: workout,
                      location: 'Dashboard: Recommended Workouts',
                    },
                  })
                }
                url={`/workouts/${workout.slug}`}
                workout={workout}
              />
            ))}
          {isLoading &&
            [...Array(3)].map((_, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <WorkoutTeaserCard animateSkeleton={isLoading} key={i} variant='simple' />
            ))}
        </Carousel>
      </section>
    </>
  );
});

DashboardWorkouts.displayName = 'DashboardWorkouts';
