import * as React from 'react';

import { zodResolver } from '@hookform/resolvers/zod';
import { useRouter } from 'next/router';
import { SubmitHandler, useForm } from 'react-hook-form';
import { z } from 'zod';

import { ButtonLink } from '@/components/common';
import { Button, Checkbox, Input } from '@/components/ui';
import { VideoPlayer } from '@/components/videos';
import { useLeavePrevention } from '@/hooks/useLeavePrevention';
import { ERROR, WARN } from '@/utils/form';
import { findPlayback } from '@/utils/mux';
import { trpc } from '@/utils/trpc';

import { RouterOutput } from '../../../trpc/router';

type VideoUpdateFormValues = { title: string; isPreview: boolean; mp4Support: boolean };

const schema: z.ZodType<VideoUpdateFormValues> = z.lazy(() =>
  z.object({ title: z.string().min(1, { message: ERROR.REQUIRED }), isPreview: z.boolean(), mp4Support: z.boolean() }),
);

export interface VideoUpdateFormProps {
  video: RouterOutput['videos']['byId'];
}

export const VideoUpdateForm = React.memo<VideoUpdateFormProps>(({ video }) => {
  const router = useRouter();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting, isDirty, isSubmitSuccessful },
  } = useForm<VideoUpdateFormValues>({
    defaultValues: { title: video.title, isPreview: video.isPreview, mp4Support: video.mp4support },
    resolver: zodResolver(schema),
  });

  const { mutateAsync } = trpc.videos.update.useMutation();

  useLeavePrevention(isDirty && !isSubmitting && !isSubmitSuccessful, WARN.UNSAVED_CHANGES);

  const onSubmit = React.useCallback<SubmitHandler<VideoUpdateFormValues>>(
    (data) => {
      mutateAsync({ id: video.id, title: data.title, isPreview: data.isPreview, mp4support: data.mp4Support }).then(
        () => router.push(`/admin/videos`),
      );
    },
    [mutateAsync, router, video.id],
  );

  const playback = findPlayback(video.playbacks);

  return (
    <form className='bg-khaki-1 rounded-lg p-8 overflow-hidden' onSubmit={handleSubmit(onSubmit)}>
      <Input errors={errors} label='Title' {...register('title')} />
      <Checkbox errors={errors} label='Is Preview' {...register('isPreview')} disabled={video.isPreview} />
      {!video.isPreview && (
        <Checkbox errors={errors} label='Enable Downloads' {...register('mp4Support')} disabled={video.isPreview} />
      )}
      {video.isPreview && (
        <p className='-mt-6 pt-0 text-xs'>
          (Video cannot changed back from a preview video. Please delete the video to make that change)
        </p>
      )}
      {video.status === 'ready' && playback && <VideoPlayer height={315} playback={playback} width={560} />}
      <div className='flex gap-x-6 mt-2 -mx-8 -mb-8 p-8 py-5 rounded-bl-lg bg-khaki-2-3'>
        <Button
          disabled={isSubmitting || !isDirty}
          state={isSubmitting ? 'waiting' : undefined}
          type='submit'
          variant='primary'
        >
          Update
        </Button>
        {!video._count.workout && (
          <ButtonLink href={`/admin/videos/${video.id}/delete`} variant='minimal'>
            Delete
          </ButtonLink>
        )}
      </div>
    </form>
  );
});

VideoUpdateForm.displayName = 'VideoUpdateForm';
