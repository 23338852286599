import * as React from 'react';

import clsx from 'clsx';

import { VideoThumbnail } from '@/components/videos';
import { findPlayback, formatDuration } from '@/utils/mux';

import { RouterOutput } from '../../../trpc/router';

export interface VideoAdminCardProps {
  video?: RouterOutput['videos']['byId'];
  className?: string;
}

export const VideoAdminCard = React.memo<VideoAdminCardProps>(({ video, className }) => {
  if (video) {
    return (
      <div
        className={clsx('group overflow-hidden rounded-lg border border-khaki-3 text-left', className)}
        key={video.id}
      >
        <div className='leading-zero relative'>
          <VideoThumbnail alt={video.title} height={125} playback={findPlayback(video.playbacks)} width={226} />
          <span className='absolute bottom-2 right-2 bg-slate/75 text-khaki-1 text-xs font-josefin py-1 px-2 rounded-md'>
            {video.duration && formatDuration(video.duration)}
          </span>
        </div>
        <div className='flex flex-col gap-1 p-3 text-xs font-josefin'>
          <div>{video.title}</div>
          <div className='flex gap-2 text-slate-1'>
            <span>{video.maxStoredResolution}</span>
            <span className='text-khaki-4'>&bull;</span>
            <span>{video.aspectRatio}</span>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className='rounded-lg border border-khaki-3 overflow-hidden animate-pulse'>
      <div className='bg-khaki-2-3 w-[226px] h-[125px]' />
      <div className='p-3 mt-1'>
        <div className='h-2 w-32 bg-khaki-2-3 rounded' />
        <div className='h-2 w-16 bg-khaki-2-3 rounded mt-2' />
      </div>
    </div>
  );
});

VideoAdminCard.displayName = 'VideoAdminCard';
