import * as React from 'react';

export interface RadioProps extends React.ComponentPropsWithRef<'input'> {
  label: string;
}

export const Radio = React.forwardRef<HTMLInputElement, RadioProps>(({ label, type = 'radio', ...props }, ref) => {
  return (
    <label className='flex gap-2 items-center cursor-pointer'>
      <input ref={ref} type={type} {...props} className='sr-only peer' />
      <div className='ring-1 ring-khaki-5 rounded-full w-6 h-6 border-4 bg-khaki-1 border-khaki-1 peer-checked:bg-gradient-purple peer-focus-visible:ring-purple/25 peer-focus-visible:ring-4' />
      {label}
    </label>
  );
});

Radio.displayName = 'Radio';
