import * as React from 'react';

import clsx from 'clsx';

export interface ExerciseGearAdminCardProps {
  title?: string;
  photo?: string;
  className?: string;
}

export const ExerciseGearAdminCard = React.memo<ExerciseGearAdminCardProps>(({ title, photo, className }) => {
  if (title) {
    return (
      <div className={clsx('flex flex-col items-center justify-center p-4 rounded-lg bg-white gap-2', className)}>
        {/* // eslint-disable-next-line @next/next/no-img-element */}
        <div>{photo && <img alt='Exercise gear.' src={photo} />}</div>
        <div className='font-josefin text-sm'>{title}</div>
      </div>
    );
  }

  return (
    <div className='flex flex-col items-center gap-2 p-4 text-center rounded-lg bg-white animate-pulse'>
      <div />
      <div className='bg-khaki-2-3 w-20 h-20 rounded-full' />
      <div className='h-2 w-16 bg-khaki-2-3 rounded' />
    </div>
  );
});

ExerciseGearAdminCard.displayName = 'ExerciseGearAdminCard';
