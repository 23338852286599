import * as React from 'react';

import { zodResolver } from '@hookform/resolvers/zod';
import { useRouter } from 'next/router';
import { useForm, SubmitHandler } from 'react-hook-form';
import { z } from 'zod';

import { Button, Input, ProgressBar } from '@/components/ui';
import { useLeavePrevention } from '@/hooks/useLeavePrevention';
import { ImageCreateInput, useImageCreate } from '@/request/client';
import { ERROR, WARN } from '@/utils/form';
import { zodImageFileCreate } from '@/utils/zod';

const schema = z.object({
  title: z.string().min(1, { message: ERROR.REQUIRED }),
  files: zodImageFileCreate,
});

export const ImageCreateForm = React.memo(() => {
  const router = useRouter();
  const [imageId, setImageId] = React.useState<string>();
  const {
    register,
    formState: { isSubmitting, isSubmitSuccessful, errors, isDirty },
    handleSubmit,
  } = useForm<ImageCreateInput>({ resolver: zodResolver(schema) });
  const { createImage, isUploading, progress } = useImageCreate({
    onSuccess: (data) => setImageId(data.id),
  });

  useLeavePrevention(isSubmitting, WARN.UPLOAD_IN_PROGRESS);
  useLeavePrevention(isDirty && !isSubmitting && !isSubmitSuccessful, WARN.UNSAVED_CHANGES);

  const onSubmit = React.useCallback<SubmitHandler<ImageCreateInput>>(
    ({ title, files }) => createImage({ title, files }),
    [createImage],
  );

  React.useEffect(() => {
    if (imageId && isSubmitSuccessful) router.push(`/admin/images/${imageId}`);
  }, [imageId, isSubmitSuccessful, router]);

  return (
    <form className='bg-khaki-1 rounded-lg p-8 overflow-hidden' onSubmit={handleSubmit(onSubmit)}>
      <Input errors={errors} label='Title' {...register('title')} />
      <div>
        <Input
          accept='image/*'
          className='text-sm file:text-purple-3 file:mr-4 file:pt-[12px] file:pb-[11px] file:px-4 file:rounded-full file:border-2 file:border-purple-3 file:border-solid file:text-sm file:font-semibold file:bg-violet-50 file:text-violet-700 hover:file:bg-violet-100 file:font-josefin file:bg-transparent file:cursor-pointer'
          disabled={isUploading}
          errors={errors}
          type='file'
          {...register('files')}
        />
        {progress > 0 && (
          <div className='max-w-xs mt-4'>
            <ProgressBar max={100} value={progress} />
          </div>
        )}
      </div>
      <div className='flex gap-x-6 mt-2 -mx-8 -mb-8 p-8 py-5 rounded-bl-lg bg-khaki-2-3'>
        <Button disabled={isSubmitting} state={isSubmitting ? 'waiting' : undefined} type='submit' variant='primary'>
          Upload
        </Button>
      </div>
    </form>
  );
});

ImageCreateForm.displayName = 'ImageCreateForm';
