import * as React from 'react';

import clsx from 'clsx';

export const Spinner: React.FC<React.SVGAttributes<SVGSVGElement>> = (props) => {
  return (
    <svg
      className={clsx('animate-spin', props?.className)}
      fill='none'
      height='20'
      viewBox='0 0 24 24'
      width='20'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle className='opacity-25' cx='12' cy='12' r='11' stroke='currentColor' strokeWidth='2' />
      <path
        d='M2.98933 18.3093C1.82574 16.6476 1.14436 14.6964 1.02052 12.6715C0.896671 10.6467 1.33518 8.627 2.28758 6.83581C3.23997 5.04463 4.66914 3.5517 6.41708 2.52208C8.16502 1.49246 10.1636 0.966271 12.192 1.00168'
        stroke='currentColor'
        strokeWidth='2'
      />
    </svg>
  );
};
