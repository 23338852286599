import * as React from 'react';

import { DrawerPosition as DrawerPosition } from '@/components/ui';
import { WorkoutSlim } from '@/types/28';

import { RouterOutput } from '../../trpc/router';

type Action =
  | {
      type: 'WORKOUT_PLAYER_OPEN';
      workout: RouterOutput['users']['workout']['featuredWorkout'] | null;
      playback: {
        muxPlaybackId: string;
        policy: string;
      };
      onClose?: (() => void) | null;
    }
  | {
      type: 'WORKOUT_PLAYER_CLOSE';
    }
  | {
      type: 'DRAWER_OPEN';
      position?: DrawerPosition | null;
      content: React.ReactNode | null;
      onClose?: (() => void) | null;
      className?: string;
    }
  | {
      type: 'DRAWER_CLOSE';
    }
  | {
      type: 'MODAL_OPEN';
      content: React.ReactNode | null;
      onClose?: (() => void) | null;
    }
  | {
      type: 'MODAL_CLOSE';
    };

type State = {
  drawerVisible: boolean;
  drawerPosition?: DrawerPosition | null;
  drawerContent: React.ReactNode | null;
  drawerClassName?: string;
  drawerOnClose?: (() => void) | null;
  modalContent: React.ReactNode | null;
  modalOnClose?: (() => void) | null;
  modalVisible: boolean;
  workoutPlayerPlayback: {
    muxPlaybackId: string;
    policy: string;
  } | null;
  workoutPlayerWorkout: WorkoutSlim | null;
  workoutPlayerOnClose?: (() => void) | null;
  workoutPlayerVisible: boolean;
};

const reducer: React.Reducer<State, Action> = (state, action) => {
  switch (action.type) {
    case 'WORKOUT_PLAYER_OPEN':
      return {
        ...state,
        workoutPlayerWorkout: action.workout,
        workoutPlayerPlayback: action.playback,
        workoutPlayerVisible: true,
      };
    case 'WORKOUT_PLAYER_CLOSE':
      return { ...state, workoutPlayerVisible: false };
    case 'DRAWER_OPEN':
      return {
        ...state,
        drawerVisible: true,
        drawerContent: action.content,
        drawerPosition: action.position,
        drawerOnClose: action.onClose,
        drawerClassName: action.className,
      };
    case 'DRAWER_CLOSE':
      return {
        ...state,
        drawerOnClose: null,
        drawerVisible: false,
      };
    case 'MODAL_OPEN':
      return {
        ...state,
        modalVisible: true,
        modalContent: action.content,
        modalOnClose: action.onClose,
      };
    case 'MODAL_CLOSE':
      return {
        ...state,
        modalVisible: false,
        modalVariant: null,
        modalWidth: null,
        modalOnClose: null,
      };
    default:
      return state;
  }
};

const initialState: State = {
  drawerContent: null,
  drawerPosition: null,
  drawerOnClose: null,
  drawerClassName: '',
  drawerVisible: false,
  modalContent: null,
  modalOnClose: null,
  modalVisible: false,
  workoutPlayerWorkout: null,
  workoutPlayerOnClose: null,
  workoutPlayerPlayback: null,
  workoutPlayerVisible: false,
};

export const UIStateContext = React.createContext<State | undefined>(undefined);
export const UIDispatchContext = React.createContext<React.Dispatch<Action> | undefined>(undefined);

export const useUI = () => {
  const stateContext = React.useContext(UIStateContext);

  if (!stateContext) {
    throw new Error('state context was not provided for `useUI`');
  }

  return stateContext;
};

export const useUIDispatch = () => {
  const dispatchContext = React.useContext(UIDispatchContext);

  if (!dispatchContext) {
    throw new Error('dispatch context was not provided for `useUIDispatch`');
  }

  return dispatchContext;
};

export const UIProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  return (
    <UIStateContext.Provider value={state}>
      <UIDispatchContext.Provider value={dispatch}>{children}</UIDispatchContext.Provider>
    </UIStateContext.Provider>
  );
};
