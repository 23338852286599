import { create } from 'zustand';
import { persist } from 'zustand/middleware';

interface AppDataStore {
  accessToken?: string;
  epixelReferrer?: string;
}

interface AppDataActions {
  setAccessToken: (token: string) => void;
  setEpixelReferrer: (referrer: string) => void;
  clearEpixelReferrer: () => void;
  clearState: () => void;
}

const defaultState: AppDataStore = {
  accessToken: undefined,
  epixelReferrer: undefined,
};

export const useAppDataStore = create<AppDataStore & AppDataActions>()(
  persist(
    (set) => ({
      accessToken: defaultState.accessToken,
      setAccessToken: (token: string) => set({ accessToken: token }),
      setEpixelReferrer: (referrer: string) => set({ epixelReferrer: referrer }),
      clearEpixelReferrer: () => set({ epixelReferrer: undefined }),
      clearState: () => set(defaultState),
    }),
    {
      name: 'app-data',
      partialize: (state) => ({
        epixelReferrer: state.epixelReferrer,
      }),
    },
  ),
);
