import * as React from 'react';

import { Image } from '@prisma/client';
import clsx from 'clsx';
import dynamic from 'next/dynamic';
import NextImage from 'next/image';
import { FieldErrors } from 'react-hook-form';

import { FormErrorMessage } from '@/components/common';
import { ImageBrowserProps } from '@/components/images';
import { Button, useUIDispatch } from '@/components/ui';
import { imgixLoader } from '@/utils/images';

interface ImageSelectFieldProps {
  errors?: FieldErrors;
  height?: number;
  image?: Image | null;
  label: string;
  name: string;
  onImageSelect: (image: Image) => void;
  width?: number;
  variant?: 'inline' | 'stacked';
}

const ImageBrowser = dynamic<ImageBrowserProps>(() => import('@/components/images').then((mod) => mod.ImageBrowser), {
  ssr: false,
});

export const ImageSelectField = React.memo<ImageSelectFieldProps>(
  ({ errors, name, label, image, onImageSelect, width = 200, height = 200, variant = 'stacked' }) => {
    const [selectedImage, setSelectedImage] = React.useState(image);
    const uiDispatch = useUIDispatch();

    const onSelectImage = React.useCallback(
      (image: Image) => {
        setSelectedImage(image);
        onImageSelect(image);
      },
      [onImageSelect],
    );

    return (
      <div>
        <label className='font-lato font-bold block mb-2 text-sm'>{label}</label>
        <div className={clsx('flex gap-x-4 gap-y-2 items-center', variant === 'inline' && 'flex-col')}>
          {selectedImage && (
            <div className='relative' style={{ width, aspectRatio: `${width} / ${height}` }}>
              <NextImage
                alt={selectedImage?.title}
                className='rounded-lg object-cover'
                fill
                loader={imgixLoader}
                src={`/${selectedImage.fileName}`}
              />
            </div>
          )}
          <div className={clsx(variant === 'inline' && 'w-full')}>
            {selectedImage && (
              <div className='text-sm mb-3'>
                <div className='font-bold mb-1'>{selectedImage.title}</div>
                {selectedImage.width} x {selectedImage.height}px
                <br />
                {selectedImage.type}
              </div>
            )}
            <Button
              className='text-purple'
              onClick={() =>
                uiDispatch({
                  type: 'MODAL_OPEN',
                  content: <ImageBrowser onImageSelect={onSelectImage} selectedImage={selectedImage} />,
                })
              }
              size='sm'
              type='button'
              variant='secondary'
            >
              {image ? 'Swap' : 'Choose'} Image
            </Button>
          </div>
        </div>
        <FormErrorMessage errors={errors} name={name} />
      </div>
    );
  },
);

ImageSelectField.displayName = 'ImageSelectField';
