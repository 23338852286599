import * as React from 'react';
import { useUser } from '@clerk/nextjs';
import { useQueryClient } from '@tanstack/react-query';
import { getQueryKey } from '@trpc/react-query';
import { NextPage } from 'next';
import { DashboardView } from '@/components/dashboard';
import { MarketingHomepage } from '@/components/marketing';
import { Spinner } from '@/components/ui';
import { trpc } from '@/utils/trpc';

const HomePage: NextPage = () => {
  const {
    user,
    isLoaded
  } = useUser();
  const userId = user?.externalId;
  const queryClient = useQueryClient();
  const {
    mutateAsync: updateUser
  } = trpc.users.update.useMutation();
  const {
    data: dbUser,
    isLoading: dbUserLoading
  } = trpc.users.byId.useQuery({
    id: userId || ''
  }, {
    enabled: !!userId,
    onSuccess: resp => {
      if (resp.data) {
        const storedTZ = resp.data.timeZone;
        const systemTZ = Intl.DateTimeFormat().resolvedOptions().timeZone;

        if (userId && systemTZ && storedTZ !== systemTZ) {
          updateUser({
            id: userId,
            timeZone: systemTZ
          }, {
            onSettled: () => {
              const workoutKey = getQueryKey(trpc.users.workout, {
                id: userId
              });
              queryClient.invalidateQueries(workoutKey);
            }
          });
        }
      }
    }
  });

  if (!isLoaded || !!user && dbUserLoading) {
    return <div className='max-w-md mx-auto my-40 font-josefin font-bold text-center text-2xl tracking-tighter'>
        <Spinner className='mx-auto mb-3 w-8 h-8 text-purple-3' />
        Logging you in
      </div>;
  }

  if (dbUser) return <DashboardView dbUser={dbUser.data} />;
  return <MarketingHomepage />;
};

export default HomePage;