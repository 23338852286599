export const clamp = (value: number, min: number, max: number) => {
  return Math.min(Math.max(min, value), max);
};

export const randomNumber = (max: number) => {
  return Math.floor(Math.random() * max);
};

// random number between two numbers (inclusive)
export const randomNumInclusive = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};
