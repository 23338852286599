import * as React from 'react';

import { Dialog, Transition } from '@headlessui/react';

import { Overlay } from '@/components/ui';

export interface ModalProps {
  open?: boolean;
  onClose: () => void;
  children: React.ReactNode;
}

export const Modal = React.memo<ModalProps>(({ children, open, onClose }) => {
  return (
    <Transition.Root as={React.Fragment} show={open}>
      <Dialog as='div' className='relative z-50' onClose={onClose}>
        <Overlay />
        <div className='fixed inset-0 z-10 overflow-y-auto'>
          <div className='flex min-h-full items-end justify-center p-4 sm:items-center'>
            <Transition.Child
              as={React.Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            >
              <Dialog.Panel className='relative transform overflow-scroll bg-khaki-1 rounded-lg transition-all max-w-5xl sm:my-8'>
                {children}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
});

Modal.displayName = 'Modal';
