import * as React from 'react';

import clsx from 'clsx';

interface HorizontalList extends React.ComponentPropsWithoutRef<'div'> {
  items: string[];
}

export const HorizontalList = React.memo<HorizontalList>(({ items, className, ...rest }) => {
  return (
    <div className={clsx(' flex items-center gap-2 text-xs text-slate-1', className)} {...rest}>
      {items.map((item) => (
        <span
          className='inline-flex items-center gap-2 after:block after:w-1 after:h-1 after:rounded-full after:bg-khaki-4 last:after:hidden'
          key={item}
        >
          {item}
        </span>
      ))}
    </div>
  );
});

HorizontalList.displayName = 'HorizontalList';
