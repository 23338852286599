import * as React from 'react';

import toast from 'react-hot-toast';

import { useUploadFile } from '@/hooks/useUploadfile';
import { trpc } from '@/utils/trpc';

export type VideoCreateInput = {
  files: FileList;
  title: string;
  isPreview: boolean;
};

export const useVideoCreate = () => {
  const { isUploading, uploadProgress, upload } = useUploadFile();

  const { mutate, isLoading } = trpc.videos.create.useMutation({
    onMutate: () => toast.loading('Saving...'),
    onError: (error) => {
      toast.remove();
      toast.error(error.message);
    },
    onSuccess: (data) => {
      toast.remove();
      toast.success(`Video ${data.title} created`);
    },
  });

  const createVideo = React.useCallback(
    async (input: VideoCreateInput) => {
      const { title, files, isPreview } = input;
      const file = files[0];

      const { url, fileName } = await upload(file);

      mutate({ fileName, title: title.trim(), uploadUrl: url, isPreview });
    },
    [mutate, upload],
  );

  return {
    createVideo,
    isLoading,
    isUploading,
    progress: uploadProgress,
  };
};
