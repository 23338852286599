import * as React from 'react';

import { Phase } from '@prisma/client';
import clsx from 'clsx';

import { StructuredModal } from '@/components/common';
import { PhasedayAdminCard } from '@/components/phases';
import { Button, useUIDispatch } from '@/components/ui';
import { trpc } from '@/utils/trpc';

export interface PhasedayBrowserProps {
  selectedPhasedays:
    | {
        phaseday: {
          number: number;
          phase: Phase & { days: { number: number }[] };
          id: string;
        };
      }[]
    | null;
  onPhasedaysSelect: (
    phasedays: {
      phaseday: {
        number: number;
        phase: Phase & { days: { number: number }[] };
        id: string;
      };
    }[],
  ) => void;
}

export const PhasedayBrowser = React.memo<PhasedayBrowserProps>((props) => {
  const { data: phasedays } = trpc.phasedays.all.useQuery(undefined);
  const [selectedPhasedays, setSelectedPhasedays] = React.useState<
    | {
        phaseday: {
          number: number;
          phase: Phase & { days: { number: number }[] };
          id: string;
        };
      }[]
    | null
  >(props.selectedPhasedays);
  const uiDispatch = useUIDispatch();

  return (
    <StructuredModal
      footer={
        <Button
          disabled={!selectedPhasedays?.length}
          onClick={() => {
            if (selectedPhasedays && selectedPhasedays?.length > 0) props.onPhasedaysSelect(selectedPhasedays);
            uiDispatch({ type: 'MODAL_CLOSE' });
          }}
          variant='primary'
        >
          Use Phasedays
        </Button>
      }
      title='Phases'
    >
      <div className='grid grid-cols-7 gap-4'>
        {phasedays?.map((phaseday) => (
          <button
            key={phaseday.id}
            onClick={() => {
              if (selectedPhasedays?.some((selectedPhaseDay) => selectedPhaseDay.phaseday.id === phaseday.id)) {
                setSelectedPhasedays(
                  selectedPhasedays.filter((selectedPhaseDay) => selectedPhaseDay.phaseday.id !== phaseday.id),
                );
              } else {
                setSelectedPhasedays((prev) => (prev ? [...prev, { phaseday }] : null));
              }
            }}
          >
            <PhasedayAdminCard
              className={clsx('border border-khaki-3', {
                'border-purple-3':
                  selectedPhasedays &&
                  selectedPhasedays.some((selectedPhaseday) => selectedPhaseday.phaseday.id === phaseday.id),
              })}
              phaseName={phaseday.phase.name}
              phasedayNumber={phaseday.number}
            />
          </button>
        ))}
        {!phasedays &&
          [...Array(28)].map((_, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <PhasedayAdminCard key={i} />
          ))}
      </div>
    </StructuredModal>
  );
});

PhasedayBrowser.displayName = 'PhasedayBrowser';
