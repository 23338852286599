import * as React from 'react';

import clsx from 'clsx';
import { useMouseHovered, useHoverDirty } from 'react-use';

import { useSlider } from '@/hooks/useSlider';
import { useStoryboard } from '@/request/client';
import { formatSeconds } from '@/utils/mux';
import { trpc } from '@/utils/trpc';

import s from './TimelineSlider.module.css';

interface TimelineSliderProps {
  onSeekEnd: (played: number) => void;
  duration: number;
  playback: {
    muxPlaybackId: string;
    policy: string;
  };
  className?: string;
  progress: {
    loaded: number;
    loadedSeconds: number;
    played: number;
    playedSeconds: number;
  };
}

export const TimelineSlider = React.memo<TimelineSliderProps>(
  ({ onSeekEnd, duration, playback, progress, className }) => {
    const sliderRef = React.useRef(null);

    const playbackId = playback.muxPlaybackId;
    const requiresToken = playback?.policy === 'signed';

    const tokenPlaybackId = requiresToken ? playbackId : undefined;
    const { data: storyboardToken } = trpc.videos.token.useQuery(
      { playbackId: tokenPlaybackId as string, type: 'storyboard' },
      {
        enabled: !!tokenPlaybackId,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
      },
    );

    const storyboardPlaybackId = requiresToken ? (storyboardToken ? playbackId : undefined) : playbackId;
    const { data: storyboard } = useStoryboard(storyboardPlaybackId, storyboardToken?.data.token);

    const { isSliding, value: sliderValue } = useSlider(sliderRef, {
      onScrubStart: () => null,
      onScrubStop: (value) => onSeekEnd(value),
    });

    const isHovering = useHoverDirty(sliderRef);
    const { elX: hoverX, elW: hoverWidth } = useMouseHovered(sliderRef, { bound: true, whenHovered: true });

    const hoverTime = hoverX ? duration * (hoverX / hoverWidth) : 0;
    const activeStoryboard = storyboard?.find((item) => hoverTime > item.start && hoverTime < item.end);

    return (
      <div
        className={clsx('relative', s.root, className)}
        ref={sliderRef}
        style={
          {
            '--storyboard-width': activeStoryboard?.w || 0,
            '--storyboard-height': activeStoryboard?.h || 0,
            '--storyboard-position-x': activeStoryboard?.x || 0,
            '--storyboard-position-y': activeStoryboard?.y || 0,
            '--progress-loaded': progress.loaded,
            '--progress-played': progress.played,
            '--slider-value': sliderValue,
            '--hover-x': hoverX,
            '--hover-width': hoverWidth,
          } as React.CSSProperties
        }
      >
        {activeStoryboard && isHovering && (
          <>
            <div
              className={clsx(
                'pointer-events-none absolute top-0 bg-no-repeat transition-opacity border-2 border-slate rounded-lg bg-slate shadow-md overflow-hidden',
                s.tooltip,
              )}
            >
              {/* eslint-disable-next-line @next/next/no-img-element */}
              <img alt='' className={clsx('absolute max-w-none', s.tooltip_img)} src={activeStoryboard.image} />
              <div className='absolute bottom-2 left-1/2 -translate-x-1/2 font-josefin text-xs px-2 py-1 bg-slate/75 rounded-md'>
                {formatSeconds(hoverTime)}
              </div>
            </div>
            <div
              className={clsx(
                'absolute top-0 z-50 pointer-events-none border-t-slate -translate-x-1/2',
                s.tooltip_triangle,
              )}
            />
          </>
        )}
        <div className={clsx('w-full, h-12 ', s.track)}>
          <div
            className={clsx(
              'absolute top-1/2 left-0 w-full pointer-events-none transition-all bg-white/10 -translate-y-1/2 rounded-full',
              s.track_base,
            )}
          />
          <div
            className={clsx(
              'absolute top-1/2 left-0 transition-all pointer-events-none bg-white/20 -translate-y-1/2 rounded-r-full',
              s.track_loaded,
            )}
          />
          <div
            className={clsx(
              'bg-white absolute top-1/2 left-0 pointer-events-none transition-all -translate-y-1/2  rounded-l-full',
              s.track_played,
            )}
          />
          <div
            className={clsx(
              'border-[3px] border-white bg-white rounded-full -translate-x-1/2 -translate-y-1/2  absolute top-1/2 pointer-events-none',
              s.knob,
              {
                [`${s.knob__sliding} bg-purple-3`]: isSliding,
              },
            )}
          />
        </div>
      </div>
    );
  },
);

TimelineSlider.displayName = 'TimelineSlider';
