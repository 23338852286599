import * as React from 'react';

import clsx from 'clsx';
import NextLink, { LinkProps as NextLinkProps } from 'next/link';
import { useRouter } from 'next/router';

import { ExternalLink } from '@/components/common';
import { useUIDispatch } from '@/components/ui';

type DrawerLinkProps = React.ComponentPropsWithRef<'a'> & NextLinkProps;

export const DrawerLink = React.memo<DrawerLinkProps>(
  ({ children, href, as, replace, scroll, shallow, prefetch, locale, className, ...rest }) => {
    const router = useRouter();
    const uiDispatch = useUIDispatch();

    const activeClass = router.asPath === href ? `active` : '';
    const isExternal = href?.startsWith('http') ?? false;

    if (isExternal) {
      return (
        <ExternalLink
          className={clsx(className, activeClass)}
          href={href}
          onClick={() => uiDispatch({ type: 'DRAWER_CLOSE' })}
          {...rest}
        >
          {children}
        </ExternalLink>
      );
    }

    return (
      <NextLink
        as={as}
        className={clsx(className, activeClass)}
        href={href}
        locale={locale}
        onClick={() => uiDispatch({ type: 'DRAWER_CLOSE' })}
        onKeyPress={() => uiDispatch({ type: 'DRAWER_CLOSE' })}
        passHref
        prefetch={prefetch}
        replace={replace}
        scroll={scroll}
        shallow={shallow}
        {...rest}
      >
        {children}
      </NextLink>
    );
  },
);

DrawerLink.displayName = 'DrawerLink';
