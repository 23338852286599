import { logger } from './logger';

export const uploadFile = async (signedUrl: string, file: File, onUploadProgress: (number: number) => void) => {
  try {
    return new Promise((resolve) => {
      const xhr = new XMLHttpRequest();
      xhr.open('PUT', signedUrl);
      xhr.addEventListener('readystatechange', function () {
        if (this.readyState === 4) {
          resolve(xhr.response);
        }
      });

      if (onUploadProgress) {
        xhr.upload.onprogress = (e) => onUploadProgress(Math.ceil((e.loaded / e.total) * 100));
      }

      xhr.setRequestHeader('Content-Type', file.type);
      xhr.send(file);
    });
  } catch (e) {
    logger.error(e);
  }
};

export const readUploadedFileAsImage = (inputFile: File): Promise<HTMLImageElement> => {
  const temporaryFileReader = new FileReader();

  return new Promise((resolve, reject) => {
    temporaryFileReader.onerror = () => {
      temporaryFileReader.abort();
      reject(new DOMException('Problem parsing input file.'));
    };

    temporaryFileReader.onload = () => {
      const img = new Image();
      img.src = String(temporaryFileReader.result);
      img.onload = () => resolve(img);
    };

    temporaryFileReader.readAsDataURL(inputFile);
  });
};
