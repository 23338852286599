/* eslint-disable @typescript-eslint/ban-ts-comment */
import * as React from 'react';

import { logger } from '@/utils/logger';

export type UseGoogleCastWebSenderOptions = {
  debug?: boolean;
  onCastEnded?: () => void;
  onCastEnding?: (remotePlayer: cast.framework.RemotePlayer) => void;
  onCastFailed?: () => void;
  // onCastResume?: (
  //   remotePlayer: cast.framework.RemotePlayer,
  //   remotePlayerController: cast.framework.RemotePlayerController,
  // ) => void;
  onCastStart?: (
    remotePlayer: cast.framework.RemotePlayer,
    remotePlayerController: cast.framework.RemotePlayerController,
  ) => void;
  onMediaLoadingError?: () => void;
  onProgressChanged?: (currentTime: number) => void;
  onSeekBackward?: () => void;
  onSeekForward?: () => void;
  startingSeekPosition?: number;
  startingVolume?: number;
  metadata?: {
    images?: { url: string; width?: number; height?: number }[];
    subtitle?: string;
    title?: string;
  };
};

type SeekTo =
  | { type: 'back'; seconds: number; time?: never }
  | { type: 'exact'; time: number; seconds?: never }
  | { type: 'forward'; seconds: number; time?: never };

export const useGoogleCastWebSender = (url?: string, videoType?: string, options?: UseGoogleCastWebSenderOptions) => {
  const [castActive, setCastActive] = React.useState(false);
  const [castMuted, setCastMuted] = React.useState(false);
  const [castPlaying, setCastPlaying] = React.useState(true);
  const castContext = React.useRef<cast.framework.CastContext>();
  const castSession = React.useRef<cast.framework.CastSession>();
  const player = React.useRef<cast.framework.RemotePlayer>();
  const playerController = React.useRef<cast.framework.RemotePlayerController>();

  const castMuteUnmute = React.useCallback(() => playerController.current?.muteOrUnmute(), []);

  const castPlayPause = React.useCallback(() => {
    playerController.current?.playOrPause();
  }, []);

  const castSeekTo = React.useCallback(({ type, seconds, time }: SeekTo, isSeconds?: boolean) => {
    if (player.current && player.current.canSeek && playerController.current) {
      if (type === 'exact') player.current.currentTime = isSeconds ? time : player.current.duration * time;
      if (type === 'forward') player.current.currentTime = player.current.currentTime + seconds;
      if (type === 'back') player.current.currentTime = player.current.currentTime - seconds;

      playerController.current.seek();
    }
  }, []);

  const castSetVolume = React.useCallback((volume: number) => {
    if (player.current && playerController.current) {
      player.current.volumeLevel = Number(volume);
      playerController.current.setVolumeLevel();
    }
  }, []);

  const castStop = React.useCallback(() => {
    playerController.current?.stop();
    castSession.current?.endSession(true);
  }, []);

  const handleProgressChange = React.useCallback(
    (event: cast.framework.RemotePlayerChangedEvent) => {
      if (options && options.onProgressChanged && player.current) options?.onProgressChanged(event.value);
    },
    [options],
  );

  const sessionStateChanged = React.useCallback(
    async (event: cast.framework.SessionStateEventData) => {
      switch (event.sessionState) {
        case cast.framework.SessionState.SESSION_STARTED: {
          if (options?.debug) logger('SessionState: SESSION_STARTED');

          setCastActive(true);

          const session = cast.framework.CastContext.getInstance().getCurrentSession();

          if (session && url && videoType) {
            castSession.current = session;

            const mediaInfo = new chrome.cast.media.MediaInfo(url, videoType);
            mediaInfo.metadata = new chrome.cast.media.GenericMediaMetadata();
            mediaInfo.metadata.metadataType = chrome.cast.media.MetadataType.GENERIC;
            if (options?.metadata) {
              const keys = Object.keys(options.metadata);
              // @ts-ignore
              keys.forEach((key) => (mediaInfo.metadata[key] = options.metadata[key]));
            }

            const request = new chrome.cast.media.LoadRequest(mediaInfo);

            if (castSession.current) {
              try {
                await castSession.current.loadMedia(request);

                const remotePlayer = new cast.framework.RemotePlayer();

                player.current = remotePlayer;
                playerController.current = new cast.framework.RemotePlayerController(remotePlayer);

                if (options?.startingSeekPosition)
                  castSeekTo({ type: 'exact', time: options.startingSeekPosition }, true);
                if (options?.startingVolume) castSetVolume(options.startingVolume);

                if (options?.onCastStart && player.current && playerController.current)
                  options.onCastStart(player.current, playerController.current);

                playerController.current?.addEventListener(
                  cast.framework.RemotePlayerEventType.CURRENT_TIME_CHANGED,
                  handleProgressChange,
                );
              } catch (e) {
                console.error(e);
              }
            }
          }

          break;
        }

        case cast.framework.SessionState.SESSION_ENDING:
          if (options?.debug) logger('SessionState: SESSION_ENDING');

          playerController.current?.removeEventListener(
            cast.framework.RemotePlayerEventType.CURRENT_TIME_CHANGED,
            handleProgressChange,
          );

          if (options?.onCastEnding && player.current) options.onCastEnding(player.current);

          castSession.current = undefined;
          player.current = undefined;
          playerController.current = undefined;

          break;

        case cast.framework.SessionState.SESSION_ENDED:
          if (options?.debug) logger('SessionState: SESSION_ENDED');

          if (options?.onCastEnded && player.current) options.onCastEnded();

          setCastActive(false);
          setCastPlaying(false);

          break;

        case cast.framework.SessionState.SESSION_START_FAILED:
          if (options?.debug) logger('SessionState: SESSION_START_FAILED');

          if (options?.onCastFailed && player.current) options.onCastFailed();

          break;
      }
    },
    [handleProgressChange, options, castSeekTo, castSetVolume, url, videoType],
  );

  const handlePlayerChange = React.useCallback((event: cast.framework.RemotePlayerChangedEvent) => {
    // if (options?.debug) logger('RemotePlayerChangedEvent', event);
    if (event.field === 'playerState' && event.value === 'PLAYING') setCastPlaying(true);
    if (event.field === 'playerState' && event.value === 'PAUSED') setCastPlaying(false);
    if (event.field === 'isMuted' && event.value) setCastMuted(true);
    if (event.field === 'isMuted' && !event.value) setCastMuted(false);
  }, []);

  React.useEffect(() => {
    if (typeof cast !== 'undefined' && !castContext.current)
      castContext.current = cast.framework.CastContext.getInstance();

    if (castContext.current) {
      castContext.current.addEventListener(
        cast.framework.CastContextEventType.SESSION_STATE_CHANGED,
        sessionStateChanged,
      );
    }

    if (playerController.current) {
      playerController.current.addEventListener(cast.framework.RemotePlayerEventType.ANY_CHANGE, handlePlayerChange);
    }

    return () => {
      if (castContext.current) {
        castContext.current.removeEventListener(
          cast.framework.CastContextEventType.SESSION_STATE_CHANGED,
          sessionStateChanged,
        );
      }

      if (playerController.current) {
        playerController.current.removeEventListener(
          cast.framework.RemotePlayerEventType.ANY_CHANGE,
          handlePlayerChange,
        );
      }
    };
  }, [handlePlayerChange, sessionStateChanged]);

  return {
    castActive,
    castPlaying,
    castMuted,
    castMuteUnmute,
    castPlayPause,
    castSeekTo,
    castSetVolume,
    castStop,
  };
};
