import * as React from 'react';

import toast from 'react-hot-toast';

import { trpc } from '@/utils/trpc';

export const useUserHasPromoSubscription = (id: string) => {
  const { data, error } = trpc.users.subscriptionGet.useQuery(
    { id },
    {
      onError: (error) => toast.error(error.message),
    },
  );

  const hasPromoSubscription = React.useMemo(() => {
    if (!data && !error) return undefined;
    if (error) return false;

    if (data) {
      if (!data) return false;
      if (!data.data.entitlements) return false;
      if (!data.data.subscriptions) return false;

      const subscriptionValues = Object.values(data.data.subscriptions);

      if (subscriptionValues.length < 1) return false;
      if (subscriptionValues.some((subscription) => subscription.store === 'promotional')) return true;
    }
  }, [data, error]);

  return hasPromoSubscription;
};
