import * as React from 'react';

import clsx from 'clsx';

interface PlayButtonProps extends React.ComponentPropsWithoutRef<'button'> {
  playing: boolean;
  ended: boolean;
}

export const PlayButton = React.memo<PlayButtonProps>(({ className, playing, ended, ...props }) => {
  return (
    <button
      className={clsx('hover:scale-110 transition-transform', className)}
      {...props}
      title={playing ? 'pause' : 'play'}
      type='button'
    >
      {playing && (
        <svg fill='none' height='48' viewBox='0 0 24 24' width='48' xmlns='http://www.w3.org/2000/svg'>
          <path
            clipRule='evenodd'
            d='M22.5 12C22.5 17.799 17.799 22.5 12 22.5C6.20101 22.5 1.5 17.799 1.5 12C1.5 6.20101 6.20101 1.5 12 1.5C17.799 1.5 22.5 6.20101 22.5 12ZM24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12ZM8.625 8C8.625 7.79289 8.79289 7.625 9 7.625H11C11.2071 7.625 11.375 7.79289 11.375 8V16C11.375 16.2071 11.2071 16.375 11 16.375H9C8.79289 16.375 8.625 16.2071 8.625 16V8ZM12.625 8C12.625 7.79289 12.7929 7.625 13 7.625H15C15.2071 7.625 15.375 7.79289 15.375 8V16C15.375 16.2071 15.2071 16.375 15 16.375H13C12.7929 16.375 12.625 16.2071 12.625 16V8Z'
            fill='#FCFBF6'
            fillRule='evenodd'
          />
        </svg>
      )}
      {!playing &&
        (ended ? (
          <svg fill='none' height='48' viewBox='0 0 25 24' width='48' xmlns='http://www.w3.org/2000/svg'>
            <path
              clipRule='evenodd'
              d='M16.9882 3.47682C15.1382 2.45412 13.0059 2.06036 10.9126 2.35487C8.82888 2.64804 6.89615 3.60746 5.40293 5.0894L2.02246 7.99215V4.00049C2.02246 3.58628 1.68667 3.25049 1.27246 3.25049C0.858247 3.25049 0.522461 3.58628 0.522461 4.00049V10.0005C0.522461 10.4147 0.858247 10.7505 1.27246 10.7505H7.27246C7.68667 10.7505 8.02246 10.4147 8.02246 10.0005C8.02246 9.58628 7.68667 9.25049 7.27246 9.25049H2.85954L6.40106 6.20945C6.41529 6.19722 6.42906 6.18447 6.44234 6.17122C7.70818 4.90752 9.35042 4.08944 11.1216 3.84024C12.8928 3.59104 14.6971 3.92422 16.2624 4.78958C17.8278 5.65494 19.0696 7.00561 19.8006 8.63806C20.5316 10.2705 20.7123 12.0963 20.3154 13.8404C19.9185 15.5844 18.9655 17.1523 17.6001 18.3077C16.2347 19.463 14.5308 20.1434 12.7451 20.2461C10.9594 20.3489 9.18865 19.8686 7.69969 18.8775C6.21073 17.8864 5.0842 16.4382 4.48984 14.7512C4.3522 14.3605 3.92392 14.1554 3.53324 14.2931C3.14256 14.4307 2.93744 14.859 3.07508 15.2497C3.77751 17.2434 5.10886 18.9549 6.86854 20.1262C8.62822 21.2974 10.7209 21.8651 12.8313 21.7437C14.9416 21.6222 16.9554 20.8182 18.5691 19.4527C20.1827 18.0873 21.3089 16.2344 21.778 14.1732C22.247 12.1121 22.0335 9.95428 21.1696 8.02502C20.3057 6.09576 18.8381 4.49952 16.9882 3.47682Z'
              fill='#FCFBF6'
              fillRule='evenodd'
            />
          </svg>
        ) : (
          <svg fill='none' height='48' viewBox='0 0 24 24' width='48' xmlns='http://www.w3.org/2000/svg'>
            <path
              clipRule='evenodd'
              d='M22.5 12C22.5 17.799 17.799 22.5 12 22.5C6.20101 22.5 1.5 17.799 1.5 12C1.5 6.20101 6.20101 1.5 12 1.5C17.799 1.5 22.5 6.20101 22.5 12ZM24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12ZM16 12L9 7.5V16.5L16 12Z'
              fill='#FCFBF6'
              fillRule='evenodd'
            />
          </svg>
        ))}
    </button>
  );
});

PlayButton.displayName = 'PlayButton';
