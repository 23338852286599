import { FAQ } from './types';

export const faqs: FAQ[] = [
  {
    question: 'How is this different from an Affiliate program?',
    answer:
      'The 28 Wellness Collective is not just an affiliate program, it’s a partnership that allows you to develop your own downline, meaning you not only earn money from your direct referrals but also referrals from your referrals.',
  },
  {
    question: 'Why don’t I see “Become a Partner” on my 28.co account?',
    answer:
      'You need to first upgrade to Premium to be qualified for becoming a partner. Once your status is updated, go to 28.co > tap “become a partner” to access your partner portal.',
  },
  {
    question: 'Are there any costs involved in joining the 28 Wellness Collective?',
    answer: 'All you need to be eligible is to have an active subscription to 28.',
  },
  {
    question: 'Can you give me an example of how the compensation works?',
    answer:
      'As a Partner, you earn commission from people that you refer directly and people that your referrals refer as well to a limited extent.',
  },
  {
    question: 'How can I track my earnings and signups?',
    answer:
      'Once you become a Partner, you will be granted access to your Partner Portal, which is your back office. There you can see everyone who has signed up under you as well as your real time earnings. The portal provides complete transparency so you can constantly track the results of your efforts.',
  },
  {
    question: 'I don’t have any experience starting my own business, what tools will you provide to help me succeed?',
    answer:
      'We will host training sessions on using the back office, and also trainings on how to leverage your online (eg. social media) and offline resources to recruit your team and grow your community.',
  },
  {
    question: 'Who can I contact if I have problems or need support?',
    answer: 'You can contact us through the Partner Portal Support section or simply email us at support@28.co',
  },
];
