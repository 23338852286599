import * as React from 'react';

import { AnimalEnergy } from '@prisma/client';
import NextImage from 'next/image';

type AnimalEnergyImageProps = {
  animalEnergy: AnimalEnergy | undefined;
  className?: string;
};

export const AnimalEnergyImage = React.memo<AnimalEnergyImageProps>(({ animalEnergy, className }) => {
  return (
    <div className={className}>
      <svg
        className='animate-spin-reverse origin-center relative z-10 -mb-[104px]'
        fill='currentColor'
        height='104'
        role='img'
        viewBox='0 0 104 104'
        width='104'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path d='M26.7353 13.3029C41.4741 -1.56105 63.8508 -4.2745 81.1435 6.70863C81.8071 7.16134 82.4708 7.64033 83.118 8.12906C68.9547 2.03367 52.8336 2.62406 39.1491 9.73983C36.2637 11.3965 33.6159 13.437 31.2706 15.8022C21.5025 25.6533 17.8814 40.1768 22.0546 52.797C24.9822 61.6544 31.1693 67.6186 39.4805 69.5967C48.0975 71.6471 57.9958 69.0844 65.6704 63.0193C64.3334 65.2271 62.7362 67.2709 60.9244 69.1047C52.4603 77.6406 40.6747 80.0654 30.9064 75.2782V75.2848C23.5158 71.709 17.9595 65.1542 15.6077 57.2383C12.2409 46.4814 13.9522 32.8937 20.0737 21.7854C21.9604 18.7083 24.1951 15.864 26.7357 13.3019L26.7353 13.3029Z' />
        <path d='M17.4774 10.8099C19.621 8.648 22.0119 6.74202 24.598 5.13785C24.8517 4.98039 25.1087 4.82619 25.3689 4.67529C24.572 5.39373 23.801 6.13185 23.0561 6.88307C12.2758 17.3744 6.92825 32.3202 8.58467 47.3354C10.9688 66.5949 23.226 79.7506 40.577 81.6924C49.8771 82.916 58.3153 80.2521 64.3236 74.1928C71.7174 66.7361 74.5051 55.4216 71.8865 44.4935L71.8833 44.4968C75.2565 47.9446 77.2961 52.4882 77.6377 57.3139C78.0931 65.2987 75.1949 73.1096 69.6457 78.824C69.3692 79.1028 69.0895 79.3718 68.8032 79.6343L68.8064 79.631C60.9864 86.7366 50.4828 90.0532 40.0409 88.7086C29.391 87.4357 19.8211 81.5441 13.832 72.5714C0.365214 53.5576 1.97541 26.4436 17.4786 10.8087L17.4774 10.8099Z' />
        <path d='M0.000641688 58.7863C4.09933 69.1134 11.3699 77.5808 16.1415 82.393C32.468 96.8045 57.8629 96.2239 72.8073 81.1526C73.4221 80.5325 74.0304 79.8797 74.6029 79.217C77.8982 75.6511 80.1817 71.2618 81.2094 66.5051C82.2406 61.7451 81.9836 56.7947 80.4645 52.1724C77.192 41.6944 68.2921 32.6795 57.629 28.6575L57.6257 28.6542C62.17 28.0932 66.7762 28.9789 70.7967 31.1901C78.2068 35.4154 83.6684 42.4127 85.9978 50.667C87.6275 56.5949 87.6309 62.8509 86.0078 68.7785C84.3912 74.7064 81.2033 80.08 76.7958 84.3215C67.4922 93.4351 54.7541 98.0604 41.8263 97.0171C32.1619 95.6491 22.9824 91.8897 15.1033 86.0828C7.18576 80.2763 1.93527 71.4841 0.543198 61.7084C0.338268 60.7472 0.159371 59.7663 0 58.7854L0.000641688 58.7863Z' />
        <path d='M42.2325 28.1109C44.9975 25.4012 48.1203 23.0918 51.5164 21.248C59.5381 18.2627 68.4412 18.8631 76.0016 22.8983C85.0999 27.8224 91.7231 36.3847 94.2471 46.4819C97.8908 61.1792 94.2146 76.7279 84.1567 89.1287C72.5434 100.827 58.9823 105.889 45.9346 103.366C58.8291 102.284 70.6829 97.1657 78.9221 88.8566L78.9188 88.8599C85.2945 82.4563 89.331 74.0618 90.366 65.0463C91.999 53.5119 88.8862 42.3872 81.8179 34.5567C75.26 27.3461 65.8068 23.5273 56.126 24.1833C50.3326 24.6786 44.9133 27.2899 40.8955 31.5321C39.1552 33.32 37.5809 35.2686 36.1885 37.3485C37.519 33.8679 39.5846 30.7156 42.2325 28.1109Z' />
        <path d='M33.6348 20.1685C43.3804 10.3402 58.2004 7.18455 72.3183 11.9274C91.3514 19.0039 108.494 40.4126 102.938 62.6473C102.844 63.0574 102.743 63.474 102.639 63.8874C103.299 45.8153 94.0351 27.8777 78.4178 19.1285C65.9367 13.4042 50.1636 15.1788 39.9683 23.3998C39.3535 23.9279 38.7908 24.4561 38.254 24.9974L38.2573 24.9942C32.2654 30.9057 29.4418 39.3499 30.6683 47.7119C31.8133 54.9553 35.6843 61.477 41.4775 65.9089C35.2514 64.0127 30.0596 59.6364 27.1059 53.7904C22.8966 46.0942 22.9548 36.7377 27.2716 29.104C28.9046 25.7972 31.0487 22.7831 33.6348 20.1685Z' />
      </svg>
      {animalEnergy && (
        <NextImage
          alt={`${AnimalEnergy[animalEnergy]} Energy`}
          className='relative z-20'
          height={104}
          src={`/icons/${AnimalEnergy[animalEnergy].toLowerCase()}.svg`}
          width={104}
        />
      )}
    </div>
  );
});

AnimalEnergyImage.displayName = 'AnimalEnergyImage';
