import * as React from 'react';

import toast from 'react-hot-toast';

import { useUploadFile } from '@/hooks/useUploadfile';
import { readUploadedFileAsImage } from '@/utils/file';
import { trpc } from '@/utils/trpc';

export type UpdateImageInput = {
  files?: FileList;
  title: string;
};

export const useImageUpdate = (id: string) => {
  const utils = trpc.useContext();
  const { mutateAsync, ...rest } = trpc.images.update.useMutation({
    onMutate: () => toast.loading('Saving...'),
    onError: ({ message }) => {
      toast.remove();
      toast.error(message);
    },
    onSuccess: async (data) => {
      await Promise.all([utils.images.byId.invalidate({ id: data.id }), utils.images.all.invalidate()]);
      toast.remove();
      toast.success(`Image ${data.title} updated`);
    },
  });

  const { isUploading, uploadProgress, upload } = useUploadFile();

  const updateImage = React.useCallback(
    async (input: UpdateImageInput) => {
      const { title, files } = input;
      const file = files?.[0];

      const data: Record<string, any> = { title };

      if (file) {
        const { width, height } = await readUploadedFileAsImage(file);
        const { url, fileName } = await upload(file);

        data.fileName = fileName;
        data.type = file.type;
        data.width = width;
        data.height = height;
        data.url = url;
      }

      await mutateAsync({ id, ...data });
    },
    [id, mutateAsync, upload],
  );

  return {
    ...rest,
    isUploading,
    progress: uploadProgress,
    updateImage,
  };
};
