import * as React from 'react';

import { zodResolver } from '@hookform/resolvers/zod';
import { User } from '@prisma/client';
import { useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import { z } from 'zod';

import { FormErrorMessage, StructuredModal } from '@/components/common';
import { Button, Radio, useUIDispatch } from '@/components/ui';
import { DurationValues, durationValues, promotionDurationMap } from '@/types/revenue-cat';
import { trpc } from '@/utils/trpc';

type UserPromotionFormValues = { duration: DurationValues };

const schema: z.ZodType<UserPromotionFormValues> = z.lazy(() => z.object({ duration: durationValues }));

type UserPromotionFormProps = { user: User };

export const UserPromotionForm = React.memo<UserPromotionFormProps>(({ user }) => {
  const uiDispatch = useUIDispatch();

  const { mutate, isLoading } = trpc.users.promoSubscriptionGrant.useMutation({
    onSuccess: () => {
      uiDispatch({ type: 'MODAL_CLOSE' });
    },
    onError: (error) => {
      toast.error(error.message, { duration: 10000 });
    },
  });

  const {
    handleSubmit,
    setValue,
    formState: { isSubmitting, errors },
  } = useForm<UserPromotionFormValues>({
    defaultValues: { duration: undefined },
    resolver: zodResolver(schema),
  });

  const onSubmit = handleSubmit(async (data) => mutate({ id: user.id, duration: data.duration }));

  return (
    <StructuredModal
      className='min-w-[1024px]'
      footer={
        <Button disabled={isSubmitting || isLoading} form='user-promotion-form' type='submit' variant='primary'>
          Confirm
        </Button>
      }
      title='How long should the promotion last?'
    >
      <form className='grid grid-cols-1 gap-3 p-4' id='user-promotion-form' onSubmit={onSubmit}>
        <FormErrorMessage className='mt-0' errors={errors} name='duration' />

        <div className='grid grid-cols-1 gap-5 items-center'>
          {Object.keys(promotionDurationMap).map((key) => {
            return (
              <Radio
                key={key}
                label={promotionDurationMap[key as keyof typeof promotionDurationMap]}
                onClick={() => setValue('duration', key as DurationValues)}
                value={key}
              />
            );
          })}
        </div>
      </form>
    </StructuredModal>
  );
});

UserPromotionForm.displayName = 'UserPromotionForm';
