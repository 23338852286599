import * as React from 'react';

import clsx from 'clsx';

export interface AnnouncementProps {
  children: React.ReactNode;
  onClose?: () => void;
  className?: string;
}

export const Announcement = React.memo<AnnouncementProps>(({ children, onClose, className = '' }) => {
  return (
    <div
      className={clsx('p-4 text-khaki-1 bg-gradient-purple-to-blue font-josefin', className)}
      data-testid='announcement-banner'
      role='banner'
    >
      <div className='relative text-center'>
        {children}
        {onClose && (
          <button className='absolute right-0' onClick={onClose}>
            x
          </button>
        )}
      </div>
    </div>
  );
});

Announcement.displayName = 'Announcement';
