import * as React from 'react';

export const OrDivider = React.memo(() => (
  <div className='flex items-center gap-4'>
    <span className="bg-[url('/images/squiggle.svg')] bg-repeat-x bg-right grow h-8" />
    <span className='uppercase text-xs font-medium'>Or</span>
    <span className="bg-[url('/images/squiggle.svg')] bg-repeat-x bg-left grow h-8" />
  </div>
));

OrDivider.displayName = 'OrDivider';
