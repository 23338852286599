import * as React from 'react';

import clsx from 'clsx';

export const DashboardCard = React.memo<React.ComponentPropsWithoutRef<'div'>>(({ children, className, ...rest }) => {
  return (
    <div
      className={clsx(
        'flex flex-col items-center justify-center py-8 px-14 rounded-2xl bg-khaki-1/10 text-khaki-1 backdrop-blur-xs text-center ',
        'max-w-[311px] min-w-[311px] lg:min-w-[calc((100%-32px)/3)] lg:max-w-[calc((100%-32px)/3)]',
        className,
      )}
      {...rest}
    >
      {children}
    </div>
  );
});

DashboardCard.displayName = 'DashboardCard';
