import { User as Auth0User } from '@auth0/auth0-react';

import { Auth0Role, UserWithIdentityClaims } from '@/types/auth0';

export function userWithIdentity(auth0User: Auth0User | undefined) {
  return auth0User ? (auth0User as UserWithIdentityClaims) : undefined;
}

export const requireRoles = (auth0User: Auth0User | undefined, roles: Auth0Role[] | Auth0Role) => {
  const identity = userWithIdentity(auth0User);

  if (!identity) return false;

  const userRoles = identity['https://28.co/roles'] || [];
  const requiredRoles = Array.isArray(roles) ? roles : [roles];

  return userRoles.some((role) => requiredRoles.indexOf(role) >= 0);
};

export const userCanPlayVideos = (auth0User: Auth0User | undefined) => {
  const identity = userWithIdentity(auth0User);

  if (!identity) return false;

  // return identity['https://28.co/roles']?.some((role) => role === 'ADMIN' || role === 'SUBSCRIBER') || false;
  return true;
};
