import * as React from 'react';

import { Phase } from '@prisma/client';
import dynamic from 'next/dynamic';
import { FieldErrors } from 'react-hook-form';

import { FormErrorMessage } from '@/components/common';
import { PhasedayAdminCard, PhasedayBrowserProps } from '@/components/phases';
import { Button, useUIDispatch } from '@/components/ui';

interface PhasedaysSelectFieldProps {
  errors?: FieldErrors;
  name: string;
  label: string;
  phasedays?:
    | {
        phaseday: {
          number: number;
          phase: Phase & { days: { number: number }[] };
          id: string;
        };
      }[]
    | null;
  onPhasedaysSelect: (
    phasedays: {
      phaseday: {
        number: number;
        phase: Phase & { days: { number: number }[] };
        id: string;
      };
    }[],
  ) => void;
}

const PhasedayBrowser = dynamic<PhasedayBrowserProps>(
  () => import('@/components/phases').then((mod) => mod.PhasedayBrowser),
  { ssr: false },
);

export const PhasedaysSelectField = React.memo<PhasedaysSelectFieldProps>(
  ({ errors, name, label, phasedays, onPhasedaysSelect }) => {
    const [selectedPhasedays, setSelectedPhasedays] = React.useState(phasedays);
    const uiDispatch = useUIDispatch();

    const onSelectPhasedays = React.useCallback(
      (
        phasedays: {
          phaseday: {
            number: number;
            phase: Phase & { days: { number: number }[] };
            id: string;
          };
        }[],
      ) => {
        setSelectedPhasedays(phasedays);
        onPhasedaysSelect(phasedays);
      },
      [onPhasedaysSelect],
    );

    return (
      <div>
        <label className='font-bold block mb-2 text-sm'>{label}</label>
        {selectedPhasedays && selectedPhasedays.length > 0 && (
          <div className='grid grid-flow-row grid-cols-7 gap-4 mb-4'>
            {selectedPhasedays
              ?.sort((a, b) => a.phaseday.number - b.phaseday.number)
              ?.map((p) => (
                <PhasedayAdminCard
                  className='border border-khaki-2-3'
                  key={p.phaseday.id}
                  phaseName={p.phaseday.phase.name}
                  phasedayNumber={p.phaseday.number}
                />
              ))}
          </div>
        )}
        <div>
          <Button
            onClick={() =>
              uiDispatch({
                type: 'MODAL_OPEN',
                content: (
                  <PhasedayBrowser
                    onPhasedaysSelect={onSelectPhasedays}
                    selectedPhasedays={selectedPhasedays ?? null}
                  />
                ),
              })
            }
            size='sm'
            type='button'
            variant='secondary'
          >
            {phasedays && phasedays?.length > 0 ? 'Swap' : 'Choose'} Phasedays
          </Button>
        </div>
        <FormErrorMessage errors={errors} name={name} />
      </div>
    );
  },
);

PhasedaysSelectField.displayName = 'PhasedaysSelectField';
