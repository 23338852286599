import * as React from 'react';

import clsx from 'clsx';
import NextImage from 'next/image';
import { useRouter } from 'next/router';

import { Type } from '@/analytics/client';
import { Button } from '@/components/ui';
import { useAnalyticsDispatch } from '@/context/analytics';
import { useUrl } from '@/hooks/useUrl';

export interface HighlightProps {
  icon: string;
  image: string;
  imageWidth: number;
  imageHeight: number;
  heading: string;
  headingGradient?: 'yellow-to-purple' | 'purple-to-blue' | 'blue-to-green';
  ctaText?: string;
  reverse: boolean;
  children: React.ReactNode;
}

export const Highlight = React.memo<HighlightProps>(
  ({
    icon,
    image,
    imageWidth,
    imageHeight,
    heading,
    headingGradient = 'yellow-to-purple',
    children,
    ctaText,
    reverse,
  }) => {
    const router = useRouter();
    const url = useUrl();
    const analyticsDispatch = useAnalyticsDispatch();

    const handleClick = React.useCallback<React.MouseEventHandler<HTMLButtonElement>>(
      (event) => {
        event.preventDefault();

        if (ctaText && process.env.NEXT_PUBLIC_AUTH0_DOMAIN) {
          analyticsDispatch({
            type: 'CTA_CLICKED',
            payload: {
              url,
              destination: process.env.NEXT_PUBLIC_AUTH0_DOMAIN,
              location: 'Highlight',
              text: ctaText,
              type: Type.Button,
            },
          });
        }

        router.push('/signup/questionnaire');
      },
      [analyticsDispatch, ctaText, router, url],
    );

    return (
      <div
        className={clsx(
          'box-content max-w-7xl mx-auto px-6 md:px-12 sm:flex sm:gap-10 sm:max-w-5xl sm:items-center lg:gap-14',
          {
            'flex-row-reverse': reverse,
          },
        )}
      >
        <div className='mb-2 text-center leading-none sm:shrink-0 sm:mb-0 sm:w-1/2 lg:w-[62.5%]'>
          <NextImage
            alt={heading}
            className='object-cover mx-auto'
            height={imageHeight}
            quality={90}
            src={image}
            width={imageWidth}
          />
        </div>
        <div className='grid gap-4 place-items-start'>
          <div className='w-24'>
            <NextImage alt={heading} className='object-cover' height={160} src={icon} width={160} />
          </div>
          <h2
            className={clsx('mb-0 font-josefin font-bold text-2xl bg-clip-text text-transparent md:text-4xl', {
              'bg-gradient-yellow-to-purple': headingGradient === 'yellow-to-purple',
              'bg-gradient-purple-to-blue': headingGradient === 'purple-to-blue',
              'bg-gradient-blue-to-green': headingGradient === 'blue-to-green',
            })}
          >
            {heading}
          </h2>
          {children}
          {ctaText && (
            <Button onClick={handleClick} variant='primary'>
              {ctaText}
            </Button>
          )}
        </div>
      </div>
    );
  },
);

Highlight.displayName = 'Highlight';
