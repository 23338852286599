import * as React from 'react';

import { useUser } from '@clerk/nextjs';
import toast from 'react-hot-toast';

import { useUIDispatch } from '@/components/ui';
import { UserDeleteConfirmation } from '@/components/users';
import { getUserMetadata } from '@/utils/auth/getUserMetadata';
import { trpc } from '@/utils/trpc';

export const useUserDelete = (id?: string) => {
  const { user } = useUser();
  const { userId } = getUserMetadata(user);
  const utils = trpc.useContext();
  const dispatch = useUIDispatch();

  const mode = userId === id ? 'self-delete' : 'user-delete';

  const { mutate, isLoading } = trpc.users.deleteProcedure.useMutation({
    onMutate: () => toast.loading('Saving...'),
    onSuccess: () => {
      toast.remove();
      toast.success('User delete successful');
      if (mode === 'user-delete') utils.users.invalidate();
      dispatch({ type: 'MODAL_CLOSE' });
    },
    onError: (error) => {
      toast.remove();
      toast.error(error.message);
      if (mode === 'user-delete') utils.users.invalidate();
      dispatch({ type: 'MODAL_CLOSE' });
    },
  });

  const deleteUserMutate = React.useCallback(async () => {
    if (id) mutate({ id });
  }, [id, mutate]);

  const deleteUser = () =>
    dispatch({
      type: 'MODAL_OPEN',
      content: <UserDeleteConfirmation deleteUser={deleteUserMutate} isLoading={isLoading} mode={mode} />,
    });

  return { deleteUser, isLoading };
};
