import { Prisma } from '@prisma/client';
import * as Sentry from '@sentry/nextjs';
import {
  isBefore,
  isSameDay,
  differenceInDays,
  endOfDay,
  startOfDay,
  addDays,
  subDays,
  isAfter,
  differenceInCalendarDays,
} from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import utcToZonedTime from 'date-fns-tz/utcToZonedTime';
import { Moon } from 'lunarphase-js';

import { DEFAULT_CYCLE_DURATION, DEFAULT_MENSTRUAL_PHASE_LENGTH, MAX_CYCLE_DURATION } from '@/lib/constants';

import { segments } from './segments';

/*
 * NOTE:
 * Determining moon phases is tough stuff. There is this nice package...
 *
 * https://github.com/jasonsturges/lunarphase-js
 *
 * however it's not totally accurate and there is no means to arrive at the
 * when the last new moon was.
 *
 * Open git issue feature request:
 * https://github.com/jasonsturges/lunarphase-js/issues/2
 *
 * The function below is our current workaround to determine the *approximate*
 * date of the last new mood by looping through the lunar age of the last thirty days
 * and finding the lowest number
 *
 * The accuracy is close. But compared to
 * http://www.paulcarlisle.net/mooncalendar/#references
 *
 * we will either land exacty on the day or +/- a day or two. I believe this is
 * more to due with lunarphase-js calculating of the lunar age.
 */

export const getLastNewMoonDate = () => {
  const thirtyDaysAgo = new Date();
  thirtyDaysAgo.setHours(0, 0, 0, 0);
  thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);

  const today = new Date();

  let date = new Date(thirtyDaysAgo);
  const dates: Date[] = [];

  while (date <= today) {
    const newDate = date.setDate(date.getDate() + 1);
    date = new Date(newDate);
    dates.push(date);
  }

  const lastNewMoon = dates.sort((a, b) => {
    return Moon.lunarAge(a) < Moon.lunarAge(b) ? -1 : 0;
  })[0];

  lastNewMoon.setHours(0, 0, 0, 0);

  return new Date(lastNewMoon.setHours(0));
};

export const timeOfDay = (date?: Date) => {
  const hour = (date ?? new Date()).getHours();
  return (hour < 12 && 'morning') || (hour < 18 && 'afternoon') || 'evening';
};

const getRawPhaseday = (daysSincePeriodStart: number, cycleDuration: number) => {
  let resp: number;
  //? Since we're looking up to 2 months in the future, we might need more than a single cycle duration added.
  while (daysSincePeriodStart < cycleDuration * -1) {
    daysSincePeriodStart += cycleDuration;
  }

  if (daysSincePeriodStart < 0) {
    //? If daysSincePeriodStart is negative (cycle start is in the future) the get phase day by counting backward from cycle duration.
    resp = cycleDuration + daysSincePeriodStart + 1;
  } else {
    resp = (daysSincePeriodStart % cycleDuration) + 1;
  }

  if (resp > MAX_CYCLE_DURATION || resp < 1) {
    Sentry.captureException(new Error('Raw phaseday out of bounds.'), {
      extra: {
        value: resp,
        args: {
          daysSincePeriodStart,
          cycleDuration,
        },
      },
    });
  }

  return Math.min(Math.max(resp, 1), MAX_CYCLE_DURATION);
};

const cycleOverrideValidator = Prisma.validator<Prisma.User$cycleOverridesArgs>()({
  select: { startDate: true, menstrualDuration: true },
});

export const getCurrentCycleData = (
  cycleStart: Date,
  cycleDuration: number,
  timeZone: string,
  cycleOverrides?: Prisma.CycleOverrideGetPayload<typeof cycleOverrideValidator>[],
  menstrualDuration?: number,
) => {
  const now = utcToZonedTime(new Date(), timeZone);
  const previousOverrides = cycleOverrides?.filter((co) =>
    isBefore(startOfDay(utcToZonedTime(co.startDate, timeZone)), now),
  );
  const lastOverride = previousOverrides?.[previousOverrides.length - 1];

  if (lastOverride && differenceInDays(now, startOfDay(lastOverride?.startDate)) <= MAX_CYCLE_DURATION) {
    cycleStart = lastOverride.startDate;
    menstrualDuration = lastOverride.menstrualDuration;
  }

  const futureOverrides = cycleOverrides?.filter((co) => isAfter(startOfDay(co.startDate), now));
  const nextOverride = futureOverrides?.[0];
  if (lastOverride && nextOverride) {
    const diff = differenceInDays(startOfDay(nextOverride.startDate), startOfDay(lastOverride.startDate));
    if (diff <= MAX_CYCLE_DURATION) {
      cycleDuration = diff;
    }
  }

  const daysSinceLastRecordedPeriod = differenceInDays(now, lastOverride?.startDate ?? cycleStart) - 1;
  if (daysSinceLastRecordedPeriod > cycleDuration) {
    const rawPhaseday = getRawPhaseday(daysSinceLastRecordedPeriod, cycleDuration);
    cycleStart = zonedTimeToUtc(startOfDay(subDays(now, rawPhaseday)), timeZone);
  }

  return { cycleStart, cycleDuration, menstrualDuration };
};

/**
 * @deprecated - Use userPhasedayNumberV2 instead
 */
export const userPhasedayNumber = (
  _cycleStart: Date,
  _cycleDuration: number,
  timeZone: string,
  menstrualPhaseLength?: number,
  cycleOverrides?: Prisma.CycleOverrideGetPayload<typeof cycleOverrideValidator>[],
) => {
  if (!menstrualPhaseLength) {
    menstrualPhaseLength = DEFAULT_MENSTRUAL_PHASE_LENGTH;
  }

  const { cycleStart, cycleDuration, menstrualDuration } = getCurrentCycleData(
    _cycleStart,
    _cycleDuration,
    timeZone,
    cycleOverrides,
    menstrualPhaseLength,
  );

  const now = new Date();

  const rightNowZoned = utcToZonedTime(now, timeZone);
  const cycleStartZoned = utcToZonedTime(cycleStart, timeZone);

  const daysSincePeriodStart = differenceInDays(endOfDay(rightNowZoned), startOfDay(cycleStartZoned));

  const phasedayNumberRaw = getRawPhaseday(daysSincePeriodStart, cycleDuration);
  const phasedayNumber = phaseDayNumberFromRaw(
    phasedayNumberRaw,
    cycleDuration,
    menstrualDuration ?? menstrualPhaseLength,
  );

  return phasedayNumber;
};

export const phaseDayNumberFromRaw = (
  phaseDayNumberRaw: number,
  cycleDuration: number,
  menstrualPhaseLength: number,
) => {
  // if phaseDayNumber raw is in the user's menstrual cycle, return the number between 1 and 5 that's closest
  if (menstrualPhaseLength >= phaseDayNumberRaw) {
    const menstrualPhaseMultiplier = DEFAULT_MENSTRUAL_PHASE_LENGTH / menstrualPhaseLength;
    const menstrualPhaseDayRaw = menstrualPhaseMultiplier * phaseDayNumberRaw;

    //? Math.min here to make sure we're never over 5 so that we're always showing 'Menstrual' content when relevant
    const menstrualPhaseDay = Math.min(Math.round(menstrualPhaseDayRaw), DEFAULT_MENSTRUAL_PHASE_LENGTH);
    return menstrualPhaseDay;
  }

  const DEFAULT_REST_OF_CYCLE_LENGTH = DEFAULT_CYCLE_DURATION - DEFAULT_MENSTRUAL_PHASE_LENGTH;
  const userRestOfCycleLength = cycleDuration - menstrualPhaseLength;

  //? So if the user isn't in the menstrual phase, we have to figure out where she is
  //? in the restOfCycle phase and map that to our 28 day value
  const restOfPhaseMultiplier = DEFAULT_REST_OF_CYCLE_LENGTH / userRestOfCycleLength;

  const restOfPhasedayRaw = phaseDayNumberRaw - menstrualPhaseLength;
  const restOfPhaseday = Math.round(restOfPhaseMultiplier * restOfPhasedayRaw);

  const phasedayNumber = restOfPhaseday + DEFAULT_MENSTRUAL_PHASE_LENGTH;

  return phasedayNumber;
};

/**
 * @deprecated - Use userPhasedayNumberRawV2 instead
 */
export const userPhasedayNumberRaw = (
  _cycleStart: Date,
  _cycleDuration: number,
  timeZone: string,
  cycleOverrides?: Prisma.CycleOverrideGetPayload<typeof cycleOverrideValidator>[],
) => {
  const now = new Date();

  const { cycleStart, cycleDuration } = getCurrentCycleData(_cycleStart, _cycleDuration, timeZone, cycleOverrides);

  const rightNowZoned = utcToZonedTime(now, timeZone);
  const cycleStartZoned = utcToZonedTime(new Date(cycleStart), timeZone);

  const daysSincePeriodStart = differenceInDays(endOfDay(rightNowZoned), startOfDay(cycleStartZoned));

  return getRawPhaseday(daysSincePeriodStart, cycleDuration);
};

export const userCycleDaysLeft = (
  cycleStart: Date,
  cycleDuration: number,
  timeZone: string,
  cycleOverrides: Prisma.CycleOverrideGetPayload<typeof cycleOverrideValidator>[],
) => {
  return cycleDuration - userPhasedayNumberRaw(cycleStart, cycleDuration, timeZone, cycleOverrides) + 1;
};

export const userLastPeriodDate = (
  cycleStart: Date,
  cycleDuration: number,
  timeZone: string,
  cycleOverrides: Prisma.CycleOverrideGetPayload<typeof cycleOverrideValidator>[],
) => {
  const rightNowZoned = utcToZonedTime(new Date(), timeZone);
  const phasedayNumber = userPhasedayNumberRaw(cycleStart, cycleDuration, timeZone, cycleOverrides);

  const lastPeriodDate = new Date(rightNowZoned);
  lastPeriodDate.setDate(lastPeriodDate.getDate() - (phasedayNumber - 1));

  return startOfDay(lastPeriodDate);
};

export const getPhasedayNumberByDate = (
  cycleStart: Date,
  cycleDuration: number,
  timeZone: string,
  byDate: Date = new Date(),
  menstrualPhaseLength?: number,
) => {
  if (!menstrualPhaseLength) {
    menstrualPhaseLength = DEFAULT_MENSTRUAL_PHASE_LENGTH;
  }
  let daysSincePeriodStart: number;

  const cycleStartZoned = utcToZonedTime(cycleStart, timeZone);

  if (isSameDay(cycleStartZoned, byDate)) {
    daysSincePeriodStart = 0;
  } else if (isBefore(byDate, cycleStartZoned)) {
    daysSincePeriodStart = differenceInDays(startOfDay(cycleStartZoned), endOfDay(byDate)) * -1 + cycleDuration - 1;
  } else {
    daysSincePeriodStart = differenceInDays(startOfDay(byDate), startOfDay(cycleStartZoned));
  }

  const phasedayNumberRaw = getRawPhaseday(daysSincePeriodStart, cycleDuration);
  const phasedayNumber = phaseDayNumberFromRaw(phasedayNumberRaw, cycleDuration, menstrualPhaseLength);

  return { phasedayNumber, phasedayNumberRaw };
};

export const surroundingDaysPhaseNames = ({
  currentPhasedayNumber,
  cycleDuration,
  cycleStart,
  timeZone,
  cycleOverrides,
}: {
  currentPhasedayNumber?: number;
  cycleStart: Date;
  cycleDuration: number;
  timeZone: string;
  cycleOverrides?: Prisma.CycleOverrideGetPayload<typeof cycleOverrideValidator>[];
}) => {
  let phasedayNumber = currentPhasedayNumber;
  if (!phasedayNumber) {
    phasedayNumber = userPhasedayNumberRaw(cycleStart, cycleDuration, timeZone, cycleOverrides);
  }

  const cycleStartZoned = utcToZonedTime(new Date(cycleStart), timeZone);
  const nextDayZoned = utcToZonedTime(addDays(new Date(), 1), timeZone);
  const prevDayZoned = utcToZonedTime(subDays(new Date(), 1), timeZone);

  const daysSincePeriodStartNext = differenceInDays(endOfDay(nextDayZoned), startOfDay(cycleStartZoned));
  const daysSincePeriodStartPrev = differenceInDays(endOfDay(prevDayZoned), startOfDay(cycleStartZoned));

  const nextDayPhasedayNumber = (daysSincePeriodStartNext % cycleDuration) + 1;
  const nextDayPhasedayNumberConverted = Math.round((nextDayPhasedayNumber / cycleDuration) * DEFAULT_CYCLE_DURATION);
  const prevDayPhasedayNumber = (daysSincePeriodStartPrev % cycleDuration) + 1;
  const prevDayPhasedayNumberConverted = Math.round((prevDayPhasedayNumber / cycleDuration) * DEFAULT_CYCLE_DURATION);

  const nextDayPhaseName = segments.find((segment) => segment.days.includes(nextDayPhasedayNumberConverted))?.name;
  const prevDayPhaseName = segments.find((segment) => segment.days.includes(prevDayPhasedayNumberConverted))?.name;

  return {
    nextDayPhaseName,
    prevDayPhaseName,
  };
};

export const getNextCycleStartDate = ({
  startOfDayZoned,
  cycleStart,
  cycleDuration,
}: {
  startOfDayZoned: Date;
  cycleStart: Date;
  cycleDuration: number;
}) => {
  const daysBetween = differenceInCalendarDays(startOfDayZoned, cycleStart);
  const daysTillNextPeriod = cycleDuration - (daysBetween % cycleDuration);
  const nextPeriod = addDays(startOfDayZoned, daysTillNextPeriod);

  return nextPeriod;
};

export const getNextFertileWindowStartDate = ({
  startOfDayZoned,
  cycleStart,
  cycleDuration,
  timeZone,
}: {
  startOfDayZoned: Date;
  cycleStart: Date;
  cycleDuration: number;
  timeZone: string;
}) => {
  let nextFertileWindowStartDate: Date | undefined = undefined;
  let date = startOfDayZoned;

  while (!nextFertileWindowStartDate) {
    const { phasedayNumber } = getPhasedayNumberByDate(cycleStart, cycleDuration, timeZone, date);
    if (phasedayNumber === 14) {
      nextFertileWindowStartDate = date;
    } else {
      date = addDays(date, 1);
    }
  }

  return nextFertileWindowStartDate;
};

export const getStartOfDayZoned = (date: Date | string, timeZone: string) => {
  return zonedTimeToUtc(startOfDay(utcToZonedTime(new Date(date), timeZone)), timeZone);
};
