import * as React from 'react';

import clsx from 'clsx';
import NextImage from 'next/image';

import { clamp } from '@/utils/number';
import { trpc } from '@/utils/trpc';

export interface VideoThumbnailProps {
  alt: string;
  playback:
    | {
        muxPlaybackId: string;
        policy: string;
      }
    | undefined;
  width: number;
  height: number;
  className?: string;
}

export const VideoThumbnail = React.memo<VideoThumbnailProps>(({ playback, width, height, alt, className }) => {
  const playbackId = playback?.muxPlaybackId;

  const requiresTokens = playback?.policy === 'signed';
  const tokenPlaybackId = requiresTokens ? playbackId : undefined;

  const maxWidth = clamp(0, width * 2, 640);

  const { data: thumbnailToken } = trpc.videos.token.useQuery(
    { playbackId: tokenPlaybackId as string, type: 'thumbnail' },
    {
      enabled: !!tokenPlaybackId,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  );
  const thumbnailSrc = React.useMemo(() => {
    if (!playbackId || (requiresTokens && !thumbnailToken)) return;

    const url = new URL(`https://image.mux.com/${playbackId}/thumbnail.jpg`);
    const params = new URLSearchParams();
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion, @typescript-eslint/no-non-null-asserted-optional-chain
    params.set('token', thumbnailToken?.data.token!);
    requiresTokens && params.set('width', String(maxWidth));
    url.search = params.toString();

    return url.toString();
  }, [maxWidth, playbackId, requiresTokens, thumbnailToken]);

  if (!thumbnailSrc) {
    return (
      <div className='animate-pulse'>
        <div className='bg-khaki-2-3 rounded-lg' style={{ width, height }} />
      </div>
    );
  }

  return (
    <NextImage
      alt={alt}
      className={clsx('object-cover', className)}
      height={height}
      src={thumbnailSrc}
      unoptimized={true}
      width={width}
    />
  );
});

VideoThumbnail.displayName = 'VideoThumbnail';
