import { OnboardingQuestion } from '@/stores/useOnboardingStore';

export const getURL = (): string => {
  if (process.env.NEXT_PUBLIC_VERCEL_ENV === 'production' && process.env.NEXT_PUBLIC_URL) {
    return process.env.NEXT_PUBLIC_URL;
  }

  if (process.env.VERCEL_GIT_COMMIT_REF === 'canary' || process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF === 'canary')
    return 'https://dev.28.co';

  if (process.env.NEXT_PUBLIC_VERCEL_ENV === 'preview' || process.env.NEXT_PUBLIC_VERCEL_ENV === 'development') {
    if (typeof window !== 'undefined' && window.origin.includes('28.co') && process.env.NEXT_PUBLIC_URL) {
      return process.env.NEXT_PUBLIC_URL;
    }

    if (typeof window !== 'undefined') return window.origin;

    return `https://${process.env.NEXT_PUBLIC_VERCEL_URL}`;
  }

  return process.env.NEXT_PUBLIC_URL || 'http://localhost:3000';
};

// General
export const SITE_NAME = '28';
export const SITE_DESCRIPTION = '#1 Free Cycle-Based Fitness + Wellness Experience';
export const SITE_TWITTER_HANDLE = '28wellness';
export const SITE_URL = getURL();
export const SITE_IMAGE = `${SITE_URL}/images/og-default.jpg`;
export const SITE_SUPPORT_EMAIL = 'support@28.co';

// Stripe
export const CURRENCY = 'usd';

// Cycle
export const DEFAULT_CYCLE_DURATION = 28;
export const DEFAULT_MENSTRUAL_PHASE_LENGTH = 5;
export const MAX_CYCLE_DURATION = 40;

// RevenueCat
export const REVENUE_CAT_API_ENDPOINT = 'https://api.revenuecat.com/v1';

// Onboarding

export const DETAILS_QUESTION_ID = 'USER_DETAILS';
export const CONSTANT_QUESTIONS: OnboardingQuestion[] = [
  {
    id: DETAILS_QUESTION_ID,
    text: 'Tell us about yourself.',
    presentationOrder: 8,
    value: null,
    type: 'USER_DETAILS',
  },
];

export const DEFAULT_WEIGHT = 170;
export const DEFAULT_HEIGHT = 65;
export const DEFAULT_AGE = 29;
export const MAX_WEIGHT = 400;
export const MIN_WEIGHT = 70;

// Legacy Date
export const LEGACY_DATE = '2023-10-31';

export const DEFAULT_FREE_MESSAGE_LIMIT = 5;

export const PARTNER_TOTP_CODE_TTL_IN_S = 60 * 60 * 24;
export const TOTP_CHAR_SET = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ234567';

export const DEFAULT_SHARED_SYMPTOM_SLUGS = [
  'physical_cramps',
  'physical_tender_breasts',
  'physical_insomnia',
  'physical_headache',
  'physical_bloating',
  'physical_joint_pain',
  'physical_backache',
  'physical_energetic',
  'physical_pelvic-pain',
  'physical_fatigue',
  'physical_cravings',
  'emotions_anxiety',
  'emotions_depression',
  'emotions_mood-swings',
  'emotions_happy',
  'emotions_sexy',
  'emotions_relaxed',
  'emotions_stressed',
  'sex_and_libido_high_libido',
  'sex_and_libido_low_libido',
  'sex_and_libido_orgasm',
  'sex_and_libido_unprotected',
  'sex_and_libido_protected',
  'sex_and_libido_makeout_sesh',
  'cycle_disruptors_drugs',
  'cycle_disruptors_alcohol',
  'cycle_disruptors_trauma',
  'cycle_disruptors_travel',
  'cycle_disruptors_illness',
  'flow_light',
  'flow_medium',
  'flow_heavy',
  'flow_spotting',
  'flow_blood_clots',
];

export const STRIPE_TO_EPIXEL_PACKAGE_MAPPINGS = {
  prod_NesQa3vVemLWiE: '554',
} as const;

export const EPIXEL_CAMPAIGN_ID = 'EPIXEL_CAMPAIGN' as const;

export const MAX_PERIOD_LENGTH = 10;

export const SURVEY_NAMES = ['Survey 1', 'Survey 2', 'Survey 3'];

export const SURVEY_CONTENT = [
  {
    treatmentContent: {
      CTAText:
        "We're thrilled to have you participate in our research study! Please complete this survey before you start using 28 for cycle syncing.",
      thankYouText: `Thank you for sharing these aspects of your life with us. Your responses are key to building a comprehensive understanding of the starting point for our study.
        
        We will send you another survey after 3 months to understand the experience you had.`,
    },
    controlContent: {
      CTAText:
        "We're thrilled to have you participate in our research study! Please complete this survey before you start using 28 for cycle syncing.",
      thankYouText: `Thank you for sharing these aspects of your life with us. Your responses are key to building a comprehensive understanding of the starting point for our study.
        
        We will send you another survey after 3 months to understand the experience you had.`,
    },
  },
  {
    treatmentContent: {
      CTAText:
        'It’s been 3 months since you started cycle syncing, and we would love to touch base with you! Please complete this 5 min survey!',
      thankYouText: `Thank you for sharing these aspects of your life with us. Your responses are key to building a comprehensive understanding of the starting point for our study.
        
        We will send you another survey after 3 months to understand the experience you had.`,
    },
    controlContent: {
      CTAText:
        'It’s been 3 months since you participated in the study, and we would love to touch base with you! Please complete this 5 min survey!',
      thankYouText: `Thank you for sharing these aspects of your life with us. Your responses are key to building a comprehensive understanding of the starting point for our study.
        
        We will send you another survey after another 3 months to understand the experience you had.`,
    },
  },
  {
    treatmentContent: {
      CTAText:
        'It’s been 6 months since you started cycle syncing, and we would love to touch base with you! Please complete this 5 min survey!',
      thankYouText: `Thank you for sharing these aspects of your life with us. Your responses are key to building a comprehensive understanding of the starting point for our study.
        
        We appreciate you participating in this study and being consistent in taking the survey. 
        
        We will share the results of the study with you once it’s finalized!`,
    },
    controlContent: {
      CTAText:
        'It’s been 6 months since you participated in the study, and we would love to touch base with you! Please complete this 5 min survey!',
      thankYouText: `Thank you for sharing these aspects of your life with us. Your responses are key to building a comprehensive understanding of the starting point for our study.
        
        We appreciate you participating in this study and being consistent in taking the survey. 
        
        Your premium access will be granted very soon!`,
    },
  },
];
