import * as React from 'react';

import { zodResolver } from '@hookform/resolvers/zod';
import { useRouter } from 'next/router';
import { SubmitHandler, useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import urlSlug from 'url-slug';
import { z } from 'zod';

import { Button, Input } from '@/components/ui';
import { useLeavePrevention } from '@/hooks/useLeavePrevention';
import { ERROR, WARN } from '@/utils/form';
import { trpc } from '@/utils/trpc';

type InstructorCreateFormValues = { name: string };

const schema: z.ZodType<InstructorCreateFormValues> = z.lazy(() =>
  z.object({ name: z.string().min(1, { message: ERROR.REQUIRED }) }),
);

export const InstructorCreateForm = React.memo(() => {
  const router = useRouter();
  const {
    register,
    formState: { isSubmitting, errors, isSubmitSuccessful, isDirty },
    handleSubmit,
  } = useForm<InstructorCreateFormValues>({ resolver: zodResolver(schema) });
  const { mutate } = trpc.instructors.create.useMutation({
    onMutate: () => toast.loading('Saving...'),
    onSuccess: (instructor) => {
      toast.remove();
      toast.success(`Instructor ${instructor.name} created`);
      router.push(`/admin/instructors/${instructor.id}`);
    },
    onError: ({ message }) => {
      toast.remove();
      toast.error(message);
    },
  });

  useLeavePrevention(isDirty && !isSubmitting && !isSubmitSuccessful, WARN.UNSAVED_CHANGES);

  const onSubmit = React.useCallback<SubmitHandler<InstructorCreateFormValues>>(
    ({ name }) => mutate({ name, slug: urlSlug(name) }),
    [mutate],
  );

  return (
    <form className='bg-khaki-1 rounded-lg p-8 overflow-hidden' onSubmit={handleSubmit(onSubmit)}>
      <Input errors={errors} label='Name' {...register('name')} />
      <div className='flex gap-x-6 mt-2 -mx-8 -mb-8 p-8 py-5 rounded-bl-lg bg-khaki-2-3'>
        <Button disabled={isSubmitting} state={isSubmitting ? 'waiting' : undefined} type='submit' variant='primary'>
          Next
        </Button>
      </div>
    </form>
  );
});

InstructorCreateForm.displayName = 'InstructorCreateForm';
