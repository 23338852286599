import * as React from 'react';

type PlainTextProps = {
  content: string;
};

export const PlainText = ({ content }: PlainTextProps) => {
  return (
    <>
      {content.split('\n').map((item, idx) => (
        // eslint-disable-next-line react/no-array-index-key
        <React.Fragment key={idx}>
          {item}
          <br />
        </React.Fragment>
      ))}
    </>
  );
};
