import { ImageLoaderProps } from 'next/image';

// borrowed from next/image
export function imgixLoader({ src, width, quality }: ImageLoaderProps) {
  const url = new URL(`https://${`${process.env.NEXT_PUBLIC_IMGIX_SOURCE}.imgix.net`}${src}`);
  const params = url.searchParams;
  params.set('auto', params.get('auto') || 'format');
  params.set('fit', params.get('fit') || 'max');
  params.set('w', params.get('w') || width.toString());
  if (quality) {
    params.set('q', quality.toString());
  }
  return url.href;
}
