import formatDistanceStrict from 'date-fns/formatDistanceStrict';
import locale from 'date-fns/locale/en-US';

const formatDistanceMap = {
  lessThanXSeconds: '{{count}}s',
  xSeconds: '{{count}}s',
  halfAMinute: '30s',
  lessThanXMinutes: '{{count}}m',
  xMinutes: '{{count}}m',
  aboutXHours: '{{count}}h',
  xHours: '{{count}}h',
  xDays: '{{count}}d',
  aboutXWeeks: '{{count}}w',
  xWeeks: '{{count}}w',
  aboutXMonths: '{{count}}m',
  xMonths: '{{count}}m',
  aboutXYears: '{{count}}y',
  xYears: '{{count}}y',
  overXYears: '{{count}}y',
  almostXYears: '{{count}}y',
};

const formatDistance = (token: keyof typeof formatDistanceMap, count: string, options: any) => {
  options = options || {};

  const result = formatDistanceMap[token].replace('{{count}}', count);

  if (options.addSuffix) {
    if (options.comparison > 0) {
      return 'in ' + result;
    } else {
      return result + ' ago';
    }
  }

  return result;
};

export const formatDuration = (duration: number | null | undefined): string => {
  if (!duration) return '';

  return formatDistanceStrict(0, duration * 1000, {
    roundingMethod: 'round',
    locale: {
      ...locale,
      formatDistance,
    },
  });
};

export const formatSeconds = (seconds: number) => {
  if (isNaN(seconds)) {
    return `00:00`;
  }
  const date = new Date(seconds * 1000);
  const hh = date.getUTCHours();
  const mm = date.getUTCMinutes();
  const ss = date.getUTCSeconds().toString().padStart(2, '0');
  if (hh) {
    return `${hh}:${mm.toString().padStart(2, '0')}:${ss}`;
  }
  return `${mm}:${ss}`;
};

export const findPlayback = (
  playbacks:
    | {
        muxPlaybackId: string;
        policy: string;
      }[]
    | undefined,
) => {
  if (!playbacks) return;

  const publicPlayback = playbacks.find((p) => p.policy === 'public');
  const signedPlayback = playbacks.find((p) => p.policy === 'signed');

  return publicPlayback ?? signedPlayback;
};
