import * as React from 'react';

export const Spiral = React.memo<React.ComponentPropsWithoutRef<'svg'> & { size?: string | number | undefined }>(
  ({ size, width, height, ...rest }) => (
    <svg
      fill='none'
      height={size || height || 24}
      viewBox='0 0 24 24'
      width={size || width || 24}
      xmlns='http://www.w3.org/2000/svg'
      {...rest}
    >
      <path
        d='M2 12.7546L13.5553 1L16.6575 3.77598H16.5631C15.9663 3.77598 15.3698 3.77598 14.7732 3.77598C14.7482 3.77676 14.7233 3.77204 14.7003 3.76216C14.6773 3.75228 14.6568 3.73747 14.6401 3.71879C14.3065 3.38394 13.9707 3.0497 13.6368 2.71485C13.616 2.68801 13.5982 2.65905 13.5836 2.62845L3.63459 12.7484L12.2239 21.3423C15.0765 18.4874 17.9304 15.6309 20.7855 12.7727L13.5673 5.54679C11.165 7.95133 8.75965 10.3589 6.35122 12.7696L12.2279 18.6518L18.099 12.7733L13.5741 8.24252L9.02327 12.7536L12.2294 15.9601L15.4061 12.7696L13.5627 10.9247L11.7236 12.7635L12.2337 13.2736L13.2792 12.2263L14.0882 13.0356L12.219 14.9045L10.0786 12.7644L13.5651 9.27721L17.0379 12.7746L12.2147 17.5972L7.37208 12.7552L13.5864 6.5944L19.7422 12.7592L12.2067 20.2954L4.70095 12.7619L13.5596 3.8999L22.4275 12.7776L12.2242 23L2 12.7546Z'
        fill='currentColor'
      />
    </svg>
  ),
);

Spiral.displayName = 'Spiral';
