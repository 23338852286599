import * as React from 'react';

import { Switch } from '@headlessui/react';
import clsx from 'clsx';
import NextImage from 'next/image';

interface LunarSwitchProps {
  disabled?: boolean;
  enabled: boolean;
  showDescription: boolean;
  lastNewMoon?: Date;
  setEnabled: (enabled: boolean) => void;
}

export const LunarSwitch = React.memo<LunarSwitchProps>(
  ({ disabled = false, enabled, setEnabled, showDescription, lastNewMoon }) => {
    return (
      <div>
        <Switch.Group as='div' className='flex items-center gap-x-4'>
          <Switch.Label as='span' className='font-bold text-sm flex items-center gap-x-2' passive>
            <NextImage alt='Lunar Cycle moon' height='32' src='/images/temp/lunar-cycle-moon.svg' width='58' />
            Follow the Lunar Cycle
          </Switch.Label>
          <Switch
            checked={enabled}
            className={clsx(
              enabled ? 'bg-purple-1' : 'bg-slate/20',
              'relative inline-flex items-center p-0.5 h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out',
              'focus:outline-none focus:ring-2 focus:ring-purple/25 focus:ring-offset-1',
              'disabled:cursor-wait',
            )}
            disabled={disabled}
            onChange={setEnabled}
          >
            <span
              aria-hidden='true'
              className={clsx(
                enabled ? 'translate-x-5' : 'translate-x-0',
                'pointer-events-none inline-block h-4 w-4 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
              )}
            />
          </Switch>
        </Switch.Group>
        {showDescription && (
          <>
            <p className='my-5 text-sm leading-relaxed'>
              If you have an irregular cycle or no cycle at all, pregnant, or on birth control, we suggest using the
              last New Moon in place of your period. Your body benefits from a consistent schedule of exercise and
              nutrition. While our descriptions of what you’re feeling may seem inaccurate, your body will adapt and
              your cycle will soon align with our curriculum.
            </p>
            {lastNewMoon && <p className='text-sm'>Last New Moon: {lastNewMoon.toLocaleDateString()}</p>}
          </>
        )}
      </div>
    );
  },
);

LunarSwitch.displayName = 'LunarSwitch';
