import * as React from 'react';

import { PhaseCard } from '@/components/marketing';
import phasesData from '@/data/phases.json';

export const FourPhases = React.memo(() => {
  return (
    <div className='box-content max-w-7xl mx-auto px-6 md:px-12 grid gap-6 md:gap-8 sm:grid-cols-2'>
      {phasesData?.map((phase) => (
        <PhaseCard
          description={phase.description}
          heading={phase.heading}
          image={phase.image}
          key={phase.name}
          name={phase.name}
        />
      ))}
    </div>
  );
});

FourPhases.displayName = 'FourPhases';
