import * as React from 'react';

import clsx from 'clsx';
import NextImage from 'next/image';
import { useRouter } from 'next/router';

import { Link } from '@/components/common';
import { adminMenu } from '@/data/adminMenu';

interface AdminLayoutProps {
  title?: React.ReactNode;
  actions?: React.ReactNode;
  children?: React.ReactNode;
}

export const AdminLayout = React.memo<AdminLayoutProps>(({ title, actions, children }) => {
  const router = useRouter();

  const acitveLink = React.useMemo(
    () => adminMenu.find((link) => router.asPath.startsWith(`/admin/${link.href.split('/')[2]}`)),
    [router.asPath],
  );

  const showSecondaryMenu = React.useMemo(() => {
    const adminHrefs = adminMenu.flatMap((i) => {
      return [i.href, ...(i.secondaryMenu?.map((i) => i.href) ?? [])];
    });

    return adminHrefs.includes(router.asPath);
  }, [router.asPath]);

  return (
    <div className='pl-64 pt-20 min-w-[1536px]'>
      <aside className='flex flex-col w-64 fixed inset-y-0 left-0 p-8 text-white gap-12 bg-gradient-purple'>
        <Link href='/'>
          <NextImage alt='28' height={62} src='/images/logo/white.svg' width={64} />
        </Link>
        <nav className='flex flex-col gap-2'>
          {adminMenu.map((link) => {
            const Icon = link.icon;
            return (
              <Link
                className={clsx('flex items-center -ml-4 p-4 gap-x-4 rounded-full text-khaki-2 text-sm', {
                  'bg-white/10': link.href === acitveLink?.href,
                })}
                href={link.href}
                key={link.href}
              >
                <Icon size={24} weight='light' />
                {link.title}
              </Link>
            );
          })}
        </nav>
      </aside>

      <div>
        <header className='-mt-20 sticky z-10 top-0 left-64 right-0 px-12 bg-khaki-2 border-b-khaki-3 border-b'>
          <div className='h-20 flex items-center justify-between'>
            <h1 className='font-josefin font-bold text-2xl tracking-tighter'>{title}</h1>
            <nav className='flex items-center gap-4'>{actions}</nav>
          </div>
          {acitveLink && showSecondaryMenu && (
            <div className='flex gap-x-6 bg-khaki-2 -mx-12 px-12 border-t border-t-khaki-3'>
              {acitveLink.secondaryMenu?.map((link) => (
                <Link
                  className={clsx(
                    'py-4 border-b text-sm',
                    router.asPath === link.href ? 'border-b-purple text-purple' : 'border-b-transparent',
                  )}
                  href={link.href}
                  key={link.href}
                >
                  {link.title}
                </Link>
              ))}
            </div>
          )}
        </header>
        <main className='grow px-12 pb-8 mt-8'>{children}</main>
      </div>
    </div>
  );
});

AdminLayout.displayName = 'AdminLayout';
