import * as React from 'react';

import NextLink from 'next/link';

import { ExternalLink } from '@/components/common';
import { Button, ButtonVariant } from '@/components/ui';

export type ButtonLinkProps = React.AnchorHTMLAttributes<HTMLAnchorElement> & {
  href: string;
  size?: 'sm' | 'lg';
  variant?: ButtonVariant;
  external?: boolean;
};

export const ButtonLink = React.forwardRef<HTMLAnchorElement, ButtonLinkProps>(
  ({ className, variant = 'primary', size = 'lg', children, href, external, ...rest }, ref) => {
    const isExternal = href?.startsWith('http') ?? false;

    if (isExternal || external) {
      return (
        <Button as={ExternalLink} className={className} href={href} ref={ref} size={size} variant={variant} {...rest}>
          {children}
        </Button>
      );
    }

    return (
      <Button as={NextLink} className={className} href={href} ref={ref} size={size} variant={variant} {...rest}>
        {children}
      </Button>
    );
  },
);

ButtonLink.displayName = 'ButtonLink';
