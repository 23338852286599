import * as React from 'react';

import clsx from 'clsx';
import { debounce } from 'throttle-debounce';

import s from './Carousel.module.css';

interface CarouselProps {
  /**
   * In Pixels
   */
  itemGap: number;
  /**
   * In REMs
   */
  containerWidth?: number;
  children: React.ReactNode;
  onScrolled?: () => void;
  fluidGutters?: boolean;
}

export const Carousel = React.memo<CarouselProps>(
  ({ itemGap = 16, containerWidth = 74, fluidGutters = true, children, onScrolled }) => {
    const scrollRef = React.useRef<HTMLDivElement>(null);

    // allow dragging
    React.useEffect(() => {
      const scrollEl = scrollRef.current;

      if (scrollEl) {
        let dx = 0; // how far the mouse has been moved
        const pos = { left: 0, x: 0 };

        const mouseDownHandler = function (e: MouseEvent) {
          e.preventDefault();

          scrollEl.style.userSelect = 'none';

          dx = 0;
          pos.left = scrollEl.scrollLeft;
          pos.x = e.clientX; // get the current mouse position

          scrollEl.addEventListener('mousemove', mouseMoveHandler);
          scrollEl.addEventListener('mouseup', mouseUpHandler);
        };

        const mouseMoveHandler = function (e: MouseEvent) {
          scrollEl.style.cursor = 'grabbing';

          dx = e.clientX - pos.x;
          scrollEl.scrollLeft = pos.left - dx; // scroll the element
        };

        const mouseUpHandler = function (e: MouseEvent) {
          e.preventDefault();

          scrollEl.style.removeProperty('cursor');
          scrollEl.style.removeProperty('user-select');

          scrollEl.removeEventListener('mousemove', mouseMoveHandler);
          scrollEl.removeEventListener('mouseup', mouseUpHandler);
        };

        const clickHandler = function (e: MouseEvent) {
          // disable clicks when dragged
          if (Math.abs(dx) > 5) {
            e.preventDefault();
            e.stopPropagation();
          }
        };

        const scrollHandler = debounce(200, () => {
          if (onScrolled) onScrolled();
        });

        scrollEl.addEventListener('scroll', scrollHandler);
        scrollEl.addEventListener('mousedown', mouseDownHandler);
        scrollEl.addEventListener('click', clickHandler, { capture: true });

        return () => {
          scrollEl.removeEventListener('scroll', scrollHandler);
          scrollEl.removeEventListener('mousedown', mouseDownHandler);
          scrollEl.removeEventListener('click', clickHandler, { capture: true });

          scrollEl.removeEventListener('mousemove', mouseMoveHandler);
          scrollEl.removeEventListener('mouseup', mouseUpHandler);
        };
      }
    }, [onScrolled]);

    return (
      <div
        className={clsx('grid relative', s.root, fluidGutters ? s.fluidGutters : s.defaultGutters)}
        style={{ '--item-gap': itemGap + 'px', '--container': containerWidth + 'rem' } as React.CSSProperties}
      >
        <div
          className={clsx(
            s.scroll,
            'grid relative w-full max-w-full cursor-grab',
            'overflow-x-scroll overscroll-x-contain hide-scrollbar',
          )}
          ref={scrollRef}
        >
          <ul className={clsx('flex', s.container)}>
            {React.Children.map(children, (child) => {
              if (React.isValidElement(child)) {
                return {
                  ...child,
                  props: {
                    ...child.props,
                    className: clsx(child.props.className, 'snap-center relative'),
                  },
                };
              }
              return child;
            })}
          </ul>
        </div>
      </div>
    );
  },
);

Carousel.displayName = 'Carousel';
