import * as React from 'react';

import { Image } from '@prisma/client';
import clsx from 'clsx';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDebounce } from 'react-use';

import { StructuredModal } from '@/components/common';
import { ImageAdminCard, ImageCreateFormLite } from '@/components/images';
import { Button, Input, useUIDispatch } from '@/components/ui';
import { trpc } from '@/utils/trpc';

export interface ImageBrowserProps {
  selectedImage: Image | null | undefined;
  onImageSelect: (image: Image) => void;
}

export const ImageBrowser = React.memo<ImageBrowserProps>((props) => {
  const [showImageCreateForm, setShowImageCreateForm] = React.useState(false);
  const [q, setQ] = React.useState('');
  const [qVal, setQVal] = React.useState('');
  useDebounce(() => setQ(qVal), 500, [qVal]);

  const { data, isInitialLoading, isLoading, refetch, fetchNextPage } = trpc.images.all.useInfiniteQuery(
    { q, size: 20 },
    {
      getNextPageParam: (lastPage, allPages) => (lastPage.length === 20 ? allPages.length * 20 : undefined),
    },
  );

  const images = React.useMemo(() => data?.pages?.flatMap((p) => p.map((i) => i)), [data]);

  const [selectedImage, setSelectedImage] = React.useState<Image | null | undefined>(props.selectedImage);
  const uiDispatch = useUIDispatch();

  const afterCreate = React.useCallback(() => {
    setShowImageCreateForm(false);
    refetch();
  }, [refetch]);

  return (
    <StructuredModal
      className='min-w-[1024px]'
      footer={
        <Button
          disabled={!selectedImage || showImageCreateForm}
          onClick={() => {
            if (selectedImage) {
              props.onImageSelect(selectedImage);
            }
            uiDispatch({ type: 'MODAL_CLOSE' });
          }}
          variant='primary'
        >
          Use Image
        </Button>
      }
      header={
        <div className='grid grid-flow-col gap-4'>
          <Button onClick={() => setShowImageCreateForm((prev) => !prev)} size='sm' variant='primary'>
            {showImageCreateForm ? 'Cancel Upload' : 'Upload Image'}
          </Button>
          {!showImageCreateForm && (
            <Input
              aria-label='Search Images'
              onChange={(e) => setQVal(e.currentTarget.value)}
              placeholder='Search images...'
              type='search'
              value={qVal}
            />
          )}
        </div>
      }
      title='Images'
    >
      {showImageCreateForm ? (
        <ImageCreateFormLite afterCreate={afterCreate} />
      ) : (
        <InfiniteScroll
          className='grid grid-cols-4 lg:grid-cols-5 gap-4'
          dataLength={images?.length ?? 0}
          hasMore={data?.pages[data?.pages.length - 1].length === 20}
          loader={null}
          next={() => !isLoading && fetchNextPage()}
          scrollableTarget='admin-modal-scroll'
        >
          {images?.map((image) => (
            <button key={image.id} onClick={() => setSelectedImage(image)}>
              <ImageAdminCard
                className={clsx('h-full', { 'border border-purple-3 shadow-sm': selectedImage?.id === image.id })}
                image={image}
              />
            </button>
          ))}
          {(isInitialLoading || isLoading) &&
            [...Array(15)].map((_, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <ImageAdminCard key={i} />
            ))}
        </InfiniteScroll>
      )}
    </StructuredModal>
  );
});

ImageBrowser.displayName = 'ImageBrowser';
