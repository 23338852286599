import * as React from 'react';

import NextImage from 'next/image';

import { DownloadAppButton, Link } from '@/components/common';
import { Divider } from '@/components/ui';

type MenuLink = { title: string; url: string };
type Menu = { title: string; links: MenuLink[] };

const MENUS: Menu[] = [
  {
    title: 'Social',
    links: [
      {
        title: 'Instagram',
        url: 'https://www.instagram.com/28wellness',
      },
      {
        title: 'TikTok',
        url: 'https://www.tiktok.com/@28wellness',
      },
      {
        title: 'Facebook',
        url: 'https://www.facebook.com/28wellness',
      },
      {
        title: 'YouTube',
        url: 'https://www.youtube.com/channel/UCrvbOGkmNQP_y0XjqPIiUXA',
      },
      {
        title: 'Twitter',
        url: 'https://twitter.com/28wellness',
      },
    ],
  },
  {
    title: 'General',
    links: [
      {
        title: 'Method',
        url: '/method',
      },
      {
        title: 'Support',
        url: '/support',
      },
      {
        title: 'About',
        url: '/about',
      },
    ],
  },
  {
    title: 'Legal',
    links: [
      {
        title: 'Terms of Use',
        url: '/terms',
      },
      {
        title: 'Privacy',
        url: '/privacy',
      },
    ],
  },
];

const currentYear = new Date().getFullYear();

export const SiteFooter = React.memo(() => {
  return (
    <footer className='relative z-0 text-khaki-1 mt-10 md:mt-16 px-6 md:px-12'>
      <NextImage
        alt='Mountains'
        className='-z-10 object-cover object-[30%_center]'
        fill
        sizes='100vw'
        src='/images/layout/footer-bkg.jpg'
      />
      <div className='mx-auto max-w-7xl flex relative flex-col items-center md:items-start justify-between gap-8 py-10 md:py-24 md:flex-row'>
        <div className='w-16 leading-zero md:w-28 lg:w-40 md:mr-auto md:ml-0 shrink-0'>
          <NextImage alt='28' height={154} src='/images/logo/khaki.svg' width={160} />
        </div>
        <nav className='flex flex-wrap justify-center gap-x-12 gap-y-8 md:flex-nowrap lg:gap-x-24 xl:gap-x-36'>
          <h2 className='sr-only'>Footer Menu</h2>
          <div className='flex gap-2 w-full md:max-w-[170px] shrink-0 justify-center md:order-last md:flex-col md:gap-4 md:justify-start'>
            <DownloadAppButton location='Footer' os='iOS' />
            <DownloadAppButton location='Footer' os='Android' />
          </div>
          {MENUS.map((menu) => (
            <div className='flex flex-col gap-6 text-sm whitespace-nowrap' key={menu.title}>
              <Divider align='left' className='text-khaki-3' variant='stubby' />
              {menu.links.map((link) => (
                <Link href={link.url} key={link.title}>
                  {link.title}
                </Link>
              ))}
            </div>
          ))}
        </nav>
        <div className='absolute bottom-6 right-4 md:flex-row text-xs'>&copy; {currentYear}, 28 Wellness, LLC</div>
      </div>
    </footer>
  );
});

SiteFooter.displayName = 'SiteFooter';
