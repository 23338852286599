import * as React from 'react';

import NextImage from 'next/image';

import { Divider } from '@/components/ui';

interface MarketingCardProps {
  image: string;
  heading: string;
  copy: string;
}

export const MarketingCard = React.memo<MarketingCardProps>(({ image, heading, copy }) => {
  return (
    <div className='mx-auto max-w-[340px] text-center md:text-left'>
      <div className='leading-zero mb-2 lg:mb-8 w-40 md:w-auto md:max-w-[280px] mx-auto md:mx-0'>
        <NextImage
          alt={heading}
          className='object-contain object-left'
          height={327}
          quality={90}
          src={image}
          width={327}
        />
      </div>
      <header className='flex flex-col'>
        <Divider className='text-khaki-3 md:ml-0 my-4 md:my-6' variant='stubby' />
        <h4 className='text-2xl font-josefin font-bold mb-2 md:mb-0 my-2 md:-order-1'>{heading}</h4>
      </header>
      <div className='text-sm'>{copy}</div>
    </div>
  );
});

MarketingCard.displayName = 'MarketingCard';
