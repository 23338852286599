import * as React from 'react';

import { useRouter } from 'next/router';

import { off, on } from '@/utils/listeners';

export const useLeavePrevention = (active: boolean, message: string) => {
  const router = useRouter();
  const leaveConfirmed = React.useRef(false);

  const beforeUnloadListener = React.useCallback(
    (event: BeforeUnloadEvent) => {
      event.preventDefault();
      return (event.returnValue = message);
    },
    [message],
  );

  const routeChangeStartListener = React.useCallback(() => {
    if (leaveConfirmed.current) return;

    if (window.confirm(message)) {
      leaveConfirmed.current = true;
    } else {
      router.events.emit('routeChangeError');
      throw 'routeChange aborted.';
    }
  }, [message, router.events]);

  React.useEffect(() => {
    leaveConfirmed.current = false;

    if (active) {
      on(window, 'beforeunload', beforeUnloadListener, { capture: true });
      router.events.on('routeChangeStart', routeChangeStartListener);
    }

    return () => {
      off(window, 'beforeunload', beforeUnloadListener, { capture: true });
      router.events.off('routeChangeStart', routeChangeStartListener);
    };
  }, [beforeUnloadListener, active, routeChangeStartListener, router.events]);
};
