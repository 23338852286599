import * as React from 'react';

import { zodResolver } from '@hookform/resolvers/zod';
import { useRouter } from 'next/router';
import { SubmitHandler, useForm } from 'react-hook-form';
import { z } from 'zod';

import { Button, Checkbox, Input, ProgressBar } from '@/components/ui';
import { useLeavePrevention } from '@/hooks/useLeavePrevention';
import { VideoCreateInput, useVideoCreate } from '@/request/client';
import { ERROR, WARN } from '@/utils/form';

const schema = z.object({
  title: z.string().min(1, { message: ERROR.REQUIRED }),
  isPreview: z.boolean().default(false),
  files: z
    .any()
    .refine((files) => files?.length == 1, ERROR.REQUIRED)
    .refine((files) => files?.[0]?.type.includes('video/'), ERROR.UNSUPPORTED_FORMAT),
});

export const VideoCreateForm = React.memo(() => {
  const router = useRouter();
  const {
    register,
    handleSubmit,
    formState: { isSubmitting, isSubmitSuccessful, isDirty, errors },
  } = useForm<VideoCreateInput>({ resolver: zodResolver(schema) });
  const { createVideo, isUploading, progress } = useVideoCreate();

  useLeavePrevention(isSubmitting, WARN.UPLOAD_IN_PROGRESS);
  useLeavePrevention(isDirty && !isSubmitting && !isSubmitSuccessful, WARN.UNSAVED_CHANGES);

  const onSubmit = React.useCallback<SubmitHandler<VideoCreateInput>>(
    ({ title, files, isPreview }) => createVideo({ title, files, isPreview }),
    [createVideo],
  );

  React.useEffect(() => {
    if (isSubmitSuccessful) router.push(`/admin/videos`);
  }, [isSubmitSuccessful, router]);

  return (
    <form className='bg-khaki-1 rounded-lg p-8 overflow-hidden' onSubmit={handleSubmit(onSubmit)}>
      <Input errors={errors} label='Title' {...register('title')} />
      <Checkbox label='Is Preview' {...register('isPreview')} />
      <p className='-mt-6 pt-0 text-xs'>
        (Video cannot changed back from a preview video. You'll have to delete the video to make that change)
      </p>

      <div>
        <Input accept='video/*' disabled={isUploading} errors={errors} type='file' {...register('files')} />
        {progress > 0 && (
          <div className='max-w-xs mt-4'>
            <ProgressBar max={100} value={progress} />
          </div>
        )}
      </div>

      <div className='flex gap-x-6 mt-2 -mx-8 -mb-8 p-8 py-5 rounded-bl-lg bg-khaki-2-3'>
        <Button disabled={isSubmitting} state={isSubmitting ? 'waiting' : undefined} type='submit' variant='primary'>
          Upload
        </Button>
      </div>
    </form>
  );
});

VideoCreateForm.displayName = 'VideoCreateForm';
