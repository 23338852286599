import * as React from 'react';

import clsx from 'clsx';
interface StatusLightProps extends React.ComponentPropsWithoutRef<'div'> {
  published: boolean;
}

export const StatusLight = React.memo<StatusLightProps>(({ published, role = 'img', className, ...rest }) => {
  return (
    <div
      className={clsx('rounded-lg w-2 h-2', { 'border-2': !published, 'bg-green': published }, className)}
      role={role}
      {...rest}
    />
  );
});

StatusLight.displayName = 'StatusLight';
