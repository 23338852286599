import * as React from 'react';

import clsx from 'clsx';
import { format, isValid } from 'date-fns';

import { ZodiacIcon, ZodiacSignIcon, ZodiacSigns, ZodiacSprite } from './ZodiacIcons';

interface BirthdayZodiacInputProps {
  defaultDate?: Date;
  defaultSign?: ZodiacSigns;
  onChange: (date: Date, sign: ZodiacSigns) => void;
  className?: string;
}

const inputClasses =
  'shrink-0 m-0 border-none bg-white text-center appearance-none text-base placeholder:text-slate-1 outline-none';

export const BirthdayZodiacInput = React.memo<BirthdayZodiacInputProps>(
  ({ onChange, defaultDate, defaultSign, className }) => {
    const inputRefs = React.useRef<(HTMLInputElement | null)[]>([]);

    const [date, setDate] = React.useState(defaultDate);
    const [sign, setSign] = React.useState<ZodiacSigns | undefined>(defaultSign);

    const [month, setMonth] = React.useState<string>(defaultDate ? format(defaultDate, 'MM') : '');
    const [day, setDay] = React.useState<string>(defaultDate ? format(defaultDate, 'dd') : '');
    const [year, setYear] = React.useState<string>(defaultDate ? format(defaultDate, 'yyyy') : '');

    React.useEffect(() => {
      setDate(new Date(`${month}-${day}-${year}`));
    }, [month, day, year]);

    React.useEffect(() => {
      if (date && isValid(date)) {
        const signIdx =
          Number(
            new Intl.DateTimeFormat('fr-TN-u-ca-persian', {
              month: 'numeric',
            }).format(date),
          ) - 1;

        setSign(Object.values(ZodiacSigns)[signIdx]);
      }
    }, [date]);

    React.useEffect(() => {
      if (date && isValid(date) && sign) {
        onChange(date, sign);
      }
    }, [onChange, date, sign]);

    return (
      <div className={className}>
        <label className='font-lato font-bold block mb-2 text-sm'>Birthday</label>
        <div className='flex gap-2 items-center py-[.875rem] pr-6 pl-4 border border-khaki-2-3  bg-white rounded-lg'>
          {sign ? <ZodiacSignIcon className='mr-1' sign={sign} /> : <ZodiacIcon className='mr-1' />}
          <input
            aria-label='month'
            className={clsx(inputClasses, 'w-8')}
            maxLength={2}
            name='month'
            onChange={(e) => {
              setMonth(e.currentTarget.value);

              if (e.currentTarget.maxLength === e.currentTarget.value.length) {
                inputRefs.current[1]?.focus();
              }
            }}
            pattern='\d*'
            placeholder='MM'
            ref={(input) => {
              inputRefs.current[0] = input;
            }}
            type='text'
            value={month}
          />
          <span>/</span>
          <input
            aria-label='day'
            className={clsx(inputClasses, 'w-8')}
            maxLength={2}
            name='day'
            onChange={(e) => {
              setDay(e.currentTarget.value);

              if (e.currentTarget.maxLength === e.currentTarget.value.length) {
                inputRefs.current[2]?.focus();
              }
            }}
            pattern='\d*'
            placeholder='DD'
            ref={(input) => {
              inputRefs.current[1] = input;
            }}
            type='text'
            value={day}
          />
          <span>/</span>
          <input
            aria-label='year'
            className={clsx(inputClasses, 'w-12')}
            maxLength={4}
            name='year'
            onChange={(e) => setYear(e.currentTarget.value)}
            pattern='\d*'
            placeholder='YYYY'
            ref={(input) => {
              inputRefs.current[2] = input;
            }}
            type='text'
            value={year}
          />
        </div>
        <div className='mt-2 h-6 absolute text-sm'>{sign ? `Zodiac sign: ${sign}` : ' '}</div>
        <ZodiacSprite />
      </div>
    );
  },
);

BirthdayZodiacInput.displayName = 'BirthdayZodiacInput';
