import { Testimonial } from './types';

export const testimonials: Testimonial[] = [
  {
    image: '/icons/flower.svg',
    quote:
      '“Women have been locked in the passenger seats of their own bodies—our education system failed us, doctors gaslit us, and hormonal birth control promised freedom but tricked our bodies into dysfunction and pain. Today, we begin anew. Today, a revolution begins. I’ll see you on the journey.”',
    author: 'Brittany Hugoboom',
    authorBio: 'Co-Founder',
  },
  {
    shortQuote: "I've been waiting for a program like this...",
    quote:
      "It's just so cool to have a program designed to support us in each phase. I've been doing the exercises on the website for two whole months now. They are VERY effective and also fun. I look forward to seeing which one has been picked out for me each day.",
    author: 'Julia T.',
  },
  {
    shortQuote: 'Truly the best app I have on my phone',
    quote:
      "This makes it SO easy and helpful to know why I'm experiencing certain things at certain times of the month. Also LOVE the food recommendations for each phase and the quick easy workouts!",
    author: 'Kim S.',
  },
  {
    shortQuote: 'Advice that is truly doable and helpful during your cycle phases',
    quote:
      "Not just in terms of good habits, but also in regards to food and exercise. It's nice to find an app that doesn't make you feel awful about not being able to keep up with an exercise chart developed without taking into account how your period affects you every day.",
    author: 'Anna E.',
  },
  {
    shortQuote: 'Wow.',
    quote:
      "I just got this app but I love the interface. It helps women's health and understanding of the cycle and how to best have self care around each phase. It's amazing.",
    author: 'Celine H.',
  },
  {
    shortQuote: 'The app predicts my wellbeing with astounding accuracy.',
    quote:
      "It has clear instructions and simple scientific explanations for your hormonal changes. They recommend affordable ingredients and you can easily get into a natural rhythm following the guidance. It's the least complex way to improve your whole body health.",
    author: 'Efua K.',
  },
  {
    shortQuote: "It's an amazing app and it's helped me understand my body better.",
    quote:
      "The workouts are also a lot of fun and easy to follow. It's easy to stay constant because the workouts are short and the nutritional information gives examples of foods to eat during the different phases of your cycle.",
    author: 'Krysty N.',
  },
  {
    shortQuote: 'Thank you 28 for making me fall back in love with working out and taking care of my body',
    quote:
      'I have noticed significant changes in my physical and mental health. I love that I feel stronger after using these workouts and have noticed a significant change in my overall health after using this for a little over a month.',
    author: 'Kyleigh D.',
  },
  {
    shortQuote:
      'I went off birth control over 2 years ago and was struggling to maintain consistency in my workout routine',
    quote:
      '28 understands the phases of your cycle, gives insights to what is happening in your body and the exercises and nutrition to benefit during that time. My biggest gain from this app is learning what to eat and the reassurance that every day is different.',
    author: 'Katarina B.',
  },
  {
    shortQuote: 'GET IT YOU WILL NOT REGRET IT!',
    quote:
      'I went off birth control over 2 years ago and have been struggling to maintain consistency in my workout routine. 28 understands the phases of your cycle, gives insights to what is happening in your body and the exercises and nutrition your body will benefit from during that time.',
    author: 'Kim S.',
  },
  {
    shortQuote: 'Basing my lifestyle off my cycle has quite literally changed my life.',
    quote:
      "Not only is the info useful and easy to understand, but it's also aesthetically pleasing. I've found it very validating when it comes to how I feel during my cycle and it has made understanding my body much easier and honestly kind of fun. Love love love the purpose behind this app.",
    author: 'Kallison M.',
  },
];
