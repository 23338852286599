import type { Image } from '@prisma/client';
import type { toZod } from 'tozod';
import { z } from 'zod';

import { ERROR } from '../form';

export const imageSchema: toZod<Image> = z.object({
  createdAt: z.date(),
  fileName: z.string(),
  height: z.number(),
  id: z.string(),
  title: z.string(),
  type: z.string(),
  updatedAt: z.date(),
  url: z.string(),
  width: z.number(),
});

/**
 * Omitting `createdAt` and `updatedAt` from Image type because they are not returned from the API
 * with the correct `Date` type. They're transformed to ISO strings before being sent to the client.
 */
export type ImageWithOmittedDates = Omit<Image, 'createdAt' | 'updatedAt'>;
export const zodImageWithOmittedDatesSchema: toZod<ImageWithOmittedDates> = z.object(
  {
    id: z.string(),
    fileName: z.string(),
    height: z.number(),
    title: z.string(),
    type: z.string(),
    url: z.string(),
    width: z.number(),
  },
  {
    required_error: ERROR.REQUIRED,
    invalid_type_error: ERROR.REQUIRED,
  },
);

const ACCEPTED_IMAGE_TYPES = ['image/jpeg', 'image/jpg', 'image/png', 'image/webp'];

export const zodImageFileCreate = z
  .any()
  .refine((files) => files?.length == 1, ERROR.REQUIRED)
  .refine(
    (files) => ACCEPTED_IMAGE_TYPES.includes(files?.[0]?.type),
    '.jpg, .jpeg, .png and .webp files are accepted.',
  );

export const zodImageFileUpdate = z
  .any()
  .refine((files) => {
    if (files?.length === 0) return true;
    return ACCEPTED_IMAGE_TYPES.includes(files?.[0]?.type);
  }, '.jpg, .jpeg, .png and .webp files are accepted.')
  .transform((files) => {
    if (files?.length === 0) return undefined;
    return files;
  });
