import * as React from 'react';

import Script from 'next/script';

import { IS_IOS } from '../Player';

export const GCastButton: React.FC = () => {
  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      window['__onGCastApiAvailable'] = function (isAvailable) {
        if (isAvailable && typeof cast !== 'undefined') {
          cast.framework.CastContext.getInstance().setOptions({
            receiverApplicationId: chrome.cast.media.DEFAULT_MEDIA_RECEIVER_APP_ID,
            autoJoinPolicy: chrome.cast.AutoJoinPolicy.ORIGIN_SCOPED,
          });
        }
      };
    }
  }, []);

  return (
    <>
      <Script
        id='cast_sender'
        src='https://www.gstatic.com/cv/js/sender/v1/cast_sender.js?loadCastFramework=1'
        strategy='afterInteractive' // __onGCastApiAvailable event listener needed before script loads
      />
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      {!IS_IOS && <google-cast-launcher style={{ maxHeight: 24, maxWidth: 24 }} />}
    </>
  );
};
