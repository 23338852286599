import * as React from 'react';

import { Player } from '@/components/player';
import { trpc } from '@/utils/trpc';

export interface VideoPlayerProps {
  playback: {
    muxPlaybackId: string;
    policy: string;
  };
  width: number;
  height: number;
}

export const VideoPlayer = React.memo<VideoPlayerProps>(({ playback, width, height }) => {
  const playbackId = playback?.muxPlaybackId;

  const requiresToken = playback?.policy === 'signed';
  const tokenPlaybackId = requiresToken ? playbackId : undefined;

  const { data: videoToken } = trpc.videos.token.useQuery(
    { playbackId: tokenPlaybackId as string, type: 'video' },
    {
      enabled: !!tokenPlaybackId,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  );

  const src = React.useMemo(() => {
    if (requiresToken && !videoToken) return;

    const url = new URL(`https://stream.mux.com/${playbackId}`);
    const params = new URLSearchParams();
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion, @typescript-eslint/no-non-null-asserted-optional-chain
    params.set('token', videoToken?.data.token!);
    url.search = params.toString();

    return url.toString();
  }, [playbackId, requiresToken, videoToken]);

  return <Player controls={true} height={height} src={src} width={width} />;
});

VideoPlayer.displayName = 'VideoPlayer';
