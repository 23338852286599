import * as React from 'react';

import dynamic from 'next/dynamic';
import { FieldErrors } from 'react-hook-form';

import { FormErrorMessage } from '@/components/common';
import { Button, useUIDispatch } from '@/components/ui';

import { ExerciseGearAdminCard } from './ExerciseGearAdminCard';
import { ExerciseGearBrowserProps } from './ExerciseGearBrowser';

interface ExerciseGearSelectFieldProps {
  errors?: FieldErrors;
  name: string;
  label: string;
  exerciseGear?:
    | {
        exerciseGear: {
          id: string;
          title: string;
          photo?: string;
        };
      }[]
    | null;
  onExerciseGearSelect: (
    exerciseGear: {
      exerciseGear: {
        id: string;
        photo?: string;
      };
    }[],
  ) => void;
}

const ExerciseGearBrowser = dynamic<ExerciseGearBrowserProps>(
  () => import('./ExerciseGearBrowser').then((mod) => mod.ExerciseGearBrowser),
  { ssr: false },
);

export const ExerciseGearSelectField = React.memo<ExerciseGearSelectFieldProps>(
  ({ errors, name, label, exerciseGear, onExerciseGearSelect }) => {
    const [selectedExerciseGear, setSelectedExerciseGear] = React.useState(exerciseGear);
    const uiDispatch = useUIDispatch();

    const onSelectExerciseGear = React.useCallback(
      (
        exerciseGear: {
          exerciseGear: {
            id: string;
            title: string;
            photo?: string;
          };
        }[],
      ) => {
        setSelectedExerciseGear(exerciseGear);
        onExerciseGearSelect(exerciseGear);
      },
      [onExerciseGearSelect],
    );

    return (
      <div>
        <label className='font-bold block mb-2 text-sm'>{label}</label>
        {selectedExerciseGear && selectedExerciseGear.length > 0 && (
          <div className='grid grid-flow-row grid-cols-7 gap-4 mb-4'>
            {selectedExerciseGear?.map((eg) => (
              <ExerciseGearAdminCard
                className='border border-khaki-2-3'
                key={eg.exerciseGear.id}
                photo={eg.exerciseGear.photo}
                title={eg.exerciseGear.title}
              />
            ))}
          </div>
        )}
        <div>
          <Button
            onClick={() =>
              uiDispatch({
                type: 'MODAL_OPEN',
                content: (
                  <ExerciseGearBrowser
                    onExerciseGearSelect={onSelectExerciseGear}
                    selectedExerciseGear={selectedExerciseGear ?? null}
                  />
                ),
              })
            }
            size='sm'
            type='button'
            variant='secondary'
          >
            {exerciseGear && exerciseGear?.length > 0 ? 'Swap' : 'Choose'} Exercise Gear
          </Button>
        </div>
        <FormErrorMessage errors={errors} name={name} />
      </div>
    );
  },
);

ExerciseGearSelectField.displayName = 'ExerciseGearSelectField';
