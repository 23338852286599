import * as React from 'react';

export const ExternalLink = React.forwardRef<HTMLAnchorElement, React.ComponentPropsWithRef<'a'>>(
  ({ children, target = '_blank', rel = 'noopener noreferrer', ...props }, ref) => {
    return (
      <a className='external' ref={ref} rel={rel} target={target} {...props}>
        {children}
      </a>
    );
  },
);

ExternalLink.displayName = 'ExternalLink';
