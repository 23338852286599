import * as React from 'react';

import clsx from 'clsx';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDebounce } from 'react-use';

import { StructuredModal } from '@/components/common';
import { InstructorAdminCard } from '@/components/instructors';
import { Button, Input, useUIDispatch } from '@/components/ui';
import { trpc } from '@/utils/trpc';

import { RouterOutput } from '../../../trpc/router';

export interface InstructorBrowserProps {
  selectedInstructor: Omit<RouterOutput['instructors']['byId'], 'coverPhoto'> | null;
  onInstructorSelect: (image: Omit<RouterOutput['instructors']['byId'], 'coverPhoto'>) => void;
}

export const InstructorBrowser = React.memo<InstructorBrowserProps>((props) => {
  const [q, setQ] = React.useState('');
  const [qVal, setQVal] = React.useState('');
  useDebounce(() => setQ(qVal), 500, [qVal]);

  const { data, isInitialLoading, isLoading, fetchNextPage } = trpc.instructors.all.useInfiniteQuery(
    { q, size: 20 },
    {
      getNextPageParam: (lastPage, allPages) => (lastPage.length === 20 ? allPages.length * 20 : undefined),
    },
  );
  const instructors = React.useMemo(() => data?.pages.flatMap((p) => p.map((i) => i)), [data]);

  const [selectedInstructor, setSelectedInstructor] = React.useState<Omit<
    RouterOutput['instructors']['byId'],
    'coverPhoto'
  > | null>(props.selectedInstructor);

  const uiDispatch = useUIDispatch();

  return (
    <StructuredModal
      footer={
        <Button
          disabled={!selectedInstructor}
          onClick={() => {
            if (selectedInstructor) {
              props.onInstructorSelect(selectedInstructor);
            }
            uiDispatch({ type: 'MODAL_CLOSE' });
          }}
          variant='primary'
        >
          Use Instructor
        </Button>
      }
      header={
        <Input
          aria-label='Search Instructors'
          onChange={(e) => setQVal(e.currentTarget.value)}
          placeholder='Search instructors...'
          type='search'
          value={qVal}
        />
      }
      title='Instructors'
    >
      <InfiniteScroll
        className='grid grid-cols-5 gap-4'
        dataLength={instructors?.length ?? 0}
        hasMore={data?.pages[data?.pages.length - 1].length === 20}
        loader={null}
        next={() => !isLoading && fetchNextPage()}
        scrollableTarget='admin-modal-scroll'
      >
        {instructors?.map((instructor) => (
          <button key={instructor.id} onClick={() => setSelectedInstructor(instructor)}>
            <InstructorAdminCard
              className={clsx('border border-khaki-3', {
                'border-purple-3': selectedInstructor && selectedInstructor.id === instructor.id,
              })}
              instructor={instructor}
            />
          </button>
        ))}
        {(isInitialLoading || isLoading) &&
          [...Array(5)].map((_, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <InstructorAdminCard className='border border-khaki-3' key={i} />
          ))}
      </InfiniteScroll>
    </StructuredModal>
  );
});

InstructorBrowser.displayName = 'InstructorBrowser';
