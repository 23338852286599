import * as React from 'react';

import { useUser } from '@clerk/nextjs';

import { Type } from '@/analytics/client';
import { Accordion, AccordionItem, AccordionPanel, AccordionButton } from '@/components/ui';
import { useAnalyticsDispatch } from '@/context/analytics';
import { faqs as basicFaqs } from '@/data/faqs';
import { faqs as networkFaqs } from '@/data/network-faqs';
import { getUserMetadata } from '@/utils/auth/getUserMetadata';
import { trpc } from '@/utils/trpc';

export const FAQs = React.memo(({ network }: { network?: boolean }) => {
  const analyticsDispatch = useAnalyticsDispatch();

  const { user } = useUser();
  const { userId } = getUserMetadata(user);

  const { data: dbUser } = trpc.users.byId.useQuery(
    { id: userId || '' },
    {
      enabled: !!userId,
    },
  );

  let faqs = basicFaqs;

  if (network) {
    faqs = networkFaqs;
  }

  return (
    <div className='mx-auto max-w-3xl px-6 md:px-12 box-content'>
      <Accordion collapsible multiple>
        {faqs.map((faq, idx) => (
          <AccordionItem
            key={faq.question}
            onClick={() => {
              analyticsDispatch({
                type: 'BUTTON_CLICKED',
                payload: {
                  email: dbUser?.data.email,
                  location: `FAQ Accordion Item #${idx + 1}`,
                  text: faq.question,
                  type: Type.Button,
                },
              });
            }}
          >
            <AccordionButton>
              <h2 className='font-josefin font-bold text-base'>{faq.question}</h2>
            </AccordionButton>
            <AccordionPanel>{faq.answer}</AccordionPanel>
          </AccordionItem>
        ))}
      </Accordion>
    </div>
  );
});

FAQs.displayName = 'FAQs';
