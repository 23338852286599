import * as React from 'react';

import ms from 'ms';

export const Time = React.memo<{ minutes?: number | null; text?: string }>(({ minutes, text }) => {
  return minutes ? (
    <>
      {text}
      {ms(minutes * 1000 * 60, { long: true })}
    </>
  ) : null;
});

Time.displayName = 'Time';
