import * as React from 'react';

import { useRouter } from 'next/router';
import { useForm } from 'react-hook-form';

import { OrDivider } from '@/components/common';
import { CycleCalendarSlider, CycleDurationSlider, LunarSwitch } from '@/components/cycle';
import { Accordion, AccordionButton, AccordionItem, AccordionPanel, Button } from '@/components/ui';
import { DEFAULT_CYCLE_DURATION, DEFAULT_MENSTRUAL_PHASE_LENGTH } from '@/lib/constants';
import { useOnboardingStore } from '@/stores/useOnboardingStore';
import { getLastNewMoonDate } from '@/utils/date';

export const CycleOnboarding: React.FC = () => {
  const { replace, query } = useRouter();
  const fromCollective = query?.source === 'collective';
  const resetCycleData = useOnboardingStore((state) => state.resetCycleData);
  const setUserOnboardingSettings = useOnboardingStore((state) => state.setCycleData);
  const cycleData = useOnboardingStore((state) => state.cycleData);
  const lastNewMoon = React.useMemo(() => getLastNewMoonDate(), []);
  const [accordionOpen, setAccordionOpen] = React.useState(false);

  const {
    setValue,
    getValues,
    watch,
    formState: { isSubmitting },
    handleSubmit,
  } = useForm({
    defaultValues: {
      cycleDuration: DEFAULT_CYCLE_DURATION,
      periodDuration: DEFAULT_MENSTRUAL_PHASE_LENGTH,
      cycleLunar: false,
      cycleStart: new Date(),
    },
  });

  const onSubmit = handleSubmit(({ cycleDuration, cycleLunar, cycleStart, periodDuration }) => {
    if (cycleData) {
      resetCycleData();
    }

    setUserOnboardingSettings({
      cycleDuration,
      cycleLunar,
      periodDuration,
      cycleStart: cycleStart.toISOString(),
    });

    replace(`/login${fromCollective ? '?source=collective&isNewUser=true' : ''}`);
  });

  const lunarEnabled = watch('cycleLunar');

  return (
    <form onSubmit={onSubmit}>
      <div className='text-center'>
        <p className='text-slate-1 mt-6'>
          Since menstruation marks the start of a new cycle, our technology uses the start of your period to determine
          which phase you&apos;re likely in.
        </p>
      </div>
      <CycleCalendarSlider
        defaultValue={getValues('cycleStart')}
        disabled={lunarEnabled}
        onChange={(date) => setValue('cycleStart', date)}
      />
      <CycleDurationSlider
        defaultValue={getValues('cycleDuration')}
        disabled={lunarEnabled}
        max={40}
        min={21}
        onChange={(duration) => setValue('cycleDuration', duration)}
        subtitle='Every woman is unique! Adjust to specify your typical cycle length (the number of days between your periods) to
        receive a more accurate experience.'
        title='Average Cycle Duration'
      />
      <CycleDurationSlider
        defaultValue={getValues('periodDuration')}
        disabled={lunarEnabled}
        max={7}
        min={3}
        onChange={(duration) => setValue('periodDuration', duration)}
        subtitle="What's your average period duration?"
        title='Average Period Length'
      />
      <OrDivider />
      <Accordion
        collapsible
        onChange={() => {
          setAccordionOpen(!accordionOpen);
        }}
      >
        <AccordionItem style={{ borderWidth: 0 }}>
          <AccordionButton
            // Style does exist but TS thinks it doesn't
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            style={{ paddingLeft: 0, paddingRight: 0, paddingTop: 0 }}
          >
            <span className='text-sm font-bold'>My Cycle Is Not Regular</span>
          </AccordionButton>
          <AccordionPanel style={{ paddingLeft: 0, paddingRight: 0 }}>
            <LunarSwitch
              enabled={lunarEnabled || false}
              lastNewMoon={lastNewMoon}
              setEnabled={(enabled) => setValue('cycleLunar', enabled)}
              showDescription={true}
            />
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
      <Button
        data-testid='period-calendar-submit-button'
        disabled={isSubmitting}
        state={isSubmitting ? 'waiting' : undefined}
        type='submit'
        variant='primary'
      >
        Continue
      </Button>
    </form>
  );
};
