import * as React from 'react';

import { DashboardCard } from '@/components/dashboard';
import { Divider, Carousel } from '@/components/ui';

export const DashboardSkeleton = React.memo(() => {
  return (
    <section>
      <header className='relative overflow-hidden py-6 md:py-8 bg-khaki-3'>
        <div className='animate-pulse'>
          <Carousel itemGap={16}>
            <DashboardCard>
              <div className='h-6 w-6 bg-khaki-2-3 rounded mt-2' />
              <div className='h-4 w-16 bg-khaki-2-3 rounded mt-2' />
              <div className='h-14 w-5/6 bg-khaki-2-3 rounded my-6' />
              <div className='h-1 w-72 bg-khaki-2-3 rounded' />
              <div className='h-3 w-3 bg-khaki-2-3 rounded mt-2' />
              <div className='h-3 w-16 bg-khaki-2-3 rounded mt-4' />
              <div className='h-2 w-24 bg-khaki-2-3 rounded mt-3' />
            </DashboardCard>
            <DashboardCard>
              <div className='h-[104px] w-[104px] bg-khaki-2-3 rounded-full' />
              <div className='h-1 w-16 bg-khaki-2-3 rounded mt-4' />
              <div className='h-2 w-24 bg-khaki-2-3 rounded mb-0 mt-2' />
              <div className='h-1 w-full bg-khaki-2-3 rounded mt-3' />
              <div className='h-1 w-full bg-khaki-2-3 rounded mt-1.5' />
              <div className='h-1 w-3/4 bg-khaki-2-3 rounded mt-1.5' />
            </DashboardCard>
            <DashboardCard>
              <div className='h-[104px] w-[104px] bg-khaki-2-3 rounded-full' />
              <div className='h-1 w-16 bg-khaki-2-3 rounded mt-4' />
              <div className='h-2 w-24 bg-khaki-2-3 rounded mb-0 mt-2' />
              <div className='h-1 w-full bg-khaki-2-3 rounded mt-3' />
              <div className='h-1 w-full bg-khaki-2-3 rounded mt-1.5' />
              <div className='h-1 w-3/4 bg-khaki-2-3 rounded mt-1.5' />
            </DashboardCard>
          </Carousel>
        </div>
      </header>
      <div className='flex flex-col gap-12 max-w-7xl px-6 md:px-12 md:mt-12 mx-auto animate-pulse'>
        <div className='max-w-7xl md:px-12'>
          <div className='grid p-6 md:p-0 bg-khaki-1 py-6 gap-6 overflow-hidden md:grid-cols-[1fr_461px] md:gap-0 md:rounded-2xl'>
            <div className='max-w-[723px] h-[408px] bg-khaki-3' />
            <div className='flex flex-col items-start gap-6 md:justify-center md:px-12 md:pb-12'>
              <header className='flex flex-col gap-2 md:col-start-2 md:row-start-1 md:pt-12'>
                <div className='h-1.5 w-24 bg-khaki-2-3 rounded' />
                <div className='h-4 w-56 bg-khaki-2-3 rounded mt-1' />
                <div className='flex gap-x-4 mt-2'>
                  <div className='h-1.5 w-8 bg-khaki-2-3 rounded' />
                  <div className='h-1.5 w-8 bg-khaki-2-3 rounded' />
                  <div className='h-1.5 w-8 bg-khaki-2-3 rounded' />
                </div>
              </header>
              <div className='flex gap-8 w-full mt-4'>
                <Divider className='h-full text-khaki-3' direction='vertical' variant='stubby' />
                <div className='grow'>
                  <div className='mb-4 h-2 w-16 bg-khaki-2-3 rounded' />
                  <div className='h-1.5 w-full mt-2 bg-khaki-2-3 rounded' />
                  <div className='h-1.5 w-full mt-2 bg-khaki-2-3 rounded' />
                  <div className='h-1.5 w-full mt-2 bg-khaki-2-3 rounded' />
                  <div className='h-1.5 w-full mt-2 bg-khaki-2-3 rounded' />
                  <div className='h-1.5 w-5/6 mt-2 bg-khaki-2-3 rounded' />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
});

DashboardSkeleton.displayName = 'DashboardSkeleton';
