import * as React from 'react';

import clsx from 'clsx';
import NextImage from 'next/image';

interface SplitLayout {
  children?: React.ReactNode;
  video?: boolean;
  image?: boolean;
}

export const SplitLayout = React.memo<SplitLayout>(({ children, video = false, image = false }) => {
  return (
    <div className='lg:grid lg:grid-cols-2 lg:h-screen'>
      <div className='lg:hidden flex justify-center p-4 border-b border-b-khaki-3'>
        <NextImage alt='28' height={38} src='/images/logo/gradient.svg' width={39} />
      </div>
      <div
        className={clsx('hidden lg:flex overflow-hidden relative', {
          'bg-gradient-yellow-to-purple': !image && !video,
        })}
      >
        {video && (
          <video autoPlay className='absolute inset-0 h-full w-full object-cover' loop muted playsInline preload='auto'>
            <source
              src='https://stream.mux.com/apRVVETLXL01wXO3itROCThBNtYUEceATgLM3kEvyr5I/high.mp4'
              type='video/mp4'
            />
            <p>Your browser does not support the video tag.</p>
          </video>
        )}
        {image && (
          <NextImage
            alt=''
            className='object-cover object-top'
            height={1828}
            src='/images/signup/welcome.jpg'
            width={1024}
          />
        )}
        <div className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2'>
          <NextImage alt='28' height={136} src='/images/logo/white.svg' width={141} />
        </div>
      </div>
      <div className='p-6 lg:p-8 lg:flex lg:flex-col lg:items-center '>
        <div className='w-full m-auto flex justify-center flex-col items-center h-full'>{children}</div>
      </div>
    </div>
  );
});

SplitLayout.displayName = 'SplitLayout';
