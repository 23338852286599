import _mixpanel from 'mixpanel-browser'
// This client was automatically generated by Mixpanel Typewriter. ** Do Not Edit **
// To update this file, run:
//   npx typewriter

/**
 * Triggered when a user is added to the database through lead forms, sign up, etc
 */
export interface AccountCreated {
    /**
     * input value for email
     */
    email: string;
    /**
     * input value for first name
     */
    first_name?: string;
    /**
     * input value for last name
     */
    last_name?: string;
    /**
     * selected sign up method (Google, Facebook, email, etc.)
     */
    method: string;
    [property: string]: any;
}

/**
 * Triggered when user updates their account details
 */
export interface ButtonClicked {
    email?: string;
    /**
     * if possible, the location of the button (e.g. module name)
     */
    location?: string;
    /**
     * Properties to provide additional context regarding the button clicked
     */
    properties?: { [key: string]: any };
    /**
     * the text/name of the button
     */
    text: string;
    /**
     * the type of button
     */
    type: Type;
    [property: string]: any;
}

/**
 * the type of button
 */
export enum Type {
    Banner = "banner",
    Button = "button",
    Interstitial = "interstitial",
}

/**
 * Triggered when a user clicks a CTA button on a page
 */
export interface CTAClicked {
    city?:          string;
    country?:       string;
    date_of_birth?: Date;
    destination?:   string;
    email?:         string;
    fbc?:           string;
    fbp?:           string;
    first_name?:    string;
    gender?:        string;
    last_name?:     string;
    location?:      string;
    phone?:         string;
    state?:         string;
    text?:          string;
    ttclid?:        string;
    type?:          string;
    url?:           string;
    zip?:           string;
    [property: string]: any;
}

/**
 * Triggered when a user updates their cycle
 */
export interface CycleUpdated {
    cycle_duration?: number;
    cycle_lunar?:    boolean;
    /**
     * The updated cycle phase of the user
     */
    cycle_phase:  string;
    cycle_start?: Date;
    days_left?:   number;
    email?:       string;
    /**
     * Selected date for first day of last period
     */
    last_period_date:      Date;
    phase_day_number?:     number;
    phase_day_number_raw?: number;
    [property: string]: any;
}

/**
 * Trigger When user answer a question
 */
export interface OnboardingQuestionAnswered {
    /**
     * user's response for the question
     */
    answer: any[];
    email?: string;
    /**
     * what are the choices as answer
     */
    options: any[];
    /**
     * question show to the user
     */
    question: string;
    /**
     * sequence of question user is answering
     */
    question_seq: number;
    [property: string]: any;
}

/**
 * Triggered when a user logs into their account
 */
export interface SignedIn {
    /**
     * the phase the user is currently in
     */
    cycle_phase: string;
    /**
     * input value for email
     */
    email: string;
    [property: string]: any;
}

/**
 * Triggered when a user logs into their account
 */
export interface SignedOut {
    /**
     * input value for email
     */
    email: string;
    [property: string]: any;
}

export interface TrialStarted {
    email?:               string;
    isSubscriber?:        boolean;
    plan_billing_amount?: number | null;
    plan_code?:           string;
    plan_name?:           string;
    plan_start_date?:     Date;
    [property: string]: any;
}

/**
 * Triggered when a video reaches 90% of the duration
 */
export interface VideoCompleted {
    /**
     * type of video
     */
    category: string;
    /**
     * duration of the video in seconds
     */
    duration: number;
    email?:   string;
    /**
     * the intensity of the workout (e.g., moderate)
     */
    intensity: string;
    /**
     * name of the video
     */
    title: string;
    /**
     * the cycle phase which this workout is associated with (e.g., menstrual)
     */
    workout_cycle_phase: string;
    /**
     * area of the body the workout is focused on (e.g., full body, legs)
     */
    workout_type: string;
    [property: string]: any;
}

/**
 * Triggered when a video starts playing
 */
export interface VideoStarted {
    /**
     * type of video
     */
    category: string;
    /**
     * duration of the video in seconds
     */
    duration: number;
    email?:   string;
    /**
     * the instructor of the video
     */
    instructor: string;
    /**
     * the intensity of the workout (e.g., moderate)
     */
    intensity: string;
    /**
     * if possible, the location of the element
     */
    location?: string;
    /**
     * name of the video
     */
    title: string;
    /**
     * the cycle phase which this workout is associated with (e.g., menstrual)
     */
    workout_cycle_phase: string;
    /**
     * area of the body the workout is focused on (e.g., full body, legs)
     */
    workout_type: string;
    [property: string]: any;
}

/**
 * Triggered when a user views a workout
 */
export interface WorkoutViewed {
    email?: string;
    /**
     * the instructor of the workout
     */
    instructor: string;
    /**
     * the intensity of the workout (e.g., moderate)
     */
    intensity: string;
    /**
     * if possible, the location of the video
     */
    location?: string;
    /**
     * the name of the video
     */
    title: string;
    /**
     * full URL of the page
     */
    url: string;
    /**
     * the cycle phase which this workout is associated with (e.g., menstrual)
     */
    workout_cycle_phase: string;
    /**
     * area of the body the workout is focused on (e.g., full body, legs)
     */
    workout_type: string;
    [property: string]: any;
}

export interface PageViewed {
    path: any;
}

_mixpanel.init(process.env.NEXT_PUBLIC_MIXPANEL_TOKEN || '');
let analytics = () => {
    return _mixpanel;
};


type StringOrNull = string | null

interface IdentifyProps {
    userId: string;
    traits: {
        email: string;
        first_name?: StringOrNull;
        last_name?: StringOrNull;
        cycle_phase?: StringOrNull
        name?: StringOrNull
        phone?: StringOrNull;
        cycle_start?: Date | null;
        date_of_birth?: Date | null;
        phaseday_number_raw?: null | number;
        days_left?: null | number;
        cycle_duration?: null | number
        cycle_lunar?: boolean | null
        phaseday_number?: null | number;
    }
}
/**
 * Fires an 'Identify' call.
 *
 * @param AccountCreated props - The analytics properties that will be sent to Mixpanel.
 * 	call is fired.
 */
function identify(params: IdentifyProps): void {

    const a = analytics();
    if (a) {
        a.identify(params.userId)
        a.people.set({
        $email: params.traits?.email,
        $first_name: params.traits?.first_name,
        $last_name: params.traits?.last_name,
        cycle_start: params.traits.cycle_start?.toISOString(),
        date_of_birth: params.traits.date_of_birth?.toISOString(),
        ...params.traits
      });
    }
}
/**
 * Fires a 'AccountCreated' track call.
 *
 * @param AccountCreated props - The analytics properties that will be sent to Mixpanel.
 * 	call is fired.
 */
function accountCreated(props: AccountCreated): void {


    const a = analytics();
    if (a) {
        a.track('Account Created', props || {}, );
    }
}
/**
 * Fires a 'ButtonClicked' track call.
 *
 * @param ButtonClicked props - The analytics properties that will be sent to Mixpanel.
 * 	call is fired.
 */
function buttonClicked(props: ButtonClicked): void {


    const a = analytics();
    if (a) {
        a.track('Button Clicked', props || {}, );
    }
}
/**
 * Fires a 'CTAClicked' track call.
 *
 * @param CTAClicked props - The analytics properties that will be sent to Mixpanel.
 * 	call is fired.
 */
function cTAClicked(props: CTAClicked): void {


    const a = analytics();
    if (a) {
        a.track('CTA Clicked', props || {}, );
    }
}
/**
 * Fires a 'CycleUpdated' track call.
 *
 * @param CycleUpdated props - The analytics properties that will be sent to Mixpanel.
 * 	call is fired.
 */
function cycleUpdated(props: CycleUpdated): void {


    const a = analytics();
    if (a) {
        a.track('Cycle Updated', props || {}, );
    }
}
/**
 * Fires a 'OnboardingQuestionAnswered' track call.
 *
 * @param OnboardingQuestionAnswered props - The analytics properties that will be sent to Mixpanel.
 * 	call is fired.
 */
function onboardingQuestionAnswered(props: OnboardingQuestionAnswered): void {


    const a = analytics();
    if (a) {
        a.track('Onboarding Question Answered', props || {}, );
    }
}
/**
 * Fires a 'SignedIn' track call.
 *
 * @param SignedIn props - The analytics properties that will be sent to Mixpanel.
 * 	call is fired.
 */
function signedIn(props: SignedIn): void {


    const a = analytics();
    if (a) {
        a.track('Signed In', props || {}, );
    }
}
/**
 * Fires a 'SignedOut' track call.
 *
 * @param SignedOut props - The analytics properties that will be sent to Mixpanel.
 * 	call is fired.
 */
function signedOut(props: SignedOut): void {


    const a = analytics();
    if (a) {
        a.track('Signed Out', props || {}, );
    }
}
/**
 * Fires a 'TrialStarted' track call.
 *
 * @param TrialStarted props - The analytics properties that will be sent to Mixpanel.
 * 	call is fired.
 */
function trialStarted(props: TrialStarted): void {


    const a = analytics();
    if (a) {
        a.track('Trial Started', props || {}, );
    }
}
/**
 * Fires a 'VideoCompleted' track call.
 *
 * @param VideoCompleted props - The analytics properties that will be sent to Mixpanel.
 * 	call is fired.
 */
function videoCompleted(props: VideoCompleted): void {


    const a = analytics();
    if (a) {
        a.track('Video Completed', props || {}, );
    }
}
/**
 * Fires a 'VideoStarted' track call.
 *
 * @param VideoStarted props - The analytics properties that will be sent to Mixpanel.
 * 	call is fired.
 */
function videoStarted(props: VideoStarted): void {


    const a = analytics();
    if (a) {
        a.track('Video Started', props || {}, );
    }
}
/**
 * Fires a 'WorkoutViewed' track call.
 *
 * @param WorkoutViewed props - The analytics properties that will be sent to Mixpanel.
 * 	call is fired.
 */
function workoutViewed(props: WorkoutViewed): void {


    const a = analytics();
    if (a) {
        a.track('Workout Viewed', props || {}, );
    }
}

/**
 * Fires a 'PageViewed' track call.
 *
 */
function pageViewed(props: PageViewed): void {
    const a = analytics();
    if (a) {
        a.track('Page Viewed: ', props || {}, );
    }
}

export const mixpanelTrack = {
    identify,
    accountCreated,
    buttonClicked,
    cTAClicked,
    cycleUpdated,
    onboardingQuestionAnswered,
    signedIn,
    signedOut,
    trialStarted,
    videoCompleted,
    videoStarted,
    workoutViewed,
    pageViewed
}