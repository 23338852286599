import * as React from 'react';

import { User } from '@prisma/client';

import { StructuredModal } from '@/components/common';
import { Button, Spinner, useUIDispatch } from '@/components/ui';
import { useUserHasPromoSubscription } from '@/request/client';
import { trpc } from '@/utils/trpc';

type UserRevokePromotionFormProps = { user: User };

export const UserRevokePromotionForm = React.memo<UserRevokePromotionFormProps>(({ user }) => {
  const uiDispatch = useUIDispatch();
  const { mutateAsync } = trpc.users.promoSubscriptionRevoke.useMutation();
  const hasPromoSubscription = useUserHasPromoSubscription(user.id);

  const onClick = React.useCallback(async () => {
    await mutateAsync({ id: user.id });
    uiDispatch({ type: 'MODAL_CLOSE' });
  }, [mutateAsync, uiDispatch, user.id]);

  return (
    <StructuredModal
      className='min-w-[1024px]'
      footer={
        <>
          {typeof hasPromoSubscription === 'boolean' && hasPromoSubscription && (
            <Button
              disabled={typeof hasPromoSubscription !== 'boolean'}
              form='user-promotion-form'
              onClick={onClick}
              type='submit'
              variant='primary'
            >
              Confirm
            </Button>
          )}
          <Button
            disabled={typeof hasPromoSubscription !== 'boolean'}
            onClick={() => uiDispatch({ type: 'MODAL_CLOSE' })}
            type='button'
            variant='danger'
          >
            Cancel
          </Button>
        </>
      }
      title='Are you sure?'
    >
      {typeof hasPromoSubscription === 'undefined' && <Spinner />}
      {typeof hasPromoSubscription === 'boolean' && hasPromoSubscription && (
        <p>
          This will immediately revoke the user's promotional subscription. Keep in mind that promotional subscriptions
          are only granted by Administrators.
        </p>
      )}
      {typeof hasPromoSubscription === 'boolean' && !hasPromoSubscription && (
        <>
          <p className='mb-4'>
            It looks like this user doesn't have a promotional subscription, and might have a paid subscription that{' '}
            <u>can not</u> be revoked.
          </p>
          <p>If you think this is a mistake, please contact developer support.</p>
        </>
      )}
    </StructuredModal>
  );
});

UserRevokePromotionForm.displayName = 'UserRevokePromotionForm';
