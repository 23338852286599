import * as React from 'react';

import type { Image } from '@prisma/client';
import dynamic from 'next/dynamic';
import NextImage from 'next/image';
import type { FieldErrors } from 'react-hook-form';

import { FormErrorMessage } from '@/components/common';
import type { ImagesBrowserProps } from '@/components/images';
import { Button, useUIDispatch } from '@/components/ui';
import { imgixLoader } from '@/utils/images';

interface ImageSelectFieldProps {
  errors?: FieldErrors;
  height?: number;
  images: Image[];
  label: string;
  name: string;
  onImagesSelect: (image: Image[]) => void;
  width?: number;
}

const ImagesBrowser = dynamic<ImagesBrowserProps>(
  () => import('@/components/images').then((mod) => mod.ImagesBrowser),
  { ssr: false },
);

export const ImagesSelectField = React.memo<ImageSelectFieldProps>(
  ({ errors, name, label, images, onImagesSelect, width = 200, height = 200 }) => {
    const [selectedImages, setSelectedImages] = React.useState(images || []);
    const uiDispatch = useUIDispatch();

    const onSelectImage = React.useCallback(
      (images: Image[]) => {
        setSelectedImages(images);
        onImagesSelect(images);
      },
      [onImagesSelect],
    );

    return (
      <div>
        <label className='font-lato font-bold block mb-2 text-sm'>{label}</label>

        <div className='flex gap-4 items-center'>
          {selectedImages.map((image) => (
            <div key={image.id}>
              <NextImage
                alt={image.title}
                className='rounded-lg object-cover'
                height={height}
                loader={imgixLoader}
                src={`/${image.fileName}`}
                width={width}
              />
              <div className='text-sm mb-4' key={image.id}>
                <div className='font-bold mb-1'>{image.title}</div>
                {image.width} x {image.height}px
                <br />
                {image.type}
              </div>
            </div>
          ))}
        </div>
        <Button
          onClick={() =>
            uiDispatch({
              type: 'MODAL_OPEN',
              content: <ImagesBrowser onImagesSelect={onSelectImage} selectedImages={selectedImages} />,
            })
          }
          size='sm'
          type='button'
          variant='secondary'
        >
          {selectedImages.length > 0 ? 'Swap' : 'Choose'} Images
        </Button>

        <FormErrorMessage errors={errors} name={name} />
      </div>
    );
  },
);

ImagesSelectField.displayName = 'ImagesSelectField';
