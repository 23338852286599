import * as React from 'react';

import clsx from 'clsx';
import NextImage from 'next/image';

import { Divider } from '@/components/ui';

interface TestimonialProps {
  variant: 'featured' | 'card';
  image?: string;
  quote: string;
  shortQuote?: string;
  author: string;
  authorBio?: string;
  className?: string;
}

export const Testimonial = React.memo<TestimonialProps>(
  ({ image, quote, author, authorBio, variant, className, shortQuote }) => {
    const isFeatured = variant === 'featured';
    const isCard = variant === 'card';

    return (
      <figure
        className={clsx(className, {
          'max-w-[375px] min-w-[375px] md:max-w-[320px] md:min-w-[320px] shrink-0 py-6 px-8 bg-khaki-1 rounded-lg border-l-4 border-l-khaki-4 border-solid mt-6':
            isCard,
        })}
      >
        <div
          className={clsx('flex flex-col h-full', {
            'items-center gap-y-6 text-center': isFeatured,
            'justify-between': isCard,
          })}
        >
          {isFeatured && image && (
            <div className='leading-none bg-[url(/images/layout/wave.svg)] w-full bg-repeat-x bg-center bg-[length:100%_auto] md:bg-[length:1680px_auto] '>
              <div className='mx-auto w-32 md:w-44'>
                <NextImage alt={author} className='rounded-full object-cover' height={180} src={image} width={180} />
              </div>
            </div>
          )}
          <blockquote
            className={clsx('m-0 p-0', {
              'px-6 max-w-2xl mx-auto font-bodoni text-xl md:text-3xl': isFeatured,
              'text-sm': isCard,
            })}
          >
            {isCard && <p className='text-slate font-bold mb-2'>{shortQuote}</p>}
            <p>{quote}</p>
          </blockquote>
          {isFeatured && <Divider className='text-khaki-3' variant='stubby' />}
          <figcaption
            className={clsx('font-josefin leading-snug', {
              'flex items-center gap-x-4 text-xs mt-4': isCard,
              flex: isFeatured,
            })}
          >
            {isCard && image && (
              <NextImage alt={author} className='rounded-full object-cover' height={40} src={image} width={40} />
            )}
            <div className=''>
              <span className={clsx(isCard && 'text-slate font-bold flex items-center')}>
                {isCard && (
                  <svg
                    className='mr-2 inline'
                    fill='none'
                    height='17'
                    viewBox='0 0 14 17'
                    width='14'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M8.34043 8.67031L10.8922 8.40194L8.41986 8.25744L14 4.09775L7.88369 7.28719L8.92624 4.98543L7.53617 7.11172L7 0L6.46383 7.09108L5.10355 4.96478L6.13617 7.30783L0 4.10808L5.65957 8.32969L3.1078 8.59806L5.58014 8.74256L0 12.9022L6.11631 9.71281L5.07376 12.0146L6.46383 9.88828L7 17L7.53617 9.90892L8.89645 12.0352L7.86383 9.69217L14 12.8919L8.34043 8.67031ZM7 9.57862C6.42411 9.57862 5.95745 9.0935 5.95745 8.49484C5.95745 7.89618 6.42411 7.41105 7 7.41105C7.57589 7.41105 8.04255 7.89618 8.04255 8.49484C8.04255 9.0935 7.57589 9.57862 7 9.57862Z'
                      fill='url(#paint0_linear_5731_988)'
                    />
                    <path
                      d='M8.18985 8.48743C8.18985 9.15359 7.64982 9.69361 6.98367 9.69361C6.31752 9.69361 5.77749 9.15359 5.77749 8.48743C5.77749 7.82128 6.31752 7.28126 6.98367 7.28126C7.64982 7.28126 8.18985 7.82128 8.18985 8.48743Z'
                      fill='url(#paint1_linear_5731_988)'
                    />
                    <defs>
                      <linearGradient
                        gradientUnits='userSpaceOnUse'
                        id='paint0_linear_5731_988'
                        x1='5.21541e-08'
                        x2='14'
                        y1='8.5'
                        y2='8.5'
                      >
                        <stop stopColor='#946F90' />
                        <stop offset='1' stopColor='#29455C' />
                      </linearGradient>
                      <linearGradient
                        gradientUnits='userSpaceOnUse'
                        id='paint1_linear_5731_988'
                        x1='5.21541e-08'
                        x2='14'
                        y1='8.5'
                        y2='8.5'
                      >
                        <stop stopColor='#946F90' />
                        <stop offset='1' stopColor='#29455C' />
                      </linearGradient>
                    </defs>
                  </svg>
                )}
                {author}
                <br />
                {authorBio && authorBio}
              </span>
            </div>
          </figcaption>
        </div>
      </figure>
    );
  },
);

Testimonial.displayName = 'Testimonial';
