import * as React from 'react';

import { FieldErrors } from 'react-hook-form';

import { Input } from '../Input';

function formatPhoneNumberValue(value: string) {
  // if input value is falsy eg if the user deletes the input, then just return
  if (value.length < 1) return null;

  // clean the input for any non-digit values.
  const phoneNumber = value.replace(/[^\d]/g, '');

  // phoneNumberLength is used to know when to apply our formatting for the phone number
  const phoneNumberLength = phoneNumber.length;

  // we need to return the value with no formatting if its less than four digits
  // this is to avoid weird behavior that occurs if you  format the area code too early
  if (phoneNumberLength < 4) return phoneNumber;

  // if phoneNumberLength is greater than 4 and less the 7 we start to return
  // the formatted number
  if (phoneNumberLength < 7) {
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
  }

  // finally, if the phoneNumberLength is greater then seven, we add the last
  // bit of formatting and return it.
  return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
}

export interface PhoneInputProps extends Omit<React.ComponentPropsWithRef<'input'>, 'onChange' | 'defaultValue'> {
  defaultValue?: string | null;
  description?: string;
  errors?: FieldErrors;
  label?: string;
  onChange: (value: string | null) => void;
}

export const PhoneInput = React.forwardRef<HTMLInputElement, PhoneInputProps>(
  ({ className, label, description, errors, type = 'text', onChange, defaultValue, ...props }, ref) => {
    const [inputValue, setInputValue] = React.useState(
      defaultValue ? formatPhoneNumberValue(String(defaultValue)) : '',
    );

    React.useEffect(() => {
      onChange(
        inputValue
          ? inputValue
              .split('')
              .filter((char) => /\d/g.test(char))
              .join('')
          : null,
      );
    }, [inputValue, onChange]);

    const handleInput = React.useCallback<React.ChangeEventHandler<HTMLInputElement>>((e) => {
      const formattedPhoneNumber = formatPhoneNumberValue(e.target.value);
      setInputValue(formattedPhoneNumber);
    }, []);

    return (
      <Input
        className={className}
        description={description}
        errors={errors}
        label={label}
        onChange={handleInput}
        ref={ref}
        type={type}
        value={inputValue as string}
        {...props}
      />
    );
  },
);

PhoneInput.displayName = 'PhoneInput';
