import * as React from 'react';

import { throttle } from 'throttle-debounce';

import { DEFAULT_CYCLE_DURATION } from '@/lib/constants';

import s from './CycleDurationSlider.module.css';

const trackHeight = 8;
const thumbSize = 40;
const thumbHalfSize = thumbSize / 2;

type CycleDurationSliderProps = {
  title: string;
  subtitle: string;
  disabled?: boolean;
  defaultValue?: number;
  min: number;
  max: number;
  startLabel?: string;
  endLabel?: string;
  onChange: (val: number) => void;
};

export const CycleDurationSlider = React.memo<CycleDurationSliderProps>((props) => {
  const [value, setValue] = React.useState<number | undefined>();

  const handleChange = (v: number) => {
    props.onChange(v);
    setValue(v);
  };

  if (value === undefined) {
    handleChange(props.defaultValue ?? DEFAULT_CYCLE_DURATION);
  }

  const sliderRef = React.useRef<HTMLDivElement>(null);
  const inputRef = React.useRef<HTMLInputElement>(null);

  const getPercent = React.useCallback((value: number) => (value - props.min) / (props.max - props.min), []);

  const getMeasurements = React.useCallback(
    (value?: number) => {
      let rangeWidthPercent = 0;
      let thumbPositionLeft = 0;

      if (value && sliderRef.current && inputRef.current) {
        const inputWidth = inputRef.current.offsetWidth;
        const inputCenter = inputWidth / 2;

        rangeWidthPercent = getPercent(value);
        const rangeWidth = rangeWidthPercent * inputWidth;

        const thumbCenterOffsetPercent = (rangeWidth - inputCenter) / inputCenter;
        const thumbOffset = thumbCenterOffsetPercent * thumbHalfSize;
        thumbPositionLeft = rangeWidth - thumbHalfSize - thumbOffset;
      }
      return { rangeWidthPercent, thumbPositionLeft };
    },
    [getPercent],
  );

  const dotPositionLeft = React.useMemo(() => getPercent(value ?? 0) * 100, [getPercent, value]);

  React.useEffect(() => {
    const { rangeWidthPercent, thumbPositionLeft } = getMeasurements(value);
    sliderRef.current?.style.setProperty('--range-width', `${rangeWidthPercent * 100}%`);
    sliderRef.current?.style.setProperty('--thumb-left', `${thumbPositionLeft}px`);
  }, [value, getMeasurements]);

  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      const throttledFn = throttle(200, () => {
        const { thumbPositionLeft } = getMeasurements(value);
        sliderRef.current?.style.setProperty('--thumb-left', `${thumbPositionLeft}px`);
      });

      window.addEventListener('resize', throttledFn);

      return () => {
        window.removeEventListener('resize', throttledFn);
      };
    }
  }, [getMeasurements, value]);

  return (
    <div>
      <div className='text-sm font-bold mb-2'>{props.title}</div>
      <div className='text-sm leading-relaxed mb-8'>{props.subtitle}</div>
      <div
        className={s.slider}
        ref={sliderRef}
        style={
          {
            '--track-height': `${trackHeight}px`,
            '--dot-left': `${dotPositionLeft}%`,
            '--thumb-size': `${thumbSize}px`,
          } as React.CSSProperties
        }
      >
        <input
          aria-label='Cycle Duration'
          className={s.input}
          disabled={props.disabled}
          max={props.max}
          min={props.min}
          onChange={(e) => handleChange(Number(e.target.value))}
          ref={inputRef}
          step={1}
          type='range'
          value={value}
        />
        <div className={s.track} />
        <div className={s.range} />
        <div className={s.dot} />
        <div className={s.thumb}>{value}</div>
      </div>
      {(props.startLabel || props.endLabel) && (
        <div className='mt-8 flex justify-between'>
          <div className='text-sm font-bold'>{props.startLabel ?? ''}</div>
          <div className='text-sm font-bold'>{props.endLabel ?? ''}</div>
        </div>
      )}
    </div>
  );
});

CycleDurationSlider.displayName = 'CycleDurationSlider';
