import * as React from 'react';

import clsx from 'clsx';
import NextImage from 'next/image';

import { Link } from '@/components/common';
import { StatusLight } from '@/components/ui';
import { WorkoutSlim } from '@/types/28';
import { imgixLoader } from '@/utils/images';
import { capitalize } from '@/utils/string';

export interface WorkoutAdminTableRowProps {
  workout?: WorkoutSlim;
}

export const WorkoutAdminTableRow = React.memo<WorkoutAdminTableRowProps>(({ workout }) => {
  if (workout) {
    return (
      <tr className='group' key={workout.id}>
        <td>
          <Link className='flex items-center gap-6' href={`/admin/workouts/${workout.id}`}>
            {workout.image && (
              <NextImage
                alt={workout.title}
                className='rounded-lg aspect-video object-cover'
                height={99}
                loader={imgixLoader}
                src={`/${workout.image.fileName}`}
                width={176}
              />
            )}
            <div className='font-bold '>{workout.title}</div>
          </Link>
        </td>
        <td>{capitalize(workout.targetArea?.replace(/_/g, ' ').toLowerCase())}</td>
        <td>{workout.phasedays[0]?.phaseday.phase.name}</td>
        <td className='text-center  w-[160px]'>
          <div className='flex flex-wrap gap-1'>
            {workout.phasedays.map(({ phaseday }) => (
              <div
                className={clsx(
                  'flex flex-col text-xs font-bold items-center justify-center w-8 h-8 rounded-full text-white',
                  { 'bg-blue': phaseday.phase.name === 'Menstrual' },
                  { 'bg-green': phaseday.phase.name === 'Follicular' },
                  { 'bg-orange': phaseday.phase.name === 'Ovulatory' },
                  { 'bg-purple-1': phaseday.phase.name === 'Luteal' },
                )}
                key={phaseday.id}
              >
                {phaseday.number}
              </div>
            ))}
          </div>
        </td>

        <td>{capitalize(workout.intensity?.toLowerCase())}</td>
        <td className='text-center'>{workout.views}</td>
        <td>
          <div className='flex gap-3 items-center'>
            <StatusLight published={workout.published} /> {workout.published ? 'Published' : 'Draft'}
          </div>
        </td>
      </tr>
    );
  }

  return (
    <tr className='animate-pulse'>
      <td>
        <div className='flex items-center gap-6'>
          <div className='bg-khaki-2-3 h-24 w-44 rounded-lg' />
          <div className='h-2 bg-khaki-2-3 grow max-w-xs rounded' />
        </div>
      </td>
      <td>
        <div className='h-2 w-16 bg-khaki-2-3 rounded' />
      </td>
      <td>
        <div className='h-2 w-16 bg-khaki-2-3 rounded' />
      </td>
      <td>
        <div className='h-8 w-8 bg-khaki-2-3 rounded-full' />
      </td>
      <td>
        <div className='h-2 w-16 bg-khaki-2-3 rounded' />
      </td>
      <td>
        <div className='h-2 w-16 bg-khaki-2-3 rounded' />
      </td>
      <td>
        <div className='h-2 w-16 bg-khaki-2-3 rounded' />
      </td>
    </tr>
  );
});

WorkoutAdminTableRow.displayName = 'WorkoutAdminTableRow';
