// This client was automatically generated by Segment Typewriter. ** Do Not Edit **
// To update this file, run:
//   npx typewriter

/**
 * Triggered when a user is added to the database through lead forms, sign up, etc
 */
export interface AccountCreated {
    /**
     * input value for email
     */
    email: string;
    /**
     * input value for first name
     */
    first_name?: string;
    /**
     * input value for last name
     */
    last_name?: string;
    /**
     * selected sign up method (Google, Facebook, email, etc.)
     */
    method: string;
    [property: string]: any;
}

/**
 * Triggered when user updates their account details
 */
export interface ButtonClicked {
    email?: string;
    /**
     * if possible, the location of the button (e.g. module name)
     */
    location?: string;
    /**
     * Properties to provide additional context regarding the button clicked
     */
    properties?: { [key: string]: any };
    /**
     * the text/name of the button
     */
    text: string;
    /**
     * the type of button
     */
    type: Type;
    [property: string]: any;
}

/**
 * the type of button
 */
export enum Type {
    Banner = "banner",
    Button = "button",
    Interstitial = "interstitial",
}

/**
 * Triggered when a user clicks a CTA button on a page
 */
export interface CTAClicked {
    city?:          string;
    country?:       string;
    date_of_birth?: Date;
    destination?:   string;
    email?:         string;
    fbc?:           string;
    fbp?:           string;
    first_name?:    string;
    gender?:        string;
    last_name?:     string;
    location?:      string;
    phone?:         string;
    state?:         string;
    text?:          string;
    ttclid?:        string;
    type?:          string;
    url?:           string;
    zip?:           string;
    [property: string]: any;
}

/**
 * Triggered when a user updates their cycle
 */
export interface CycleUpdated {
    cycle_duration?: number;
    cycle_lunar?:    boolean;
    /**
     * The updated cycle phase of the user
     */
    cycle_phase:  string;
    cycle_start?: Date;
    days_left?:   number;
    email?:       string;
    /**
     * Selected date for first day of last period
     */
    last_period_date:      Date;
    phase_day_number?:     number;
    phase_day_number_raw?: number;
    [property: string]: any;
}

/**
 * Trigger When user answer a question
 */
export interface OnboardingQuestionAnswered {
    /**
     * user's response for the question
     */
    answer: any[];
    email?: string;
    /**
     * what are the choices as answer
     */
    options: any[];
    /**
     * question show to the user
     */
    question: string;
    /**
     * sequence of question user is answering
     */
    question_seq: number;
    [property: string]: any;
}

/**
 * Triggered when a user logs into their account
 */
export interface SignedIn {
    /**
     * the phase the user is currently in
     */
    cycle_phase: string;
    /**
     * input value for email
     */
    email: string;
    [property: string]: any;
}

/**
 * Triggered when a user logs into their account
 */
export interface SignedOut {
    /**
     * input value for email
     */
    email: string;
    [property: string]: any;
}

export interface TrialStarted {
    email?:               string;
    isSubscriber?:        boolean;
    plan_billing_amount?: number | null;
    plan_code?:           string;
    plan_name?:           string;
    plan_start_date?:     Date;
    [property: string]: any;
}

/**
 * Triggered when a video reaches 90% of the duration
 */
export interface VideoCompleted {
    /**
     * type of video
     */
    category: string;
    /**
     * duration of the video in seconds
     */
    duration: number;
    email?:   string;
    /**
     * the intensity of the workout (e.g., moderate)
     */
    intensity: string;
    /**
     * name of the video
     */
    title: string;
    /**
     * the cycle phase which this workout is associated with (e.g., menstrual)
     */
    workout_cycle_phase: string;
    /**
     * area of the body the workout is focused on (e.g., full body, legs)
     */
    workout_type: string;
    [property: string]: any;
}

/**
 * Triggered when a video starts playing
 */
export interface VideoStarted {
    /**
     * type of video
     */
    category: string;
    /**
     * duration of the video in seconds
     */
    duration: number;
    email?:   string;
    /**
     * the instructor of the video
     */
    instructor: string;
    /**
     * the intensity of the workout (e.g., moderate)
     */
    intensity: string;
    /**
     * if possible, the location of the element
     */
    location?: string;
    /**
     * name of the video
     */
    title: string;
    /**
     * the cycle phase which this workout is associated with (e.g., menstrual)
     */
    workout_cycle_phase: string;
    /**
     * area of the body the workout is focused on (e.g., full body, legs)
     */
    workout_type: string;
    [property: string]: any;
}

/**
 * Triggered when a user views a workout
 */
export interface WorkoutViewed {
    email?: string;
    /**
     * the instructor of the workout
     */
    instructor: string;
    /**
     * the intensity of the workout (e.g., moderate)
     */
    intensity: string;
    /**
     * if possible, the location of the video
     */
    location?: string;
    /**
     * the name of the video
     */
    title: string;
    /**
     * full URL of the page
     */
    url: string;
    /**
     * the cycle phase which this workout is associated with (e.g., menstrual)
     */
    workout_cycle_phase: string;
    /**
     * area of the body the workout is focused on (e.g., full body, legs)
     */
    workout_type: string;
    [property: string]: any;
}


/**
 * The analytics.js snippet should be available via window.analytics.
 * You can install it by following instructions at: https://segment.com/docs/sources/website/analytics.js/quickstart/
 * Make sure to also include the TypeScript declarations with: `npm install --dev @types/segment-analytics`
 */
 declare global {
    interface Window {
        analytics: SegmentAnalytics.AnalyticsJS
    }
}

/** The callback exposed by analytics.js. */
export type Callback = () => void

/** A dictionary of options. For example, enable or disable specific destinations for the call. */
export interface Options {
    /**
     * Selectivly filter destinations. By default all destinations are enabled.
     * https://segment.com/docs/sources/website/analytics.js/#selecting-destinations
     */
    integrations?: {
        [key: string]: boolean | { [key: string]: any }
    }
    /**
     * A dictionary of extra context to attach to the call.
     * https://segment.com/docs/spec/common/#context
     */
    context?: Context
}

/**
 * Context is a dictionary of extra information that provides useful context about a datapoint.
 * @see {@link https://segment.com/docs/spec/common/#context}
 */
export interface Context extends Record<string, any> {
    active?: boolean
    app?: {
        name?: string
        version?: string
        build?: string
    }
    campaign?: {
        name?: string
        source?: string
        medium?: string
        term?: string
        content?: string
    }
    device?: {
        id?: string
        manufacturer?: string
        model?: string
        name?: string
        type?: string
        version?: string
    }
    ip?: string
    locale?: string
    location?: {
        city?: string
        country?: string
        latitude?: string
        longitude?: string
        region?: string
        speed?: string
    }
    network?: {
        bluetooth?: string
        carrier?: string
        cellular?: string
        wifi?: string
    }
    os?: {
        name?: string
        version?: string
    }
    page?: {
        hash?: string
        path?: string
        referrer?: string
        search?: string
        title?: string
        url?: string
    }
    referrer?: {
        type?: string
        name?: string
        url?: string
        link?: string
    }
    screen?: {
        density?: string
        height?: string
        width?: string
    }
    timezone?: string
    groupId?: string
    traits?: Record<string, any>
    userAgent?: string
}

export type ViolationHandler = (
    message: Record<string, any>,
    violations: any[]
) => void

/**
 * The default handler that is fired if none is supplied with setTypewriterOptions.
 * This handler will log a warning message to the console.
 */
export const defaultValidationErrorHandler: ViolationHandler = (message, violations) => {
    const msg = JSON.stringify(
        {
            type: 'Typewriter JSON Schema Validation Error',
            description:
                `You made an analytics call (${message.event}) using Typewriter that doesn't match the ` +
                'Tracking Plan spec.',
            errors: violations,
        },
        undefined,
        2,
    );

    console.warn(msg);
};


let analytics: () => SegmentAnalytics.AnalyticsJS | undefined = () => {
    return window.analytics;
};

/** Options to customize the runtime behavior of a Typewriter client. */
export interface TypewriterOptions {
    /**
     * Underlying analytics instance where analytics calls are forwarded on to.
     * Defaults to window.analytics.
     */
    analytics?: SegmentAnalytics.AnalyticsJS;
    /**
     * Handler fired when if an event does not match its spec. This handler
     * does not fire in production mode, because it requires inlining the full
     * JSON Schema spec for each event in your Tracking Plan.
     *
     * By default, it will throw errors if NODE_ENV = "test" so that tests will fail
     * if a message does not match the spec. Otherwise, errors will be logged to stderr.
     */
    onViolation?: ViolationHandler;
}

/**
 * Updates the run-time configuration of this Typewriter client.
 *
 * @param {TypewriterOptions} options - the options to upsert
 *
 * @typedef {Object} TypewriterOptions
 * @property {AnalyticsJS} [analytics] - Underlying analytics instance where analytics
 * 		calls are forwarded on to. Defaults to window.analytics.
 * @property {Function} [onViolation] - Handler fired when if an event does not match its spec. This handler does not fire in
 * 		production mode, because it requires inlining the full JSON Schema spec for each event in your Tracking Plan. By default,
 * 		it will throw errors if NODE_ENV="test" so that tests will fail if a message does not match the spec. Otherwise, errors
 * 		will be logged to stderr.
 */
export function setTypewriterOptions(options: TypewriterOptions) {
    analytics = options.analytics ? () => options.analytics || window.analytics : analytics;
}


/**
 * Helper to attach metadata on Typewriter to outbound requests.
 * This is used for attribution and debugging by the Segment team.
 */
function withTypewriterContext(message: Options = {}): Options {
    return {
        ...message,
        context: {
            ...(message.context || {}),
            typewriter: {
                language: 'typescript',
                version: '8.1.0',
            },
        },
    };
}

/**
 * Fires a 'AccountCreated' track call.
 *
 * @param AccountCreated props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function accountCreated(props: AccountCreated, options?: Options, callback?: Callback): void {


    const a = analytics();
    if (a) {
        a.track('Account Created', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'ButtonClicked' track call.
 *
 * @param ButtonClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function buttonClicked(props: ButtonClicked, options?: Options, callback?: Callback): void {


    const a = analytics();
    if (a) {
        a.track('Button Clicked', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'CTAClicked' track call.
 *
 * @param CTAClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function cTAClicked(props: CTAClicked, options?: Options, callback?: Callback): void {


    const a = analytics();
    if (a) {
        a.track('CTA Clicked', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'CycleUpdated' track call.
 *
 * @param CycleUpdated props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function cycleUpdated(props: CycleUpdated, options?: Options, callback?: Callback): void {


    const a = analytics();
    if (a) {
        a.track('Cycle Updated', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'OnboardingQuestionAnswered' track call.
 *
 * @param OnboardingQuestionAnswered props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function onboardingQuestionAnswered(props: OnboardingQuestionAnswered, options?: Options, callback?: Callback): void {


    const a = analytics();
    if (a) {
        a.track('Onboarding Question Answered', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'SignedIn' track call.
 *
 * @param SignedIn props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function signedIn(props: SignedIn, options?: Options, callback?: Callback): void {


    const a = analytics();
    if (a) {
        a.track('Signed In', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'SignedOut' track call.
 *
 * @param SignedOut props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function signedOut(props: SignedOut, options?: Options, callback?: Callback): void {


    const a = analytics();
    if (a) {
        a.track('Signed Out', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'TrialStarted' track call.
 *
 * @param TrialStarted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function trialStarted(props: TrialStarted, options?: Options, callback?: Callback): void {


    const a = analytics();
    if (a) {
        a.track('Trial Started', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'VideoCompleted' track call.
 *
 * @param VideoCompleted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function videoCompleted(props: VideoCompleted, options?: Options, callback?: Callback): void {


    const a = analytics();
    if (a) {
        a.track('Video Completed', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'VideoStarted' track call.
 *
 * @param VideoStarted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function videoStarted(props: VideoStarted, options?: Options, callback?: Callback): void {


    const a = analytics();
    if (a) {
        a.track('Video Started', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'WorkoutViewed' track call.
 *
 * @param WorkoutViewed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function workoutViewed(props: WorkoutViewed, options?: Options, callback?: Callback): void {


    const a = analytics();
    if (a) {
        a.track('Workout Viewed', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}

const clientAPI = {
    /**
     * Updates the run-time configuration of this Typewriter client.
     *
     * @param {TypewriterOptions} options - the options to upsert
     *
     * @typedef {Object} TypewriterOptions
     * @property {AnalyticsJS} [analytics] - Underlying analytics instance where analytics
     * 		calls are forwarded on to. Defaults to window.analytics.
     * @property {Function} [onViolation] - Handler fired when if an event does not match its spec. This handler does not fire in
     * 		production mode, because it requires inlining the full JSON Schema spec for each event in your Tracking Plan. By default,
     * 		it will throw errors if NODE_ENV="test" so that tests will fail if a message does not match the spec. Otherwise, errors
     * 		will be logged to stderr.
     */
    setTypewriterOptions,

    /**
     * Fires a 'AccountCreated' track call.
     *
     * @param AccountCreated props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    accountCreated,
    /**
     * Fires a 'ButtonClicked' track call.
     *
     * @param ButtonClicked props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    buttonClicked,
    /**
     * Fires a 'CTAClicked' track call.
     *
     * @param CTAClicked props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    cTAClicked,
    /**
     * Fires a 'CycleUpdated' track call.
     *
     * @param CycleUpdated props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    cycleUpdated,
    /**
     * Fires a 'OnboardingQuestionAnswered' track call.
     *
     * @param OnboardingQuestionAnswered props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    onboardingQuestionAnswered,
    /**
     * Fires a 'SignedIn' track call.
     *
     * @param SignedIn props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    signedIn,
    /**
     * Fires a 'SignedOut' track call.
     *
     * @param SignedOut props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    signedOut,
    /**
     * Fires a 'TrialStarted' track call.
     *
     * @param TrialStarted props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    trialStarted,
    /**
     * Fires a 'VideoCompleted' track call.
     *
     * @param VideoCompleted props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    videoCompleted,
    /**
     * Fires a 'VideoStarted' track call.
     *
     * @param VideoStarted props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    videoStarted,
    /**
     * Fires a 'WorkoutViewed' track call.
     *
     * @param WorkoutViewed props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    workoutViewed,
};

export default new Proxy<typeof clientAPI>(clientAPI, {
    get(target, method) {
        if (typeof method === 'string' && target.hasOwnProperty(method)) {
            return target[method as keyof typeof clientAPI];
        }

        return () => {
            console.warn(`⚠️  You made an analytics call (${String(method)}) that can't be found. Either:
         a) Re-generate your typewriter client: \`npx typewriter\`
         b) Add it to your Tracking Plan: https://app.segment.com/segment-oscb/protocols/tracking-plans/rs_1zTHJU9fd5mt7cndWnd4PgJbMCE`);
            const a = analytics();
            if (a) {
                a.track(
                    'Unknown Analytics Call Fired',
                    {
                        method,
                    },
                    withTypewriterContext(),
                );
            }
        };
    },
});

