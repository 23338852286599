import * as React from 'react';

import { zodResolver } from '@hookform/resolvers/zod';
import { Prisma } from '@prisma/client';
import { useRouter } from 'next/router';
import { SubmitHandler, useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import urlSlug from 'url-slug';
import { z } from 'zod';

import { Button, Input } from '@/components/ui';
import { useLeavePrevention } from '@/hooks/useLeavePrevention';
import { ERROR, WARN } from '@/utils/form';
import { trpc } from '@/utils/trpc';

type WorkoutCreateFormValues = Pick<Prisma.WorkoutCreateInput, 'title'>;

const schema: z.ZodType<WorkoutCreateFormValues> = z.lazy(() =>
  z.object({ title: z.string().min(1, { message: ERROR.REQUIRED }) }),
);

export const WorkoutCreateForm = React.memo(() => {
  const router = useRouter();
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting, isSubmitSuccessful, isDirty },
  } = useForm<WorkoutCreateFormValues>({ resolver: zodResolver(schema) });
  const { mutateAsync } = trpc.workouts.create.useMutation({
    onMutate: () => toast.loading('Saving...'),
    onSuccess: (workout) => {
      toast.remove();
      toast.success(`Workout ${workout.title} created`);
    },
    onError: (error) => {
      toast.remove();
      toast.error(error.message);
    },
  });

  useLeavePrevention(isDirty && !isSubmitting && !isSubmitSuccessful, WARN.UNSAVED_CHANGES);

  const onSubmit = React.useCallback<SubmitHandler<WorkoutCreateFormValues>>(
    ({ title }) => mutateAsync({ title, slug: urlSlug(title) }).then(({ id }) => router.push(`/admin/workouts/${id}`)),
    [mutateAsync, router],
  );

  return (
    <form className='bg-khaki-1 rounded-lg p-8 overflow-hidden' onSubmit={handleSubmit(onSubmit)}>
      <Input errors={errors} label='Title' {...register('title')} />
      <div className='flex gap-x-6 mt-2 -mx-8 -mb-8 p-8 py-5 rounded-bl-lg bg-khaki-2-3'>
        <Button disabled={isSubmitting} state={isSubmitting ? 'waiting' : undefined} type='submit' variant='primary'>
          Create Workout
        </Button>
      </div>
    </form>
  );
});

WorkoutCreateForm.displayName = 'WorkoutCreateForm';
