import * as React from 'react';

import clsx from 'clsx';

interface DividerProps extends React.ComponentPropsWithoutRef<'hr'> {
  variant?: 'simple' | 'stubby' | 'wavy';
  align?: 'left' | 'center' | 'right';
  direction?: 'horizontal' | 'vertical';
  reverse?: boolean;
}

/* 
 We favor filling our divider via borders instead of backgroundColor
 because a border will inherit it color from the text color of its parent.
 This is a very nice effect and a logical default for an <hr />.
*/

export const Divider = React.memo<DividerProps>(
  ({ variant = 'simple', align = 'center', direction = 'horizontal', reverse = false, className }) => {
    return (
      <hr
        className={clsx(
          'm-0 shadow-none',
          {
            'border-t': variant === 'simple',
            'border-l': variant === 'simple' && direction === 'vertical',
            'border-2 rounded-full': variant === 'stubby',
            'w-12': variant === 'stubby' && direction === 'horizontal',
            'w-full h-8 md:h-24 border-0 bg-[url(/images/layout/wave.svg)] bg-repeat-x bg-center bg-contain':
              variant === 'wavy',
            'h-0': direction === 'horizontal' && variant !== 'wavy',
            'ml-0': align === 'left',
            'mx-auto': align === 'center',
            'mr-0': align === 'right',
            '-scale-x-100': reverse,
          },
          className,
        )}
      />
    );
  },
);

Divider.displayName = 'Divider';
