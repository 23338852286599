import * as React from 'react';

import { useUser } from '@clerk/nextjs';
import { FieldErrors } from 'react-hook-form';

import { FormErrorMessage } from '@/components/common';
import { Button, useUIDispatch } from '@/components/ui';
import { VideoBrowser, VideoPlayer } from '@/components/videos';
import { findPlayback, formatDuration } from '@/utils/mux';
import { trpc } from '@/utils/trpc';

import { RouterOutput } from '../../../trpc/router';

export interface VideoSelectFieldProps {
  errors?: FieldErrors;
  label?: string;
  name: string;
  onVideoSelect: (Video: RouterOutput['workouts']['byId']['data']['video']) => void;
  removePreview?: () => void;
  video?: RouterOutput['workouts']['byId']['data']['video'] | null;
  onlyPreviews?: boolean;
}

export const VideoSelectField = React.memo<VideoSelectFieldProps>(
  ({ errors, name, label = 'Video', video, onVideoSelect, onlyPreviews, removePreview }) => {
    const { user } = useUser();
    const [selectedVideo, setSelectedVideo] = React.useState(video);
    const uiDispatch = useUIDispatch();

    const { data: playbacks } = trpc.videos.playbacks.useQuery(
      { videoId: video?.id ?? '' },
      {
        enabled: !!user,
        select: (data) => data?.playbacks ?? [],
      },
    );

    const playback = findPlayback(playbacks);

    const onSelectVideo = React.useCallback(
      (video: RouterOutput['workouts']['byId']['data']['video']) => {
        setSelectedVideo(video);
        onVideoSelect(video);
      },
      [onVideoSelect],
    );

    const handleOpenBrowser = React.useCallback<React.MouseEventHandler<HTMLButtonElement>>(
      (event) => {
        event.preventDefault();

        uiDispatch({
          type: 'MODAL_OPEN',
          content: (
            <VideoBrowser onVideoSelect={onSelectVideo} onlyPreviews={onlyPreviews} selectedVideo={selectedVideo} />
          ),
        });
      },
      [onSelectVideo, selectedVideo, uiDispatch],
    );

    return (
      <div>
        <label className='font-josefin font-bold block mb-2 tracking-tighter'>{label}</label>
        <div className='flex gap-4 items-center'>
          {selectedVideo && playback && <VideoPlayer height={202} playback={playback} width={360} />}
          <div>
            {selectedVideo && (
              <div className='mb-4 text-sm'>
                <div className='font-bold mb-1'>{selectedVideo.title}</div>
                {selectedVideo.maxStoredResolution} {selectedVideo.status} <br />
                {formatDuration(selectedVideo.duration)}
              </div>
            )}
            <div className='flex flex-col gap-1'>
              <Button onClick={handleOpenBrowser} size='sm' type='button' variant='secondary'>
                {!video ? 'Choose Video' : 'Swap Video'}
              </Button>
              {onlyPreviews && video && (
                <Button onClick={() => removePreview?.()} size='sm' type='button' variant='danger'>
                  Remove Preview
                </Button>
              )}
            </div>
          </div>
        </div>
        <FormErrorMessage errors={errors} name={name} />
      </div>
    );
  },
);

VideoSelectField.displayName = 'VideoSelectField';
