import * as React from 'react';

import { uploadFile } from '@/utils/file';
import { trpc } from '@/utils/trpc';

export const useUploadFile = () => {
  const [uploadProgress, setUploadProgress] = React.useState(0);

  const { mutateAsync, isLoading } = trpc.file.upload.useMutation();

  const upload = React.useCallback(
    async (file: File) => {
      setUploadProgress(0);

      if (!file) throw new Error('No file found');

      const { signedUrl, publicUrl, fileName } = await mutateAsync({
        fileType: file.type,
        originalFileName: file.name,
      });

      if (!signedUrl || !publicUrl) throw new Error('Something went wrong trying to generate signed URL');

      await uploadFile(signedUrl, file, setUploadProgress);

      return { url: publicUrl, fileName };
    },
    [mutateAsync],
  );

  return {
    isUploading: isLoading,
    upload,
    uploadProgress,
  };
};
