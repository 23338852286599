import * as React from 'react';

import clsx from 'clsx';
import NextImage from 'next/image';

export interface PhaseCardProps {
  name: string;
  heading: string;
  description: string;
  image: string;
}

export const PhaseCard = React.memo<PhaseCardProps>(({ name, heading, description, image }) => {
  return (
    <div
      className={clsx(
        'grid items-center relative max-w-2xl h-[397px] text-khaki-1  p-8 lg:px-[10%] rounded-2xl overflow-hidden bg-gradient-to-r to-transparent',
        { 'from-blue-1/60': name === 'Menstrual' },
        { 'from-green-1/60': name === 'Follicular' },
        { 'from-orange-1/60': name === 'Ovulatory' },
        { 'from-purple-4/60': name === 'Luteal' },
      )}
    >
      <NextImage
        alt={name}
        className='-z-10 object-cover'
        fill
        sizes='(max-width: 639px) 100vw, (max-width: 1376px) 50vw, 624px'
        src={image}
      />
      <div className='max-w-xs'>
        <div className='mb-1 text-xs font-josefin font-bold uppercase tracking-widest' data-testid='phase-name'>
          {name}
        </div>
        <h2 className='mb-2 font-bodoniBold tracking-tight text-5xl lg:text-6xl'>{heading}</h2>
        <p className='leading-normal'>{description}</p>
      </div>
    </div>
  );
});

PhaseCard.displayName = 'PhaseCard';
