import * as React from 'react';

import { off, on } from '@/utils/listeners';

type AirplayAvailabilityEventType = { availability: 'available' | 'not-available' };

export const useAppleAirplay = (videoRef: React.MutableRefObject<HTMLVideoElement | null>) => {
  const [airplayAvailable, setAirplayAvailable] = React.useState<boolean>(false);

  const showAirplayPicker = React.useCallback(() => {
    const video = videoRef.current;
    if (!video) return;

    if (airplayAvailable) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      video.webkitShowPlaybackTargetPicker();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [airplayAvailable]);

  const playbackTargetCallback = React.useCallback((event: AirplayAvailabilityEventType) => {
    switch (event.availability) {
      case 'available':
        setAirplayAvailable(true);
        break;

      default:
        setAirplayAvailable(false);
    }
  }, []);

  React.useEffect(() => {
    const video = videoRef.current;
    if (!video) return;

    if (window.WebKitPlaybackTargetAvailabilityEvent && video)
      on(video, 'webkitplaybacktargetavailabilitychanged', playbackTargetCallback);

    return () => {
      if (window.WebKitPlaybackTargetAvailabilityEvent && video)
        off(video, 'webkitplaybacktargetavailabilitychanged', playbackTargetCallback);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playbackTargetCallback]);

  return { airplayAvailable, showAirplayPicker };
};
