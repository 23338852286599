import * as React from 'react';

import { useUser } from '@clerk/nextjs';
import NextImage from 'next/image';

import { PlainText } from '@/components/common';
import { Carousel } from '@/components/ui';
import { WorkoutFivePointRating, WorkoutPlayButton, WorkoutTeaserCard } from '@/components/workouts';
import { useAnalyticsDispatch } from '@/context/analytics';
import { getUserMetadata } from '@/utils/auth/getUserMetadata';
import { imgixLoader } from '@/utils/images';
import { formatDuration } from '@/utils/mux';
import { capitalize } from '@/utils/string';
import { trpc } from '@/utils/trpc';

import { RouterOutput } from '../../../trpc/router';

interface WorkoutViewProps {
  workout: RouterOutput['workouts']['byId']['data'];
  moreWorkouts: RouterOutput['workouts']['recommended']['data'];
}

export const WorkoutView = React.memo<WorkoutViewProps>(({ workout, moreWorkouts }) => {
  const analyticsDispatch = useAnalyticsDispatch();
  const { user } = useUser();
  const { userId } = getUserMetadata(user);

  const { data: dbUser } = trpc.users.byId.useQuery(
    { id: userId || '' },
    {
      enabled: !!userId,
    },
  );

  return (
    <>
      <article className='bg-khaki-1 max-w-screen-3xl 3xl:max-w-[1600px] mb-6 md:mb-12 mx-auto lg:grid lg:grid-cols-[1fr_436px]'>
        {workout.image && (
          <NextImage
            alt={workout.title}
            className='aspect-video object-cover'
            height={727}
            loader={imgixLoader}
            priority={true}
            src={`/${workout.image.fileName}`}
            width={1293}
          />
        )}
        <div className='flex flex-col items-start pt-3 pb-6 px-6 gap-4 lg:p-12'>
          <header>
            <h2 className='font-josefin font-bold text-2xl '>{workout.title}</h2>
            <div className='flex items-center gap-2 mb-3 mt-1 font-josefin text-slate-1 text-xs tracking-tight'>
              <span className='font-bold uppercase text-xs text-slate'>
                {workout.phasedays[0]?.phaseday.phase.heading}
              </span>
              <span className='text-khaki-4'>&bull;</span>
              {workout.video?.duration && (
                <>
                  <span>{formatDuration(workout.video.duration)}</span>
                  <span className='text-khaki-4'>&bull;</span>
                </>
              )}
              <span>{capitalize(workout.targetArea?.replace(/_/g, ' ').toLowerCase())}</span>
              <span className='text-khaki-4'>&bull;</span>
              <span>{capitalize(workout.intensity?.toLowerCase())} Intensity</span>
            </div>
            <WorkoutFivePointRating workoutId={workout.id} />
          </header>
          {workout.content && (
            <div className='text-sm'>
              <PlainText content={workout.content} />
            </div>
          )}
          <WorkoutPlayButton workout={workout} />
          {workout.instructor && (
            <div className='flex items-center gap-4'>
              {workout.instructor.photo && (
                <NextImage
                  alt={workout.instructor.name}
                  className='rounded-full aspect-square object-cover'
                  height={56}
                  loader={imgixLoader}
                  src={`/${workout.instructor.photo.fileName}`}
                  width={56}
                />
              )}
              <div className='font-josefin text-sm'>
                <div className=''>Instructor</div>
                <h2 className='font-bold'>{workout.instructor.name.split(' ')[0]}</h2>
              </div>
            </div>
          )}
        </div>
      </article>
      <section key={`${workout.id}-similar-wokouts`}>
        <header className='px-6 md:px-12 max-w-7xl mx-auto'>
          <h2 className='mb-4 font-josefin text-xs font-bold tracking-widest uppercase text-transparent bg-clip-text bg-gradient-yellow-to-purple'>
            Similar {workout.phasedays[0]?.phaseday.phase.heading} workouts
          </h2>
        </header>
        {moreWorkouts && (
          <Carousel itemGap={16}>
            {moreWorkouts?.map((workout) => (
              <WorkoutTeaserCard
                key={workout.id}
                onClickHandler={() =>
                  analyticsDispatch({
                    type: 'WORKOUT_VIEWED',
                    payload: {
                      dbUser: dbUser?.data,
                      workout,
                      location: 'Workout: Similar Workouts',
                    },
                  })
                }
                url={`/workouts/${workout.slug}`}
                workout={workout}
              />
            ))}
          </Carousel>
        )}
      </section>
    </>
  );
});

WorkoutView.displayName = 'WorkoutView';
