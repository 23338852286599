import * as React from 'react';

import clsx from 'clsx';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDebounce } from 'react-use';

import { StructuredModal } from '@/components/common';
import { Button, Input, useUIDispatch } from '@/components/ui';
import { VideoAdminCard } from '@/components/videos';
import { trpc } from '@/utils/trpc';

import { RouterOutput } from '../../../trpc/router';

export interface VideoBrowserProps {
  selectedVideo: RouterOutput['workouts']['byId']['data']['video'] | null | undefined;
  onVideoSelect: (video: RouterOutput['workouts']['byId']['data']['video']) => void;
  onlyPreviews?: boolean;
}

export const VideoBrowser = React.memo<VideoBrowserProps>((props) => {
  const [selectedVideo, setSelectedVideo] = React.useState<
    RouterOutput['workouts']['byId']['data']['video'] | null | undefined
  >(props.selectedVideo);

  const [q, setQ] = React.useState('');
  const [qVal, setQVal] = React.useState('');
  useDebounce(() => setQ(qVal), 500, [qVal]);

  const { data, isInitialLoading, isLoading, fetchNextPage } = trpc.videos.all.useInfiniteQuery(
    { q, size: 12, onlyPreviews: props.onlyPreviews ?? false },
    {
      getNextPageParam: (lastPage, allPages) => (lastPage.length === 12 ? allPages.length * 12 : undefined),
    },
  );
  const videos = React.useMemo(() => data?.pages.flatMap((p) => p.map((v) => v)), [data]);

  const uiDispatch = useUIDispatch();

  const handleUseVideo = React.useCallback<React.MouseEventHandler<HTMLButtonElement>>(
    (event) => {
      event.preventDefault();

      if (selectedVideo) {
        props.onVideoSelect(selectedVideo);
      }

      uiDispatch({ type: 'MODAL_CLOSE' });
    },
    [props, selectedVideo, uiDispatch],
  );

  return (
    <StructuredModal
      footer={
        <Button disabled={!selectedVideo} onClick={handleUseVideo} variant='primary'>
          Use Video
        </Button>
      }
      header={
        <Input
          aria-label='Search Videos'
          onChange={(e) => setQVal(e.currentTarget.value)}
          placeholder='Search videos...'
          type='search'
          value={qVal}
        />
      }
      title='Videos'
    >
      <InfiniteScroll
        className='grid grid-cols-4 gap-4'
        dataLength={videos?.length ?? 0}
        hasMore={data?.pages[data?.pages.length - 1].length === 12}
        loader={null}
        next={() => !isLoading && fetchNextPage()}
        scrollableTarget='admin-modal-scroll'
      >
        {videos?.map((video) => (
          <button key={video.id} onClick={() => setSelectedVideo(video)}>
            <VideoAdminCard
              className={clsx('h-full', {
                'border-purple-3 shadow-sm': selectedVideo?.id === video.id,
              })}
              video={video}
            />
          </button>
        ))}
        {(isInitialLoading || isLoading) &&
          [...Array(8)].map((_, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <VideoAdminCard key={i} />
          ))}
      </InfiniteScroll>
    </StructuredModal>
  );
});

VideoBrowser.displayName = 'VideoBrowser';
