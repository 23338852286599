import * as React from 'react';

import { Transition } from '@headlessui/react';

export const Overlay = React.memo<React.ComponentPropsWithoutRef<'div'>>(() => {
  return (
    <Transition.Child
      as={React.Fragment}
      enter='ease-out duration-300'
      enterFrom='opacity-0'
      enterTo='opacity-100'
      leave='ease-in duration-200'
      leaveFrom='opacity-100'
      leaveTo='opacity-0'
    >
      <div className='fixed inset-0 bg-slate bg-opacity-80 transition-opacity' />
    </Transition.Child>
  );
});

Overlay.displayName = 'Overlay';
