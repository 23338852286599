import * as React from 'react';

import { zodResolver } from '@hookform/resolvers/zod';
import type { Image } from '@prisma/client';
import { SubmitHandler, useForm } from 'react-hook-form';
import { z } from 'zod';

import { Button, Input, ProgressBar } from '@/components/ui';
import type { ImageCreateInput } from '@/request/client';
import { useImageCreate } from '@/request/client';
import { ERROR } from '@/utils/form';
import { zodImageFileCreate } from '@/utils/zod';

const schema = z.object({
  title: z.string().min(1, { message: ERROR.REQUIRED }),
  files: zodImageFileCreate,
});

type ImageCreateFormLiteProps = {
  afterCreate: (image: Image) => void;
};

export const ImageCreateFormLite = React.memo<ImageCreateFormLiteProps>(({ afterCreate }) => {
  const {
    register,
    formState: { isSubmitting, errors },
    handleSubmit,
  } = useForm<ImageCreateInput>({ resolver: zodResolver(schema) });
  const { createImage, isUploading, progress } = useImageCreate({
    onSuccess: afterCreate,
  });

  const onSubmit = React.useCallback<SubmitHandler<ImageCreateInput>>(
    ({ title, files }) => createImage({ title, files }),
    [createImage],
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Input errors={errors} label='Title' {...register('title')} />

      <div>
        <Input
          accept='image/*'
          className='text-sm file:text-purple-3 file:mr-4 file:pt-[12px] file:pb-[11px] file:px-4 file:rounded-full file:border-2 file:border-purple-3 file:border-solid file:text-sm file:font-semibold file:bg-violet-50 file:text-violet-700 hover:file:bg-violet-100 file:font-josefin file:bg-transparent file:cursor-pointer'
          disabled={isUploading}
          errors={errors}
          type='file'
          {...register('files')}
        />
        {progress > 0 && (
          <div className='max-w-xs mt-4'>
            <ProgressBar max={100} value={progress} />
          </div>
        )}
      </div>
      <div>
        <Button disabled={isSubmitting} state={isSubmitting ? 'waiting' : undefined} type='submit' variant='primary'>
          Upload
        </Button>
      </div>
    </form>
  );
});

ImageCreateFormLite.displayName = 'ImageCreateFormLite';
