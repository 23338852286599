import * as React from 'react';

import clsx from 'clsx';

interface PhaseColorTextProps extends React.ComponentPropsWithoutRef<'span'> {
  phaseName: string;
  className?: string;
}

export const PhaseColorText = React.memo<PhaseColorTextProps>(({ phaseName, className, children }) => {
  return (
    <span
      className={clsx(
        { 'text-blue': phaseName === 'Menstrual' },
        { 'text-green': phaseName === 'Follicular' },
        { 'text-orange': phaseName === 'Ovulatory' },
        { 'text-purple-2': phaseName === 'Luteal' },
        className,
      )}
    >
      {children}
    </span>
  );
});

PhaseColorText.displayName = 'PhaseColorText';
