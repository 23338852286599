import * as React from 'react';

import clsx from 'clsx';

import { capitalize } from '@/utils/string';

export const SubscriptionStatusIndicator = React.memo<{
  status: string;
  showLabel?: boolean;
  className?: string | undefined;
}>(({ status, showLabel = true, className }) => (
  <div
    className={clsx(
      'inline-flex gap-2 items-center text-xs font-bold ',
      {
        'text-green': status === 'active',
        'text-yellow': status === 'trialing',
        'text-slate-1': ['expired', 'incomplete', 'incomplete_expired'].includes(status),
        'text-red': ['canceled', 'unpaid', 'past_due'].includes(status),
        'text-blue': !status,
      },
      className,
    )}
  >
    <div
      className={clsx('w-[6px] h-[6px] border-2 border-transparent rounded-full', {
        'bg-green': status === 'active',
        'bg-yellow': status === 'trialing',
        'text-slate-1': ['expired', 'incomplete', 'incomplete_expired'].includes(status),
        'text-red': ['canceled', 'unpaid', 'past_due'].includes(status),
        'bg-blue': !status,
      })}
    />
    {showLabel && capitalize(status || 'unavailable')}
  </div>
));

SubscriptionStatusIndicator.displayName = 'SubscriptionStatusIndicator';
