import * as React from 'react';
import { AppState, Auth0Provider, User, useAuth0 } from '@auth0/auth0-react';
import { ClerkProvider, useSession, useSignIn, useUser } from '@clerk/nextjs';
import { AppProps } from 'next/app';
import Router, { useRouter } from 'next/router';
import { DefaultSeo } from 'next-seo';
import { Toaster } from 'react-hot-toast';
import * as typewriter from '@/analytics/client';
import { mixpanelTrack } from '@/analytics/client';
import { AppLayout } from '@/components/layout';
import { BrandzookaPixel, TickTockPixel } from '@/components/pixels';
import { Protect } from '@/components/protect/Protect';
import { UIProvider } from '@/components/ui';
import { AnalyticsProvider } from '@/context/analytics';
import { SITE_DESCRIPTION, SITE_IMAGE, SITE_NAME, SITE_TWITTER_HANDLE, SITE_URL } from '@/lib/constants';
import { userWithIdentity } from '@/utils/auth0/client';
import { setToken, trpc } from '@/utils/trpc';
import '@fontsource/josefin-sans';
import '@fontsource/josefin-sans/700.css';
import '@fontsource/lato';
import '@fontsource/lato/300.css';
import '@/styles/globals.css';
import '@/styles/editor.css';
import 'react-tooltip/dist/react-tooltip.css';

const onRedirectCallback = (appState: AppState | undefined, auth0User: User | undefined) => {
  const identity = userWithIdentity(auth0User);

  if (identity?.email) {
    /**
     * Note: During the onboarding phase when we create a user
     * cycle creation and cycle duration and consequently the phase
     * are defaulted properties.
     */
    const params = {
      method: identity.sub.split('|')[0],
      email: identity.email,
      first_name: identity['https://28.co/first_name'] || identity['https://28.co/username'] || '',
      last_name: identity['https://28.co/last_name'] || ''
    };
    typewriter.accountCreated(params);
    mixpanelTrack.accountCreated(params);
  }

  Router.replace(appState && appState.returnTo ? appState.returnTo : window.location.pathname);
};

const AUTHENTICATED_PROTECTED_PATHS = ['/account', '/signup/profile', '/signup/welcome', 'signup/questionnaire'];
const ADMIN_PROTECTED_PATHS = ['/admin'];
const NO_LAYOUT_PATHS = ['/login'];
const PROTECTED_PATHS = [...AUTHENTICATED_PROTECTED_PATHS, ...ADMIN_PROTECTED_PATHS];

const TokenSetter = () => {
  const {
    getAccessTokenSilently,
    isAuthenticated
  } = useAuth0();
  const {
    session,
    isSignedIn
  } = useSession();
  const {
    signIn,
    setActive
  } = useSignIn();
  const {
    user
  } = useUser();
  React.useEffect(() => {
    if (isAuthenticated && !isSignedIn) {
      const migrateUser = async () => {
        const token = await getAccessTokenSilently();

        if (!token) {
          return null;
        }

        const signInToken = (await fetch('/api/hooks/migrate', {
          headers: {
            authorization: `Bearer ${token}`
          }
        }).then(async res => res.json()))?.signInToken;

        if (signInToken && signInToken?.token) {
          const result = await signIn?.create({
            strategy: 'ticket',
            ticket: signInToken.token
          });

          if (result && result.status === 'complete') {
            setActive?.({
              session: result.createdSessionId
            });
          }
        }
      };

      migrateUser();
    }
  }, [getAccessTokenSilently, isAuthenticated, isSignedIn]);
  React.useEffect(() => {
    // Verify the user has a 28 user ID attached, if not we are still waiting on the webhook to complete
    if (isSignedIn && user?.externalId) {
      const getAndSetToken = async () => {
        const token = await session.getToken({
          template: 'auth0'
        });

        if (token) {
          setToken(token);
        }
      };

      getAndSetToken();
    }
  }, [isSignedIn, user?.externalId]);
  return null;
};

function App({
  Component,
  pageProps,
  router: {
    route
  }
}: AppProps<{
  fallback?: any;
}>) {
  const requireAuth = PROTECTED_PATHS.some(path => route.startsWith(path));
  const requireAdmin = ADMIN_PROTECTED_PATHS.some(path => route.startsWith(path));
  const noLayout = NO_LAYOUT_PATHS.some(path => route.startsWith(path));
  const router = useRouter();
  React.useEffect(() => {
    const trackPage = (url: any) => {
      mixpanelTrack.pageViewed({
        path: url
      });
    };

    router.events.on('routeChangeStart', trackPage);
    return () => {
      router.events.off('routeChangeStart', trackPage);
    };
  }, []);
  return <>
      <DefaultSeo defaultTitle={SITE_NAME} description={SITE_DESCRIPTION} openGraph={{
      type: 'website',
      locale: 'en_US',
      site_name: SITE_NAME,
      images: [{
        url: SITE_IMAGE,
        width: 1200,
        height: 630,
        alt: SITE_NAME
      }]
    }} titleTemplate={`%s - ${SITE_NAME}`} twitter={{
      handle: `@${SITE_TWITTER_HANDLE}`,
      site: `@${SITE_TWITTER_HANDLE}`,
      cardType: 'summary_large_image'
    }} />
      <ClerkProvider {...pageProps}>
        <Auth0Provider audience={(process.env.NEXT_PUBLIC_AUTH0_AUDIENCE as string)} clientId={(process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID as string)} domain={(process.env.NEXT_PUBLIC_AUTH0_DOMAIN as string)} onRedirectCallback={onRedirectCallback} redirectUri={SITE_URL + '/callback'}>
          <TokenSetter />
          <AnalyticsProvider>
            <UIProvider>
              {noLayout ? <Component {...pageProps} /> : requireAuth ? <Protect isAdmin={requireAdmin}>
                  <AppLayout>
                    <Component {...pageProps} />
                  </AppLayout>
                </Protect> : <AppLayout>
                  <Component {...pageProps} />
                </AppLayout>}
            </UIProvider>
          </AnalyticsProvider>
        </Auth0Provider>
      </ClerkProvider>
      <Toaster position='bottom-center' toastOptions={{
      className: 'toast',
      success: {
        iconTheme: {
          primary: '#6D8859',
          secondary: '#fff'
        }
      },
      error: {
        iconTheme: {
          primary: '#DC5959',
          secondary: '#fff'
        }
      }
    }} />
      {
      /* Manual Pixels, Ugh :( */
    }
      <BrandzookaPixel />
      <TickTockPixel />
    </>;
}

export default trpc.withTRPC(App);