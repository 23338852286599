import * as React from 'react';

import { toast } from 'react-hot-toast';

import { Button, useUIDispatch } from '@/components/ui';
import { PhaseName } from '@/types/28';
import { trpc } from '@/utils/trpc';

type PhaseJumpConfirmProps = {
  heading: string;
  phaseName: PhaseName;
  userId?: string;
};

export const PhaseJumpConfirm: React.FC<PhaseJumpConfirmProps> = ({ heading, phaseName, userId }) => {
  const utils = trpc.useContext();
  const { mutate } = trpc.users.jump.useMutation({
    onMutate: () => toast.loading('Preparing your experience...'),
    onSuccess: () => {
      toast.remove();
      toast.success('All set!');
    },
    onError: (error) => {
      toast.remove();
      toast.error(error.message);
    },
    onSettled: async () => {
      await Promise.all([
        utils.users.byId.invalidate({ id: userId }),
        utils.users.workout.invalidate({ id: userId }, { refetchType: 'all' }),
        utils.workouts.recommended.invalidate({ currentPhase: phaseName }),
      ]);
    },
  });
  const uiDispatch = useUIDispatch();

  return (
    <div className='px-5 pt-6 max-w-lg sm:px-8 sm:pt-8'>
      <h3 className='mb-1 font-josefin font-bold text-lg sm:text-2xl sm:mb-2'>{heading}</h3>
      <p className='text-sm'>Would you like to jump ahead to the {phaseName} phase?</p>
      <div className='flex gap-2 bg-khaki-2 -mx-6 mt-5 px-5 py-4 sm:py-5 sm:px-7 sm:mt-8 sm:-mx-8'>
        <Button
          className='px-8'
          onClick={() => {
            if (!userId) return;
            mutate({ id: userId, phaseName });
            uiDispatch({ type: 'MODAL_CLOSE' });
          }}
          size='sm'
          type='button'
          variant='primary'
        >
          Yes
        </Button>
        <Button
          className='px-8'
          onClick={() => uiDispatch({ type: 'MODAL_CLOSE' })}
          size='sm'
          type='button'
          variant='secondary'
        >
          No
        </Button>
      </div>
    </div>
  );
};
