import { useCookie } from 'react-use';

const COOKIE_NAME = 'accept_cookies';

export const useAcceptCookies = () => {
  const [value, updateCookie] = useCookie(COOKIE_NAME);

  const acceptCookies = () => {
    updateCookie('accepted', { expires: 365 });
  };

  return {
    acceptedCookies: !!value,
    onAcceptCookies: acceptCookies,
  };
};
