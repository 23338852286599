import * as React from 'react';

import clsx from 'clsx';
import { FieldErrors } from 'react-hook-form';

import { FormErrorMessage } from '@/components/common';

export interface InputProps extends React.ComponentPropsWithRef<'input'> {
  description?: string | React.ReactNode;
  errors?: FieldErrors;
  label?: string;
  endadornment?: string;
}

export const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, label, description, errors, type = 'text', ...props }, ref) => {
    return (
      <div>
        {label && (
          <label className='font-lato font-bold block mb-2 text-sm' htmlFor={props.name || props.id}>
            {label}
          </label>
        )}
        <div className='relative'>
          <input
            autoCapitalize='off'
            autoComplete='off'
            autoCorrect='off'
            className={clsx(
              'w-full text-base align-middle placeholder:text-slate-1 outline-none focus:border-khaki-5 bg-white',
              {
                'py-[9px] pl-10 pr-4 border border-khaki-3 rounded-full text-sm bg-[url(/icons/search.svg)] bg-[length:1rem_1rem] bg-no-repeat bg-[center_left_1rem]':
                  type === 'search',
                ' text-sm file:text-purple-3 file:mr-4 file:pt-[9px] file:pb-[7px] file:px-4 file:rounded-full file:border-2 file:border-purple-3 file:border-solid file:text-sm file:font-josefin  file:font-bold file:bg-transparent file:cursor-pointer':
                  type === 'file',
                'p-4 border border-khaki-2-3 rounded-lg': !['search', 'file'].includes(type),
              },
              className,
            )}
            id={props.name || props.id}
            ref={ref}
            spellCheck='false'
            type={type}
            {...props}
          />
          {props.endadornment && (
            <span className='text-sm absolute top-1/2 right-9 translate-y-[-50%]'>{props.endadornment}</span>
          )}
        </div>
        {description && <div className='text-sm mt-2'>{description}</div>}
        <FormErrorMessage errors={errors} name={props.name as string} />
      </div>
    );
  },
);

Input.displayName = 'Input';
