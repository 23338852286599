import * as React from 'react';

// Thanks Copilot 😘
const fractionsMap = new Map<number, string>([
  [0.1, '1/10'],
  [0.125, '1/8'],
  [0.2, '1/5'],
  [0.25, '1/4'],
  [0.3, '3/10'],
  [0.333, '1/3'],
  [0.375, '3/8'],
  [0.4, '4/10'],
  [0.5, '1/2'],
  [0.6, '6/10'],
  [0.625, '5/8'],
  [0.667, '2/3'],
  [0.7, '7/10'],
  [0.75, '3/4'],
  [0.8, '4/5'],
  [0.875, '7/8'],
  [0.9, '9/10'],
]);

export type FractionsProps = {
  float?: number;
  displayNumber?: boolean;
  text?: string;
};

export const Fractions = React.memo<FractionsProps>(({ float, displayNumber = true, text }) => {
  if (float && fractionsMap.has(float))
    return (
      <>
        {text && <span>{text}</span>}
        <sup>{fractionsMap.get(float)?.split('/')[0]}</sup>/<sub>{fractionsMap.get(float)?.split('/')[1]}</sub>
      </>
    );

  if (displayNumber && float) return <>{float}</>;

  return null;
});

Fractions.displayName = 'Fractions';
