import * as React from 'react';

import { Image } from '@prisma/client';
import clsx from 'clsx';
import NextImage from 'next/image';

import { imgixLoader } from '@/utils/images';

export interface ImageAdminCardProps {
  image?: Image;
  className?: string;
}

export const ImageAdminCard = React.memo<ImageAdminCardProps>(({ image, className }) => {
  if (image) {
    return (
      <div
        className={clsx('overflow-hidden rounded-lg border border-khaki-3 text-left h-full', className)}
        key={image.id}
      >
        <NextImage
          alt={image.title}
          className='object-cover w-full aspect-[166/124]'
          height={124}
          loader={imgixLoader}
          src={`/${image.fileName}`}
          title={image.title}
          width={166}
        />
        <div className='flex flex-col gap-1 p-3 font-josefin text-xs'>
          <div>{image.title}</div>
          <div className='flex gap-2 text-slate-1'>
            <span>{image.type}</span>
            <span className='text-khaki-4'>&bull;</span>
            <span>
              {image.width}x{image.height}
            </span>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className='bg-khaki-1 rounded-lg overflow-hidden animate-pulse border border-khaki-3'>
      <div className='bg-khaki-2-3 w-[216px] h-[151px]' />
      <div className='p-3'>
        <div className='h-2 w-32 bg-khaki-2-3 rounded' />
        <div className='h-2 w-16 bg-khaki-2-3 rounded mt-2' />
      </div>
    </div>
  );
});

ImageAdminCard.displayName = 'ImageAdminCard';
