import * as React from 'react';

import clsx from 'clsx';
import NextImage from 'next/image';

import { HorizontalList } from '@/components/ui';
import { imgixLoader } from '@/utils/images';

import { RouterOutput } from '../../../trpc/router';

export interface InstructorAdminCardProps {
  instructor?: Omit<RouterOutput['instructors']['byId'], 'coverPhoto'>;
  className?: string;
}

export const InstructorAdminCard = React.memo<InstructorAdminCardProps>(({ instructor, className }) => {
  if (instructor) {
    return (
      <div className={clsx('flex flex-col items-center gap-2 p-4 rounded-lg text-center', className)}>
        {instructor.photo && (
          <NextImage
            alt={instructor.name}
            className='rounded-full aspect-square object-cover'
            height={104}
            loader={imgixLoader}
            src={`/${instructor.photo.fileName}`}
            width={104}
          />
        )}
        <div className='font-josefin text-sm'>{instructor.name}</div>
        {'_count' in instructor && 'workoutViews' in instructor && (
          <HorizontalList
            items={[
              `${instructor._count.workouts} workout${instructor._count.workouts < 2 ? '' : 's'}`,
              instructor._count.recipes > 0 &&
                `${instructor._count.recipes} recipe${instructor._count.recipes < 2 ? '' : 's'}`,
              `${instructor.workoutViews} view${instructor.workoutViews < 2 ? '' : 's'}`,
            ].filter((i): i is string => {
              return typeof i === 'string';
            })}
          />
        )}
      </div>
    );
  }

  return (
    <div
      className={clsx(
        'flex flex-col items-center gap-2 p-4 text-center rounded-lg bg-khaki-1 animate-pulse',
        className,
      )}
    >
      <div className='bg-khaki-2-3 h-[104px] w-[104px] rounded-full' />
      <div className='h-2 w-16 bg-khaki-2-3 rounded' />
    </div>
  );
});

InstructorAdminCard.displayName = 'InstructorAdminCard';
