import * as React from 'react';

import clsx from 'clsx';

import { Link } from '@/components/common';
import { VideoThumbnail } from '@/components/videos';
import { findPlayback, formatDuration } from '@/utils/mux';

import { RouterOutput } from '../../../trpc/router';

export interface VideoAdminTableRowProps {
  video?: RouterOutput['videos']['byId'];
}

export const VideoAdminTableRow = React.memo<VideoAdminTableRowProps>(({ video }) => {
  if (video) {
    return (
      <tr className='group' key={video.id}>
        <td>
          <Link className='flex items-center gap-x-6' href={`/admin/videos/${video.id}`}>
            <VideoThumbnail
              alt={video.title}
              className='rounded-lg'
              height={99}
              playback={findPlayback(video.playbacks)}
              width={176}
            />
            <div className='font-bold'>{video.title}</div>
          </Link>
        </td>
        <td>{video.duration && formatDuration(video.duration)}</td>
        <td>{video.maxStoredResolution}</td>
        <td>
          <div className='flex gap-3 items-center'>
            <div
              className={clsx('w-2 h-2 border-2 border-transparent rounded-full', {
                'bg-green': video.status === 'ready',
                'bg-yellow': video.status === 'preparing',
                'bg-red': video.status === 'errored',
              })}
            />
            <span
              className={clsx({
                'text-green': video.status === 'ready',
                'text-yellow': video.status === 'preparing',
                'text-red': video.status === 'errored',
              })}
            >
              {video.status}
            </span>
          </div>
        </td>
      </tr>
    );
  }

  return (
    <tr className='animate-pulse'>
      <td>
        <div className='flex items-center gap-6'>
          <div className='bg-khaki-2-3 h-24 w-44 rounded-lg' />
          <div className='h-2 bg-khaki-2-3 grow max-w-xs rounded' />
        </div>
      </td>
      <td>
        <div className='h-2 w-16 bg-khaki-2-3 rounded' />
      </td>
      <td>
        <div className='h-2 w-16 bg-khaki-2-3 rounded' />
      </td>
      <td>
        <div className='h-2 w-16 bg-khaki-2-3 rounded' />
      </td>
    </tr>
  );
});

VideoAdminTableRow.displayName = 'VideoAdminTableRow';
