import crypto from 'crypto';

export const capitalize = ([first, ...rest] = '', locales?: string | string[] | undefined) => {
  return first?.toLocaleUpperCase(locales) + rest.join('') ?? '';
};

export const getInitials = (name: string | undefined) => {
  if (name) {
    return name
      .split(' ')
      .map((n) => n[0])
      .join('');
  }
};

export const getRandomString = (length = 6) => {
  return crypto.randomBytes(20).toString('hex').slice(0, length);
};
