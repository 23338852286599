import { httpBatchLink, httpLink, loggerLink, splitLink } from '@trpc/client';
import { createTRPCNext } from '@trpc/next';
import superjson from 'superjson';

import { SITE_URL } from '@/lib/constants';
import { useAppDataStore } from '@/stores/appDataStore';

import type { AppRouter } from '../trpc/router';

export let token: string | undefined;

const QUERY_DEBUG_ENABLED = false;

export const setToken = (newToken: string) => {
  token = newToken;
  //? We want to ensure that we only update this here so that we know for sure that we can make authed requests.
  useAppDataStore.getState().setAccessToken(newToken);
};

export const trpc = createTRPCNext<AppRouter>({
  config() {
    return {
      transformer: superjson,
      links: [
        loggerLink({
          enabled: (opts) =>
            (QUERY_DEBUG_ENABLED && process.env.NODE_ENV === 'development' && typeof window !== 'undefined') ||
            (opts.direction === 'down' && opts.result instanceof Error),
        }),
        splitLink({
          condition: () => {
            if (token) {
              return true;
            }
            return false;
          },
          false: httpBatchLink({
            url: `${SITE_URL}/api/trpc`,
            headers: () => ({
              authorization: `Bearer ${token}`,
            }),
          }),
          true: httpLink({
            url: `${SITE_URL}/api/trpc`,
            headers: () => ({ authorization: `Bearer ${token}` }),
          }),
        }),
      ],
    };
  },
  ssr: false,
});
