import * as React from 'react';

import { useUser } from '@clerk/nextjs';
import Cookies from 'js-cookie';

import * as typewriter from '@/analytics/client';
import { mixpanelTrack } from '@/analytics/client';
import { SITE_URL } from '@/lib/constants';
import { WorkoutSlim } from '@/types/28';
import { getUserMetadata } from '@/utils/auth/getUserMetadata';
import { userPhasedayNumber, userPhasedayNumberRaw, userCycleDaysLeft, userLastPeriodDate } from '@/utils/date';
import { trpc } from '@/utils/trpc';

import { RouterOutput } from '../trpc/router';

type Action =
  | {
      type: 'BUTTON_CLICKED';
      payload: typewriter.ButtonClicked;
    }
  | {
      type: 'CTA_CLICKED';
      payload: { destination: string; location?: string; text: string; type: typewriter.Type; url: string };
    }
  | { type: 'CYCLE_UPDATED'; payload: { dbUser: RouterOutput['users']['update']['data'] } }
  | {
      type: 'EXPLICIT_IDENTIFY';
      payload: { dbUser: RouterOutput['users']['update']['data'] };
    }
  | {
      type: 'INIT_IDENTIFY';
      payload: { dbUser: RouterOutput['users']['byId']['data'] };
    }
  | {
      type: 'ONBOARDING_QUESTION_ANSWERED';
      payload: typewriter.OnboardingQuestionAnswered;
    }
  | { type: 'PAGE_VIEW'; payload: { asPath: string } }
  | { type: 'RESET_INIT_IDENTIFY' }
  | { type: 'SIGNED_OUT'; payload: { email: string } }
  | { type: 'TRIAL_STARTED'; payload: typewriter.TrialStarted }
  | {
      type: 'VIDEO_COMPLETED';
      payload: {
        category: string;
        dbUser?: RouterOutput['users']['byId']['data'];
        duration: number;
        workout: WorkoutSlim;
      };
    }
  | {
      type: 'VIDEO_STARTED';
      payload: {
        category: string;
        dbUser?: RouterOutput['users']['byId']['data'];
        duration: number;
        location?: string;
        workout: WorkoutSlim;
      };
    }
  | {
      type: 'WORKOUT_VIEWED';
      payload: {
        dbUser?: RouterOutput['users']['byId']['data'];
        location: string;
        workout: RouterOutput['workouts']['recommended']['data'][number];
      };
    };

type State = {
  dbUser?: RouterOutput['users']['byId']['data'];
  initIdentifyUsed: boolean;
};

const reducer: React.Reducer<State, Action> = (state, action) => {
  switch (action.type) {
    case 'BUTTON_CLICKED': {
      typewriter.buttonClicked({ ...action.payload });
      mixpanelTrack.buttonClicked({ ...action.payload });

      return state;
    }
    case 'CTA_CLICKED': {
      const { dbUser } = state;
      const { destination, text, type, url, location } = action.payload;

      const params = {
        date_of_birth: dbUser?.birthday || undefined,
        destination,
        email: dbUser?.email,
        fbc: Cookies.get('fbc'),
        fbp: Cookies.get('fbp'),
        first_name: dbUser?.firstName || undefined,
        last_name: dbUser?.lastName || undefined,
        location,
        phone: dbUser?.phone || undefined,
        text,
        ttclid: Cookies.get('ttclid'),
        type,
        url,
      };
      typewriter.cTAClicked(params);
      mixpanelTrack.cTAClicked(params);

      return state;
    }
    case 'CYCLE_UPDATED': {
      const { dbUser } = action.payload;

      const params = {
        cycle_duration: dbUser.cycleDuration,
        cycle_lunar: dbUser.cycleLunar,
        cycle_phase: dbUser.phaseName,
        cycle_start: dbUser.cycleStart,
        days_left: userCycleDaysLeft(dbUser.cycleStart, dbUser.cycleDuration, dbUser.timeZone, dbUser.cycleOverrides),
        email: dbUser.email,
        last_period_date: userLastPeriodDate(
          dbUser.cycleStart,
          dbUser.cycleDuration,
          dbUser.timeZone,
          dbUser.cycleOverrides,
        ),
        phase_day_number_raw: userPhasedayNumberRaw(
          dbUser.cycleStart,
          dbUser.cycleDuration,
          dbUser.timeZone,
          dbUser.cycleOverrides,
        ),
        phase_day_number: userPhasedayNumber(
          dbUser.cycleStart,
          dbUser.cycleDuration,
          dbUser.timeZone,
          dbUser.menstrualDuration,
          dbUser.cycleOverrides,
        ),
      };
      typewriter.cycleUpdated(params);
      mixpanelTrack.cycleUpdated(params);

      return state;
    }
    case 'EXPLICIT_IDENTIFY': {
      const { dbUser } = action.payload;

      const phasedayNumber = userPhasedayNumber(
        dbUser.cycleStart,
        dbUser.cycleDuration,
        dbUser.timeZone,
        dbUser.menstrualDuration,
        dbUser.cycleOverrides,
      );
      const phasedayNumberRaw = userPhasedayNumberRaw(
        dbUser.cycleStart,
        dbUser.cycleDuration,
        dbUser.timeZone,
        dbUser.cycleOverrides,
      );
      const daysLeft = userCycleDaysLeft(
        dbUser.cycleStart,
        dbUser.cycleDuration,
        dbUser.timeZone,
        dbUser.cycleOverrides,
      );

      const params = {
        cycle_lunar: dbUser.cycleLunar,
        cycle_phase: dbUser.phaseName,
        cycle_start: dbUser.cycleStart,
        email: dbUser.email,
        first_name: dbUser.firstName,
        last_name: dbUser.lastName,
        name: dbUser.name,
        phaseday_number: phasedayNumber,
        phaseday_number_raw: phasedayNumberRaw,
        days_left: daysLeft,
        cycle_duration: dbUser.cycleDuration,
      };
      window.analytics.identify(dbUser.id, params);
      mixpanelTrack.identify({ userId: dbUser.id, traits: params });

      return state;
    }
    // only calls identify once, unless it's been explicityly reset
    case 'INIT_IDENTIFY': {
      if (state.initIdentifyUsed) return state;
      const { dbUser } = action.payload;

      const signInParams = {
        cycle_phase: dbUser.phaseName,
        email: dbUser.email,
      };
      typewriter.signedIn(signInParams);
      mixpanelTrack.signedIn(signInParams);

      const params = {
        cycle_duration: dbUser.cycleDuration,
        cycle_lunar: dbUser.cycleLunar,
        cycle_phase: dbUser.phaseName,
        cycle_start: dbUser.cycleStart,
        days_left: userCycleDaysLeft(dbUser.cycleStart, dbUser.cycleDuration, dbUser.timeZone, dbUser.cycleOverrides),
        email: dbUser.email,
        first_name: dbUser.firstName,
        last_name: dbUser.lastName,
        last_period_date: userLastPeriodDate(
          dbUser.cycleStart,
          dbUser.cycleDuration,
          dbUser.timeZone,
          dbUser.cycleOverrides,
        ),
        name: dbUser.name,
        phaseday_number_raw: userPhasedayNumberRaw(
          dbUser.cycleStart,
          dbUser.cycleDuration,
          dbUser.timeZone,
          dbUser.cycleOverrides,
        ),
        phaseday_number: userPhasedayNumber(
          dbUser.cycleStart,
          dbUser.cycleDuration,
          dbUser.timeZone,
          dbUser.menstrualDuration,
          dbUser.cycleOverrides,
        ),
      };
      window.analytics.identify(dbUser.id, params);
      mixpanelTrack.identify({ userId: dbUser.id, traits: params });

      return { ...state, initIdentifyUsed: true };
    }
    case 'ONBOARDING_QUESTION_ANSWERED': {
      typewriter.onboardingQuestionAnswered({ ...action.payload });
      mixpanelTrack.onboardingQuestionAnswered({ ...action.payload });

      return state;
    }
    case 'PAGE_VIEW': {
      const { asPath } = action.payload;

      window.analytics.page(asPath);

      return state;
    }
    case 'RESET_INIT_IDENTIFY':
      return { ...state, initIdentifyUsed: false };
    case 'SIGNED_OUT': {
      const { email } = action.payload;

      typewriter.signedOut({
        email,
      });
      mixpanelTrack.signedOut({
        email,
      });

      return state;
    }
    case 'TRIAL_STARTED': {
      typewriter.trialStarted({ ...action.payload });
      mixpanelTrack.trialStarted({ ...action.payload });

      return state;
    }
    case 'VIDEO_COMPLETED': {
      const { category, dbUser, workout, duration } = action.payload;

      if (workout?.intensity && workout.phasedays[0].phaseday.phase.name && workout.targetArea) {
        const params = {
          email: dbUser?.email,
          category,
          duration,
          intensity: workout.intensity,
          title: workout.title,
          workout_cycle_phase: workout.phasedays[0].phaseday.phase.name,
          workout_type: workout.targetArea,
        };
        typewriter.videoCompleted(params);
        mixpanelTrack.videoCompleted(params);
      }

      return state;
    }
    case 'VIDEO_STARTED': {
      const { category, dbUser, workout, duration, location } = action.payload;

      if (workout?.intensity && workout.phasedays[0].phaseday.phase.name && workout.targetArea) {
        const params = {
          /**
           * The `category` property refers to the literal type of the video. In this case just "Workout".
           * In the future we could have more categories like a "Trailer" video or a "Promotion".
           */
          category,
          email: dbUser?.email,
          duration,
          instructor: workout.instructor?.name as string,
          intensity: workout.intensity,
          /**
           * The `location` property refers to the general area of componentry that the event was triggered.
           * In the case of the WorkoutPlayer it can be used from both the Dashboard and the Workout page. So
           * we would specify based on the route if it was the player in the Dashboard or on a Workout page.
           */
          location,
          title: workout.title,
          workout_cycle_phase: workout.phasedays[0].phaseday.phase.name,
          workout_type: workout.targetArea,
        };
        typewriter.videoStarted(params);
        mixpanelTrack.videoStarted(params);
      }

      return state;
    }
    case 'WORKOUT_VIEWED': {
      const { workout, location } = action.payload;

      if (workout.instructor && workout.intensity && workout.phasedays[0].phaseday.phase.name && workout.targetArea) {
        const params = {
          instructor: workout.instructor.name,
          intensity: workout.intensity,
          workout_cycle_phase: workout.phasedays[0].phaseday.phase.name,
          workout_type: workout.targetArea,
          location,
          title: workout.title,
          url: `${SITE_URL}/workouts/${workout.slug}`,
        };
        typewriter.workoutViewed(params);
        mixpanelTrack.workoutViewed(params);
      }

      return state;
    }
    default:
      return state;
  }
};

const initialState: State = {
  initIdentifyUsed: false,
};

export const AnalyticsStateContext = React.createContext<State | undefined>(undefined);
export const AnalyticsDispatchContext = React.createContext<React.Dispatch<Action> | undefined>(undefined);

export const useAnalytics = () => {
  const stateContext = React.useContext(AnalyticsStateContext);

  if (!stateContext) {
    throw new Error('state context was not provided for `useUI`');
  }

  return stateContext;
};

export const useAnalyticsDispatch = () => {
  const dispatchContext = React.useContext(AnalyticsDispatchContext);

  if (!dispatchContext) {
    throw new Error('dispatch context was not provided for `useUIDispatch`');
  }

  return dispatchContext;
};

export const AnalyticsProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { user } = useUser();
  const { userId } = getUserMetadata(user);
  const { data: dbUser } = trpc.users.byId.useQuery({ id: userId || '' }, { enabled: !!userId });

  const [state, dispatch] = React.useReducer(reducer, initialState);

  return (
    <AnalyticsStateContext.Provider value={{ ...state, dbUser: dbUser?.data }}>
      <AnalyticsDispatchContext.Provider value={dispatch}>{children}</AnalyticsDispatchContext.Provider>
    </AnalyticsStateContext.Provider>
  );
};
