import * as React from 'react';

import { X } from '@phosphor-icons/react';
import clsx from 'clsx';

import { useUIDispatch } from '@/components/ui';

export interface SimpleModalProps extends Omit<React.ComponentPropsWithoutRef<'div'>, 'title'> {
  title: string;
  children: React.ReactNode;
}

export const SimpleModal = React.memo<SimpleModalProps>(({ title, children, className }) => {
  const ui = useUIDispatch();

  return (
    <div className='overflow-y-scroll overscroll-contain max-w-md text-center' id='admin-modal-scroll'>
      <div className={clsx('py-8 px-16', className)}>
        <button aria-label='Close panel' className='absolute top-4 right-4' onClick={() => ui({ type: 'MODAL_CLOSE' })}>
          <X className='w-5 h-5' />
        </button>
        <h2 className='font-josefin text-2xl font-bold tracking-tighter pt-4 px-8 mb-6'>{title}</h2>
        {children}
      </div>
    </div>
  );
});

SimpleModal.displayName = 'SimpleModal';
