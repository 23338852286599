import * as React from 'react';

import dynamic from 'next/dynamic';
import { FieldErrors } from 'react-hook-form';

import { FormErrorMessage } from '@/components/common';
import { InstructorBrowserProps } from '@/components/instructors';
import { Button, useUIDispatch } from '@/components/ui';

import { RouterOutput } from '../../../trpc/router';
import { InstructorAdminCard } from '../InstructorAdminCard';

interface InstructorSelectFieldProps {
  errors?: FieldErrors;
  name: string;
  label: string;
  instructor: Omit<RouterOutput['instructors']['byId'], 'coverPhoto'> | null;
  onInstructorSelect: (instructor: Omit<RouterOutput['instructors']['byId'], 'coverPhoto'>) => void;
}

const InstructorBrowser = dynamic<InstructorBrowserProps>(
  () => import('@/components/instructors').then((mod) => mod.InstructorBrowser),
  { ssr: false },
);

export const InstructorSelectField = React.memo<InstructorSelectFieldProps>(
  ({ errors, name, label, instructor, onInstructorSelect }) => {
    const [selectedInstructor, setSelectedInstructor] = React.useState<Omit<
      RouterOutput['instructors']['byId'],
      'coverPhoto'
    > | null>(instructor);
    const uiDispatch = useUIDispatch();

    const onSelectInstructor = React.useCallback(
      (instructor: Omit<RouterOutput['instructors']['byId'], 'coverPhoto'>) => {
        setSelectedInstructor(instructor);
        onInstructorSelect(instructor);
      },
      [onInstructorSelect],
    );

    return (
      <div>
        <label className='font-josefin font-bold block mb-2 tracking-tighter'>{label}</label>
        <div className='flex gap-4 items-center'>
          {selectedInstructor && (
            <InstructorAdminCard className='border border-khaki-3 w-40' instructor={selectedInstructor} />
          )}
          <div>
            <Button
              onClick={() =>
                uiDispatch({
                  type: 'MODAL_OPEN',
                  content: (
                    <InstructorBrowser
                      onInstructorSelect={onSelectInstructor}
                      selectedInstructor={selectedInstructor}
                    />
                  ),
                })
              }
              size='sm'
              type='button'
              variant='secondary'
            >
              {instructor ? 'Swap' : 'Choose'} Instructor
            </Button>
          </div>
        </div>
        <FormErrorMessage errors={errors} name={name} />
      </div>
    );
  },
);

InstructorSelectField.displayName = 'InstructorSelectField';
