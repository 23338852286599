import * as React from 'react';

import { zodResolver } from '@hookform/resolvers/zod';
import { useRouter } from 'next/router';
import { SubmitHandler, useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import { z } from 'zod';

import { ButtonLink } from '@/components/common';
import { Button, Checkbox } from '@/components/ui';
import { useLeavePrevention } from '@/hooks/useLeavePrevention';
import { ERROR, WARN } from '@/utils/form';
import { trpc } from '@/utils/trpc';

import { RouterOutput } from '../../../trpc/router';

type InstructorDeleteFormValues = { confirm: boolean };

const schema: z.ZodType<InstructorDeleteFormValues> = z.lazy(() =>
  z.object({ confirm: z.boolean({ required_error: ERROR.REQUIRED }) }),
);

export interface InstructorDeleteFormProps {
  image: RouterOutput['images']['byId'];
}

export const ImageDeleteForm = React.memo<InstructorDeleteFormProps>(({ image }) => {
  const router = useRouter();
  const {
    register,
    handleSubmit,
    watch,
    formState: { isSubmitting, errors, isDirty, isSubmitSuccessful },
  } = useForm<InstructorDeleteFormValues>({ resolver: zodResolver(schema) });
  const watchConfirm = watch('confirm');

  const { mutate } = trpc.images.deleteProcedure.useMutation({
    onMutate: () => toast.loading('Deleting image...'),
    onError: ({ message }) => {
      toast.remove();
      toast.error(message);
    },
    onSuccess: ({ title }) => {
      toast.remove();
      toast.success(`Image ${title} deleted`);
      router.push('/admin/images');
    },
  });

  useLeavePrevention(isDirty && !isSubmitting && !isSubmitSuccessful, WARN.UNSAVED_CHANGES);

  const onSubmit = React.useCallback<SubmitHandler<InstructorDeleteFormValues>>(
    ({ confirm }) => confirm && mutate({ id: image.id }),
    [image.id, mutate],
  );

  if (Object.values(image._count).reduce((prev, current) => prev + current, 0)) {
    return (
      <div className='bg-khaki-1 rounded-lg p-8 overflow-hidden'>
        <h2 className='font-josefin font-bold text-2xl tracking-tighter mb-10'>You cannot delete {image.title}.</h2>
        <div className='flex gap-x-6 mt-2 -mx-8 -mb-8 p-8 py-5 rounded-bl-lg bg-khaki-2-3'>
          <ButtonLink href={`/admin/images/${image.id}`} variant='secondary'>
            Back
          </ButtonLink>
        </div>
      </div>
    );
  }

  return (
    <form className='bg-khaki-1 rounded-lg p-8 overflow-hidden' onSubmit={handleSubmit(onSubmit)}>
      <h2 className='font-josefin font-bold text-2xl tracking-tighter'>
        Are you sure you want to permanently delete {image.title}?
      </h2>
      <Checkbox errors={errors} label='I understand this action can not be undone.' {...register('confirm')} />
      <div className='flex gap-x-6 mt-2 -mx-8 -mb-8 p-8 py-5 rounded-bl-lg bg-khaki-2-3'>
        <Button
          disabled={isSubmitting || !watchConfirm}
          state={isSubmitting ? 'waiting' : undefined}
          type='submit'
          variant='danger'
        >
          Yes, delete this image
        </Button>
        <ButtonLink href={`/admin/images/${image.id}`} variant='minimal'>
          No, I changed my mind
        </ButtonLink>
      </div>
    </form>
  );
});

ImageDeleteForm.displayName = 'ImageDeleteForm';
