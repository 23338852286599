import * as React from 'react';

import clsx from 'clsx';

export interface PhasedayAdminCardProps {
  phasedayNumber?: number;
  phaseName?: string;
  className?: string;
}

export const PhasedayAdminCard = React.memo<PhasedayAdminCardProps>(({ phasedayNumber, phaseName, className }) => {
  if (phasedayNumber && phaseName) {
    return (
      <div className={clsx('flex flex-col items-center justify-center p-4 rounded-lg bg-white gap-2', className)}>
        <div
          className={clsx(
            'font-josefin font-bold text-3xl flex flex-col items-center justify-center w-20 h-20 py-4 px-6 rounded-full text-white',
            { 'bg-blue': phaseName === 'Menstrual' },
            { 'bg-green': phaseName === 'Follicular' },
            { 'bg-orange': phaseName === 'Ovulatory' },
            { 'bg-purple-1': phaseName === 'Luteal' },
          )}
        >
          {phasedayNumber}
        </div>
        <div className='font-josefin text-sm'>{phaseName}</div>
      </div>
    );
  }

  return (
    <div className='flex flex-col items-center gap-2 p-4 text-center rounded-lg bg-white animate-pulse'>
      <div />
      <div className='bg-khaki-2-3 w-20 h-20 rounded-full' />
      <div className='h-2 w-16 bg-khaki-2-3 rounded' />
    </div>
  );
});

PhasedayAdminCard.displayName = 'PhasedayAdminCard';
