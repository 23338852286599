import * as React from 'react';

import { Link } from '@/components/common';
import { DashboardCycle, DashboardSkeleton, DashboardWorkouts } from '@/components/dashboard';
import { InsightsNutritionCta } from '@/components/marketing';
import { Period } from '@/components/ui';
import { trpc } from '@/utils/trpc';

import { RouterOutput } from '../../../trpc/router';

const REFERRAL_CAMPAIGN = process.env.NEXT_PUBLIC_GROWSURF_REFERRAL_CAMPAIGN || '';

export const DashboardView = React.memo<{ dbUser: RouterOutput['users']['byId']['data'] }>(({ dbUser }) => {
  const { mutate } = trpc.referrals.getOrAddParticipant.useMutation();
  const { data: workout } = trpc.users.workout.useQuery(
    { id: dbUser.id },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  );

  React.useEffect(() => {
    // On mount we want to ensure that the user exists or is added to our C2C referral campaign.
    if (dbUser?.id) {
      mutate({
        id: dbUser.id,
        campaignId: REFERRAL_CAMPAIGN,
      });
    }
  }, [dbUser?.id, mutate]);

  if (!workout) {
    return (
      <section>
        <DashboardSkeleton />
      </section>
    );
  }
  const { phaseday, featuredWorkout } = workout;

  if (!phaseday || !featuredWorkout) {
    return <section>something went wrong</section>;
  }

  return (
    <section>
      <DashboardCycle dbUser={dbUser} phaseday={phaseday} />
      <DashboardWorkouts dbUser={dbUser} featuredWorkout={featuredWorkout} phaseName={phaseday.phase.name} />
      <InsightsNutritionCta />
      <div className='max-w-7xl px-6 md:px-12 mx-auto md:text-right mt-6 text-sm tracking-tight font-josefin'>
        <Link href='/account'>
          Sound inaccurate? <strong>Adjust your period</strong>
          <Period className='inline-block ml-2 w-4 h-4' />
        </Link>
      </div>
    </section>
  );
});

DashboardView.displayName = 'DashboardView';
