import * as React from 'react';

import { useUIDispatch } from '@/components/ui';
import { PhaseName } from '@/types/28';

import { PhaseJumpConfirm } from '../PhaseJumpConfirm';

type PhaseJumpProps = {
  phaseName?: PhaseName;
  userId?: string;
};

export const PhaseJump = React.memo<PhaseJumpProps>(({ phaseName, userId }) => {
  const uiDispatch = useUIDispatch();

  if (phaseName === 'Menstrual')
    return (
      <button
        className='mt-3 font-josefin text-sm underline bg-transparent'
        onClick={() =>
          uiDispatch({
            type: 'MODAL_OPEN',
            content: (
              <PhaseJumpConfirm heading='Looks like your period ended early.' phaseName='Follicular' userId={userId} />
            ),
          })
        }
      >
        My period ended
      </button>
    );

  if (phaseName === 'Luteal')
    return (
      <button
        className='mt-3 font-josefin text-sm underline bg-transparent'
        onClick={() =>
          uiDispatch({
            type: 'MODAL_OPEN',
            content: (
              <PhaseJumpConfirm heading='Looks like your period started early.' phaseName='Menstrual' userId={userId} />
            ),
          })
        }
      >
        My period started
      </button>
    );

  return <div className='h-5 mt-3' />;
});

PhaseJump.displayName = 'PhaseJump';
