import { useQuery } from '@tanstack/react-query';

export type ThumbnailVTT = {
  start: number;
  end: number;
  x: number;
  y: number;
  w: number;
  h: number;
  image: string;
};

const fetcher = (url: string): Promise<string> => {
  // TODO: Promise needs reject condition
  return new Promise((resolve) => {
    const req = new XMLHttpRequest();
    req.open('GET', url);
    req.overrideMimeType('text/plain; charset=utf-8');
    req.send();
    req.onload = () => resolve(req.responseText);
  });
};

const deconstructTimestamp = (timestamp: string) => {
  const splitStampMilliseconds = timestamp.split('.');
  const timeParts = splitStampMilliseconds[0];
  const timePartsSplit = timeParts.split(':');

  return {
    milliseconds: parseInt(splitStampMilliseconds[1], 10) || 0,
    seconds: parseInt(timePartsSplit?.pop() ?? '', 10) || 0,
    minutes: parseInt(timePartsSplit?.pop() ?? '', 10) || 0,
    hours: parseInt(timePartsSplit?.pop() ?? '', 10) || 0,
  };
};

const getSecondsFromTimestamp = (timestamp: string) => {
  const timestampParts = deconstructTimestamp(timestamp);
  const seconds =
    timestampParts.hours * (60 * 60) +
    timestampParts.minutes * 60 +
    timestampParts.seconds +
    timestampParts.milliseconds / 1000;

  return seconds;
};

const getPropsFromDef = (def: string) => {
  const imageDefSplit = def.split(/#xywh=/i);
  const imageUrl = imageDefSplit[0];
  const imageCoords = imageDefSplit[1];
  const splitCoords = imageCoords.match(/[0-9]+/gi);

  return {
    x: splitCoords ? Number(splitCoords[0]) : 0,
    y: splitCoords ? Number(splitCoords[1]) : 0,
    w: splitCoords ? Number(splitCoords[2]) : 0,
    h: splitCoords ? Number(splitCoords[3]) : 0,
    image: imageUrl,
  };
};

// TODO: Brian - type `params` based off MUX options
export const useStoryboard = (playbackId: string | undefined, storyboardToken?: string) => {
  const { data, refetch, error } = useQuery({
    queryKey: [playbackId],
    enabled: !!playbackId,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    queryFn: async () => {
      const data = await fetcher(
        `https://image.mux.com/${playbackId}/storyboard.vtt${storyboardToken ? `?token=${storyboardToken}` : ''}`,
      );

      const processedVtts: ThumbnailVTT[] = [];
      const vttDefinitions = data.split(/[\r\n][\r\n]/i);

      vttDefinitions.forEach((vttDef) => {
        if (
          vttDef.match(
            /([0-9]{2}:)?([0-9]{2}:)?[0-9]{2}(.[0-9]{3})?( ?--> ?)([0-9]{2}:)?([0-9]{2}:)?[0-9]{2}(.[0-9]{3})?[\r\n]{1}.*/gi,
          )
        ) {
          const vttDefSplit = vttDef.split(/[\r\n]/i);
          const vttTiming = vttDefSplit[0];
          const vttTimingSplit = vttTiming.split(/ ?--> ?/i);
          const vttTimeStart = vttTimingSplit[0];
          const vttTimeEnd = vttTimingSplit[1];
          const vttImageDef = vttDefSplit[1];
          const imageProps = getPropsFromDef(vttImageDef);

          processedVtts.push({
            start: getSecondsFromTimestamp(vttTimeStart),
            end: getSecondsFromTimestamp(vttTimeEnd),
            image: imageProps.image,
            x: imageProps.x,
            y: imageProps.y,
            w: imageProps.w,
            h: imageProps.h,
          });
        }
      });

      return processedVtts;
    },
  });

  return { data, loading: !data && !error, error, refetch };
};
