export const getUserMetadata = (
  user?: { externalId?: string | null; publicMetadata?: Record<string, unknown> } | null,
) => {
  return {
    roles: user?.publicMetadata?.roles as string,
    userId: (user?.externalId as string) ?? (user?.publicMetadata?.user_id as string),
    username: user?.publicMetadata?.username as string,
    firstName: user?.publicMetadata?.first_name as string,
  };
};
