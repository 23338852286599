import { MethodItem } from './types';

export const method: MethodItem[] = [
  {
    image: '/images/marketing-card/lengthen-strengthen-tone.jpg',
    heading: 'Lengthen, Strengthen & Tone',
    copy: 'An innovative  process designed to lean your legs, tone your arms, sculpt your booty, and tighten your abs. Best of all? It works for any body type, regardless of size or shape.',
  },
  {
    image: '/images/marketing-card/gentle-but-effective.jpg',
    heading: 'Gentle but Effective',
    copy: "Sometimes, less is more. Our bodies don't need constant cardio and heavy weights to achieve amazing results. With the right form, focus, and movements, your natural body weight can work wonders.",
  },
  {
    image: '/images/marketing-card/avoid-injury.jpg',
    heading: 'Avoid Injury',
    copy: 'Say goodbye to swelling and pain. Overly strenuous workouts encourage bad form that leads to injuries. 28 emphasizes proper form through functional movements that your body is naturally familiar with when you carry, reach, and bend in everyday life. ',
  },
  {
    image: '/images/marketing-card/balance-your-hormones.jpg',
    heading: 'Balance Your Hormones',
    copy: 'Specific movements and nutrition help to balance your hormones, the powerful molecules that regulate your body, skin, and emotions. ',
  },
  {
    image: '/images/marketing-card/healthy-energy-levels.jpg',
    heading: 'Healthy Energy Levels',
    copy: "Varying the intensity according to each phase ensures you're optimizing your energy levels and never working your body more than you need to.",
  },
  {
    image: '/images/marketing-card/workout-anywhere.jpg',
    heading: 'Workout Anywhere',
    copy: "The world is your gym. Whether you're in a dorm room, the beach, the forest, or your home, all you need is a laptop, tablet, or phone.",
  },
];
