import * as React from 'react';

import { FieldErrors } from 'react-hook-form';

import { FormErrorMessage } from '@/components/common';

export type TextareaProps = React.TextareaHTMLAttributes<HTMLTextAreaElement> & {
  description?: string;
  errors?: FieldErrors;
  label: string;
};

export const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ errors, label, description, ...props }, ref) => {
    return (
      <div>
        <label className='font-lato font-bold block mb-2 text-sm' htmlFor={props.name}>
          {label}
        </label>
        <textarea
          autoCapitalize='off'
          autoComplete='off'
          autoCorrect='off'
          className='w-full min-h-[6rem] p-4 bg-white border border-khaki-2-3 text-base align-middle resize-y rounded-lg outline-none focus:border-khaki-5'
          ref={ref}
          spellCheck='false'
          {...props}
        />
        {description && <div>{description}</div>}
        <FormErrorMessage errors={errors} name={props.name as string} />
      </div>
    );
  },
);

Textarea.displayName = 'Textarea';
