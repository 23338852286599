import { z } from 'zod';

export const entitlementsSchema = z.record(
  z.object({
    expires_date: z.string(),
    grace_period_expires_date: z.string().nullable(),
    product_identifier: z.string(),
    purchase_date: z.string(),
  }),
);
export type Entitlements = z.infer<typeof entitlementsSchema>;

export const subscriptionsSchema = z.record(
  z.object({
    auto_resume_date: z.string().nullable(),
    billing_issues_detected_at: z.string().nullable(),
    expires_date: z.string(),
    grace_period_expires_date: z.string().nullable(),
    is_sandbox: z.boolean(),
    original_purchase_date: z.string(),
    ownership_type: z.string(),
    period_type: z.string(),
    purchase_date: z.string(),
    refunded_at: z.string().nullable(),
    store: z.string(),
    unsubscribe_detected_at: z.string().nullable(),
  }),
);
export type Subscriptions = z.infer<typeof subscriptionsSchema>;

export type RevenueCatUser = {
  request_date: string;
  request_date_ms: number;
  subscriber: {
    entitlements: Entitlements;
    first_seen: string;
    last_seen: string;
    management_url: string | null;
    non_subscriptions: Record<
      string,
      {
        id: string;
        purchase_date: string;
        store: 'app_store' | 'play_store' | 'mac_app_store' | 'stripe' | 'amazon';
        is_sandbox: boolean;
      }
    >;
    original_app_user_id: string;
    original_application_version: string;
    original_purchase_date: string;
    subscriber_attributes: {
      $attConsentStatus: {
        updated_at_ms: number;
        value: string;
      };
    };
    subscriptions: Subscriptions;
  };
};

export type RevenueCatWebhookEventType =
  | 'BILLING_ISSUE'
  | 'CANCELLATION'
  | 'EXPIRATION'
  | 'INITIAL_PURCHASE'
  | 'NON_RENEWING_PURCHASE'
  | 'PRODUCT_CHANGE'
  | 'RENEWAL'
  | 'SUBSCRIBER_ALIAS' // deprecated
  | 'SUBSCRIPTION_PAUSED'
  | 'TEST'
  | 'TRANSFER'
  | 'UNCANCELLATION';

export type RevenueCatWebhookEventCommonFields = {
  aliases: string[];
  app_id: string;
  app_user_id: string;
  event_timestamp_ms: number;
  id: string;
  original_app_user_id: string;
  type: RevenueCatWebhookEventType;
};

export type CancelReason =
  | 'BILLING_ERROR'
  | 'CUSTOMER_SUPPORT'
  | 'DEVELOPER_INITIATED'
  | 'PRICE_INCREASE'
  | 'UNKNOWN'
  | 'UNSUBSCRIBE';

export type ExpirationReason =
  | 'BILLING_ERROR'
  | 'CUSTOMER_SUPPORT'
  | 'DEVELOPER_INITIATED'
  | 'PRICE_INCREASE'
  | 'SUBSCRIPTION_PAUSED'
  | 'UNKNOWN'
  | 'UNSUBSCRIBE';

export type Store = 'AMAZON' | 'APP_STORE' | 'MAC_APP_STORE' | 'PLAY_STORE' | 'PROMOTIONAL' | 'STRIPE';

export type IsTrialConversionProperty = { is_trial_conversion: boolean };
export type CancelReasonProperty = { cancel_reason: CancelReason };
export type ExpirationReasonProperty = { expiration_reason: ExpirationReason };

export interface RevenueCatTransferEvent {
  api_version: string;
  event: {
    app_id: string;
    environment: string;
    event_timestamp_ms: number;
    id: string;
    store: string;
    subscriber_attributes: {
      $displayName: DisplayName;
      $email: DisplayName;
    };
    transferred_from: string[];
    transferred_to: string[];
    type: string;
  };
}

export interface DisplayName {
  updated_at_ms: number;
  value: string;
}

export type RevenueCatWebhookPayload<Extra = unknown> = {
  api_version: string;
  event: RevenueCatWebhookEventCommonFields & {
    commission_percentage: number | null;
    country_code: string;
    currency: string | null;
    entitlement_id: string | null;
    entitlement_ids: string[] | null;
    environment: 'SANDBOX' | 'PRODUCTION';
    expiration_at_ms: number;
    is_family_share: boolean | null;
    offer_code: string | null;
    original_transaction_id: string | null;
    period_type: 'TRIAL' | 'INTRO' | 'NORMAL' | 'PROMOTIONAL';
    presented_offering_id: string | null;
    price: number | null;
    price_in_purchased_currency: number | null;
    product_id: string;
    purchased_at_ms: number;
    store: Store;
    subscriber_attributes: Record<
      string,
      {
        updated_at_ms: number;
        value: string;
      }
    >;
    takehome_percentage: number | null;
    tax_percentage: number | null;
    transaction_id: string | null;
  } & Extra;
};

export const durationValues = z.enum(
  ['daily', 'three_day', 'weekly', 'monthly', 'two_month', 'three_month', 'six_month', 'yearly', 'lifetime'],
  {
    required_error: 'Please select one of the options below',
    invalid_type_error: 'Please select one of the options below',
  },
);
export type DurationValues = z.infer<typeof durationValues>;
export const promotionDurationMap: Record<DurationValues, string> = {
  daily: 'One Day: 24 hour access',
  three_day: 'Three Days: 72 hour access',
  weekly: 'One Week: 7 day access',
  monthly: 'One Month: 1 month (31-day) access',
  two_month: '2 Months: 2 month (61-day) access',
  three_month: 'Three Months: 3 month (92-day) access',
  six_month: 'Six Months: 6 month (183-day) access',
  yearly: 'One Year: 1 year (365-day) access',
  lifetime: 'Lifetime: 200 year access',
};

export type Package = {
  identifier: string;
  platform_product_identifier: string;
};

export type Offering = {
  description: string;
  identifier: string;
  packages: Package[];
};

export type RevenueCatOfferingsResponse = {
  current_offering_id: string;
  offerings: Offering[];
};
