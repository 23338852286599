/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
export function logger(...args: any): void {
  console.log(...args);
}

logger.warn = function loggerWarn(...args: any) {
  console.warn(...args);
};

logger.error = function loggerError(...args: any) {
  console.error(...args);
};
