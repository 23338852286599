import * as React from 'react';

import { Link } from '@/components/common';
import { Button, useUIDispatch } from '@/components/ui';
import { useAcceptCookies } from '@/hooks/useAcceptCookies';

export const AcceptCookies = React.memo(() => {
  const uiDispatch = useUIDispatch();

  const { onAcceptCookies } = useAcceptCookies();

  return (
    <>
      <p>
        This site uses cookies to improve your experience. By clicking, you agree to our{' '}
        <Link className='underline' href='/privacy'>
          Privacy Policy
        </Link>
      </p>
      <Button
        onClick={() => {
          onAcceptCookies();
          uiDispatch({ type: 'DRAWER_CLOSE' });
        }}
        size='sm'
        variant='primary'
      >
        Accept cookies
      </Button>
    </>
  );
});

AcceptCookies.displayName = 'AcceptCookies';
