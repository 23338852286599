import React, { PropsWithChildren } from 'react';

import { useUser } from '@clerk/nextjs';
import { useRouter } from 'next/router';
import toast from 'react-hot-toast';

import { getUserMetadata } from '@/utils/auth/getUserMetadata';

interface ProtectProps extends PropsWithChildren {
  isAdmin: boolean;
}

export const Protect = ({ children, isAdmin }: ProtectProps) => {
  const { user, isLoaded } = useUser();
  const { replace, isReady } = useRouter();

  if (isLoaded && !user) {
    replace('/login');
  }

  const { roles } = getUserMetadata(user);

  if (isAdmin && !roles?.includes('ADMIN')) {
    if (isReady && roles?.length) {
      toast.error("Oops, looks like you don't have permission to view that!");
      replace('/');
    }
    return null;
  }

  return <>{children}</>;
};
