import * as React from 'react';

import NextLink, { LinkProps as NextLinkProps } from 'next/link';

import { ExternalLink } from '@/components/common';

export type LinkProps = React.ComponentPropsWithRef<'a'> & NextLinkProps;

export const Link = React.memo<LinkProps>(
  ({ children, href, as, replace, scroll, shallow, prefetch, locale, ...rest }) => {
    const isExternal = href?.startsWith('http') || href?.endsWith('.pdf') || false;
    const isAnchor = /^#/.test(href);

    if (isExternal) {
      return (
        <ExternalLink href={href} {...rest}>
          {children}
        </ExternalLink>
      );
    }

    if (isAnchor) {
      return (
        <a href={href} {...rest}>
          {children}
        </a>
      );
    }

    return (
      <NextLink
        as={as}
        href={href}
        locale={locale}
        passHref
        prefetch={prefetch}
        replace={replace}
        scroll={scroll}
        shallow={shallow}
        {...rest}
      >
        {children}
      </NextLink>
    );
  },
);

Link.displayName = 'Link';
