import * as React from 'react';

import { zodResolver } from '@hookform/resolvers/zod';
import NextImage from 'next/image';
import { useRouter } from 'next/router';
import { SubmitHandler, useForm } from 'react-hook-form';
import { z } from 'zod';

import { ButtonLink, FormErrorMessage } from '@/components/common';
import { Button, Input, ProgressBar } from '@/components/ui';
import { useLeavePrevention } from '@/hooks/useLeavePrevention';
import { UpdateImageInput, useImageUpdate } from '@/request/client';
import { ERROR, WARN } from '@/utils/form';
import { imgixLoader } from '@/utils/images';
import { zodImageFileUpdate } from '@/utils/zod';

import { RouterOutput } from '../../../trpc/router';

const schema: z.ZodType<UpdateImageInput> = z.lazy(() =>
  z.object({
    title: z.string().min(1, { message: ERROR.REQUIRED }),
    files: zodImageFileUpdate,
  }),
);

type ImageUpdateFormProps = {
  image: RouterOutput['images']['byId'];
};

export const ImageUpdateForm = React.memo<ImageUpdateFormProps>(({ image }) => {
  const router = useRouter();
  const {
    formState: { errors, isSubmitting, isDirty, isSubmitSuccessful },
    handleSubmit,
    register,
    watch,
  } = useForm<UpdateImageInput>({
    defaultValues: { title: image.title, files: undefined },
    resolver: zodResolver(schema),
  });
  const { updateImage, isUploading, progress } = useImageUpdate(image.id);

  useLeavePrevention(isDirty && !isSubmitting && !isSubmitSuccessful, WARN.UNSAVED_CHANGES);

  const onSubmit = React.useCallback<SubmitHandler<UpdateImageInput>>(
    (input) => updateImage(input).then(() => router.push(`/admin/images`)),
    [router, updateImage],
  );

  const canDelete = !Object.values(image._count).reduce((prev, current) => prev + current, 0);
  const files = watch('files');

  return (
    <form className='bg-khaki-1 rounded-lg p-8 overflow-hidden' onSubmit={handleSubmit(onSubmit)}>
      <Input errors={errors} label='Title' {...register('title')} />
      <div className='flex items-center gap-x-6'>
        {(typeof files === 'undefined' || files.length === 0) && (
          <NextImage
            alt={image.title}
            className='object-cover'
            height={200}
            loader={imgixLoader}
            src={`/${image.fileName}`}
            width={200}
          />
        )}
        <Input accept='image/*' disabled={isUploading} type='file' {...register('files')} />
        <FormErrorMessage errors={errors} name='files' />
        {progress > 0 && (
          <div className='max-w-xs mt-4'>
            <ProgressBar max={100} value={progress} />
          </div>
        )}
      </div>
      <div className='flex gap-x-6 mt-2 -mx-8 -mb-8 p-8 py-5 rounded-bl-lg bg-khaki-2-3'>
        <Button
          disabled={isSubmitting || !isDirty}
          state={isSubmitting ? 'waiting' : undefined}
          type='submit'
          variant='primary'
        >
          Update
        </Button>
        {canDelete && (
          <ButtonLink href={`/admin/images/${image.id}/delete`} variant='minimal'>
            Delete
          </ButtonLink>
        )}
      </div>
    </form>
  );
});

ImageUpdateForm.displayName = 'ImageUpdateForm';
