import * as React from 'react';

import type { ManifestParsedData, SubtitleTracksUpdatedData, HlsListeners } from 'hls.js';
import type Hls from 'hls.js';
import toast from 'react-hot-toast';

import { off, on } from '@/utils/listeners';
import { logger } from '@/utils/logger';

const HAS_NAVIGATOR = typeof navigator !== 'undefined';
const IS_IPAD_PRO = HAS_NAVIGATOR && navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1;
export const IS_IOS =
  HAS_NAVIGATOR && (/iPad|iPhone|iPod/.test(navigator.userAgent) || IS_IPAD_PRO) && !(window as any).MSStream;

export type PlayerEventCallback = (event: Event) => void;
export type PlayerHeartbeatEventCallback = (threshold: number) => void;
export type PlayerErrorEventCallback = (...args: any) => void;
export type PlayerProgressEventCallback = (progress: {
  loaded: number;
  loadedSeconds: number;
  played: number;
  playedSeconds: number;
}) => void;
export type PlayerReadyEventCallback = (event: 'hlsManifestParsed', data: ManifestParsedData) => void;
export type HlsTrackUpdateEventCallback = (event: 'hlsSubtitleTracksUpdated', data: SubtitleTracksUpdatedData) => void;

export type MuxConfig = {
  automaticErrorTracking?: boolean;
  debug?: boolean;
  disableCookies?: boolean;
  env_key: string;
  meta?: Record<string, string | number | boolean>;
  player_name: string;
  respectDoNotTrack?: boolean;
};

type PlayerProps = Omit<React.ComponentPropsWithRef<'video'>, 'onProgress'> & {
  forceHLS?: boolean;
  heartbeatCallback?: PlayerHeartbeatEventCallback;
  heartbeatThreshold?: number | number[];
  muted?: boolean;
  muxConfig?: MuxConfig;
  onBuffer?: PlayerEventCallback;
  onBufferEnd?: PlayerEventCallback;
  onDisablePIP?: PlayerEventCallback;
  onDuration?: PlayerEventCallback;
  onEnablePIP?: PlayerEventCallback;
  onEnded?: (event: React.SyntheticEvent<HTMLVideoElement, Event>, shouldCallTrackingEvent?: boolean) => void;
  onError?: PlayerErrorEventCallback;
  onInitTrackSwitch?: (trackId: number) => void;
  onPause?: PlayerEventCallback;
  onPlay?: PlayerEventCallback;
  onPresentationModeChange?: PlayerEventCallback;
  onProgress?: PlayerProgressEventCallback;
  onReady?: PlayerReadyEventCallback;
  onSeek?: PlayerEventCallback;
  onStart?: PlayerEventCallback;
  onSubtitleTrackUpdate?: HlsTrackUpdateEventCallback;
  pip?: boolean;
  playbackRate?: number;
  playing?: boolean;
  progressInterval?: number;
  subtitlesOnStart?: boolean;
  subtitleTrackId?: number;
  volume?: number;
};

type PIPType = 'inline' | 'picture-in-picture' | 'fullscreen';

interface SafariVideo extends HTMLVideoElement {
  webkitPresentationMode: PIPType;
  webkitSetPresentationMode: (type: PIPType) => void;
  webkitSupportsPresentationMode: boolean;
}

function supportsWebKitPresentationMode(video: SafariVideo = document.createElement('video') as SafariVideo) {
  // Check if Safari supports PiP, and is not on mobile (other than iPad)
  // iPhone safari appears to "support" PiP through the check, however PiP does not function
  const notMobile = /iPhone|iPod/.test(navigator.userAgent) === false;
  return video.webkitSupportsPresentationMode && typeof video.webkitSetPresentationMode === 'function' && notMobile;
}

declare module 'react' {
  function forwardRef<RefType, PropsType = unknown>(
    render: (props: PropsType, ref?: React.MutableRefObject<RefType>) => React.ReactElement | null,
  ): React.ForwardRefExoticComponent<React.PropsWithoutRef<PropsType> & React.RefAttributes<RefType>>;
}

// Consideration for where HLS is supported natively (currently safari)
function canPlayHLS(video: HTMLVideoElement) {
  return video.canPlayType('application/vnd.apple.mpegurl');
}

const ForwardRefPlayer = React.forwardRef<HTMLVideoElement, PlayerProps>(
  (
    {
      forceHLS,
      muted,
      muxConfig,
      onBuffer,
      onBufferEnd,
      onDisablePIP,
      onDuration,
      onEnablePIP,
      onEnded,
      onError,
      onInitTrackSwitch,
      onPause,
      onPlay,
      onPresentationModeChange,
      onProgress,
      onReady,
      onSeek,
      onStart,
      onSubtitleTrackUpdate,
      pip,
      playbackRate,
      playing,
      progressInterval,
      src,
      subtitlesOnStart,
      subtitleTrackId,
      heartbeatThreshold,
      heartbeatCallback,
      volume,
      ...props
    },
    ref,
  ) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const videoRef = ref || React.useRef<HTMLVideoElement>(null);
    const srcRef = React.useRef<string | null>(null);
    const hlsRef = React.useRef<Hls | null>(null);
    const onStartUsedRef = React.useRef<boolean>(false);
    const onEndedUsedRef = React.useRef<boolean>(false);
    const playingRef = React.useRef<boolean>(false);
    const pipRef = React.useRef<boolean>(false);
    const mutedRef = React.useRef<boolean>(false);
    const initSubititlesRef = React.useRef<boolean>(false);
    const subtitlesTrackRef = React.useRef<number>(0);
    const allThresholdsMetRef = React.useRef<boolean>(false);
    const heartbeatThresholdTrackerRef = React.useRef<number[]>([]);

    const shouldUseHLS = React.useCallback(
      (url: string | undefined) => {
        if (!url) return false;
        if (forceHLS) return true;
        if (IS_IOS) return false;

        return true;
      },
      [forceHLS],
    );

    const getCurrentTime = React.useCallback((video: HTMLVideoElement) => video.currentTime, []);
    const getDuration = React.useCallback((video: HTMLVideoElement) => video.duration, []);

    const getLoadedSeconds = React.useCallback((video: HTMLVideoElement) => {
      if (video.buffered.length === 0) return 0;

      const end = video.buffered.end(video.buffered.length - 1);
      const duration = video.duration;

      if (end > duration) return duration;
      return end;
    }, []);

    const progressCallback = React.useCallback(() => {
      if (videoRef.current && onProgress) {
        const video = videoRef.current;
        if (!video || !playingRef.current) return;

        const playedPercent = Number((getCurrentTime(video) / getDuration(video)).toFixed(2));

        if (heartbeatThreshold && heartbeatCallback && !allThresholdsMetRef.current) {
          if (typeof heartbeatThreshold == 'object') {
            // will be `undefined` after the last threshold is tracked
            const nextUntrackedThreshold = heartbeatThreshold[heartbeatThresholdTrackerRef.current.length];

            /**
             * if there is a current NEXT untracked threshold, and the percent played is greater than it,
             * push that untracked threshold into the tracker ref, and call the heartbeat callback
             * */
            if (nextUntrackedThreshold && playedPercent >= nextUntrackedThreshold) {
              heartbeatThresholdTrackerRef.current.push(nextUntrackedThreshold);
              if (heartbeatThresholdTrackerRef.current.length === heartbeatThreshold.length) {
                allThresholdsMetRef.current = true;
              }
              heartbeatCallback(nextUntrackedThreshold);
            }
          }

          if (typeof heartbeatThreshold === 'number') {
            if (playedPercent !== 0 && playedPercent >= heartbeatThreshold) {
              heartbeatThresholdTrackerRef.current.push(heartbeatThreshold);
              allThresholdsMetRef.current = true;
              heartbeatCallback(heartbeatThreshold);
            }
          }
        }

        onProgress({
          loaded: Number((getLoadedSeconds(video) / getDuration(video)).toFixed(2)),
          loadedSeconds: getLoadedSeconds(video),
          played: playedPercent,
          playedSeconds: getCurrentTime(video),
        });
      }
    }, [getCurrentTime, getDuration, getLoadedSeconds, heartbeatCallback, heartbeatThreshold, onProgress, videoRef]);

    const playCallback = React.useCallback<PlayerEventCallback>(
      (event) => {
        if (!onStartUsedRef.current && onStart) {
          onStart(event);
          onStartUsedRef.current = true;
        }

        playingRef.current = true;
        onPlay && onPlay(event);
      },
      [onPlay, onStart],
    );

    const onEndedCallback = React.useCallback(
      (event: React.SyntheticEvent<HTMLVideoElement, Event>) => {
        onEnded && onEnded(event, !onEndedUsedRef.current);
        if (!onEndedUsedRef.current) onEndedUsedRef.current = true;
        heartbeatThresholdTrackerRef.current = [];
      },
      [onEnded],
    );

    const pauseCallback = React.useCallback<PlayerEventCallback>(
      (event) => {
        playingRef.current = false;
        onPause && onPause(event);
      },
      [onPause],
    );

    const enablePIP = React.useCallback(() => {
      const video = videoRef.current;
      if (!video) return;

      if (video.requestPictureInPicture && document.pictureInPictureElement !== video) {
        video.requestPictureInPicture();
      } else if (
        supportsWebKitPresentationMode(video as SafariVideo) &&
        (video as SafariVideo).webkitPresentationMode !== 'picture-in-picture'
      ) {
        (video as SafariVideo).webkitSetPresentationMode('picture-in-picture');
      }
      pipRef.current = true;
    }, [videoRef]);

    const disablePIP = React.useCallback(() => {
      const video = videoRef.current;
      if (!video) return;

      if (document.exitPictureInPicture && document.pictureInPictureElement === video) {
        document.exitPictureInPicture();
      } else if (
        supportsWebKitPresentationMode(video as SafariVideo) &&
        (video as SafariVideo).webkitPresentationMode !== 'inline'
      ) {
        (video as SafariVideo).webkitSetPresentationMode('inline');
      }
      pipRef.current = false;
    }, [videoRef]);

    const mute = React.useCallback(() => {
      const video = videoRef.current;
      if (!video) return;

      video.muted = true;
      mutedRef.current = true;
    }, [videoRef]);

    const unmute = React.useCallback(() => {
      const video = videoRef.current;
      if (!video) return;

      video.muted = false;
      mutedRef.current = false;
    }, [videoRef]);

    const addListeners = React.useCallback(
      (video: HTMLVideoElement) => {
        on(video, 'ended', onEndedCallback);
        on(video, 'pause', pauseCallback);
        on(video, 'play', playCallback);
        onBuffer && on(video, 'waiting', onBuffer);
        onBufferEnd && on(video, 'playing', onBufferEnd);
        onDisablePIP && on(video, 'leavepictureinpicture', onDisablePIP);
        onDuration && on(video, 'durationchange', onDuration);
        onEnablePIP && on(video, 'enterpictureinpicture', onEnablePIP);
        onError && on(video, 'error', onError);
        onPresentationModeChange && on(video, 'webkitpresentationmodechanged', onPresentationModeChange);
        onSeek && on(video, 'seeked', onSeek);

        if (!shouldUseHLS(src) && onReady) on(video, 'canplay', onReady);
      },
      [
        onBuffer,
        onBufferEnd,
        onDisablePIP,
        onDuration,
        onEnablePIP,
        onEndedCallback,
        onError,
        onPresentationModeChange,
        onReady,
        onSeek,
        pauseCallback,
        playCallback,
        shouldUseHLS,
        src,
      ],
    );

    const removeListeners = React.useCallback(
      (video: HTMLVideoElement) => {
        off(video, 'ended', onEndedCallback);
        off(video, 'pause', pauseCallback);
        off(video, 'play', playCallback);
        onBuffer && off(video, 'waiting', onBuffer);
        onBufferEnd && off(video, 'playing', onBufferEnd);
        onDisablePIP && off(video, 'leavepictureinpicture', onDisablePIP);
        onDuration && off(video, 'durationchange', onDuration);
        onEnablePIP && off(video, 'enterpictureinpicture', onEnablePIP);
        onError && off(video, 'error', onError);
        onPresentationModeChange && off(video, 'webkitpresentationmodechanged', onPresentationModeChange);
        onReady && off(video, 'canplay', onReady);
        onSeek && off(video, 'seeked', onSeek);

        if (!shouldUseHLS(src) && onReady) off(video, 'canplay', onReady);
      },
      [
        onBuffer,
        onBufferEnd,
        onDisablePIP,
        onDuration,
        onEnablePIP,
        onEndedCallback,
        onError,
        onPresentationModeChange,
        onReady,
        onSeek,
        pauseCallback,
        playCallback,
        shouldUseHLS,
        src,
      ],
    );

    const initSubtitleTrackSwitch = React.useCallback(
      (hls: Hls) => {
        if (initSubititlesRef.current) return;
        if (subtitlesOnStart) {
          hls.subtitleDisplay = true;
          hls.subtitleTrack = 0;
          initSubititlesRef.current = true;

          onInitTrackSwitch && onInitTrackSwitch(0);

          return;
        } else {
          hls.subtitleDisplay = false;
          hls.subtitleTrack = -1;
          initSubititlesRef.current = true;

          onInitTrackSwitch && onInitTrackSwitch(-1);

          return;
        }
      },
      [onInitTrackSwitch, subtitlesOnStart],
    );

    // listeners
    React.useEffect(() => {
      const video = videoRef.current;
      if (!video) return;

      addListeners(video);

      return () => {
        if (!video) return;
        removeListeners(video);
      };
    }, [addListeners, removeListeners, videoRef]);

    // play/pause
    React.useEffect(() => {
      const video = videoRef.current;
      if (!video || typeof playing === 'undefined') return;

      if (playing && !playingRef.current) video.play();
      if (!playing && playingRef.current && video.played.length > 0) video.pause();
    }, [playing, videoRef]);

    // mute/unmute
    React.useEffect(() => {
      const video = videoRef.current;
      if (!video || typeof muted === 'undefined') return;

      if (muted && !mutedRef.current) mute();
      if (!muted && mutedRef.current) unmute();
    }, [mute, muted, unmute, videoRef]);

    // enable/disable PIP
    React.useEffect(() => {
      if (typeof pip === 'undefined') return;
      if (pip && !pipRef.current) enablePIP();
      if (!pip && pipRef.current) disablePIP();
    }, [disablePIP, enablePIP, pip]);

    // volume
    React.useEffect(() => {
      const video = videoRef.current;
      if (video && typeof volume !== 'undefined') video.volume = volume;
    }, [videoRef, volume]);

    // playback rate
    React.useEffect(() => {
      const video = videoRef.current;

      if (video && typeof playbackRate !== 'undefined') {
        try {
          video.playbackRate = playbackRate;
        } catch (error) {
          onError && onError(error);
        }
      }
    }, [onError, playbackRate, videoRef]);

    // progress interval
    React.useEffect(() => {
      const intervalId = setInterval(() => onProgress && progressCallback(), progressInterval || 1000);

      return () => {
        clearInterval(intervalId);
      };
    }, [onProgress, progressCallback, progressInterval]);

    // subtitles track
    React.useEffect(() => {
      const hls = hlsRef.current;
      if (!hls || typeof subtitleTrackId !== 'number') return;

      if (subtitleTrackId < 0) {
        hls.subtitleDisplay = false;
        hls.subtitleTrack = -1;

        subtitlesTrackRef.current = -1;
      }

      if (subtitleTrackId >= 0) {
        hls.subtitleDisplay = true;
        hls.subtitleTrack = subtitleTrackId;

        subtitlesTrackRef.current = subtitleTrackId;
      }
    }, [subtitleTrackId]);

    React.useEffect(() => {
      const video = videoRef.current;
      let hls: Hls | null = null;
      let HlsConstructor: typeof Hls | null;
      if (!video || !src) return;

      const applyVideoSrc = async () => {
        if (canPlayHLS(video) && IS_IOS) {
          if (src !== srcRef.current) {
            video.src = src;
            srcRef.current = src;
            video.load();
          }
        } else if (canPlayHLS(video) === 'probably' && !IS_IOS) {
          if (src !== srcRef.current) {
            onReady && on(video, 'canplay', onReady);
            video.src = src;
            srcRef.current = src;
            video.play();
          }
        } else if (shouldUseHLS(src)) {
          HlsConstructor = (await import('hls.js')).default;

          if (HlsConstructor.isSupported()) {
            // This will run in all other modern browsers
            hls = new HlsConstructor();
            hlsRef.current = hls;

            hls.loadSource(src);
            hls.attachMedia(video);

            onReady && hls.on('hlsManifestParsed' as keyof HlsListeners, onReady);
            onError && hls.on('hlsError' as keyof HlsListeners, onError);
            onSubtitleTrackUpdate && hls.on('hlsSubtitleTracksUpdated' as keyof HlsListeners, onSubtitleTrackUpdate);

            hls.on('hlsSubtitleTrackSwitch' as keyof HlsListeners, () => hls && initSubtitleTrackSwitch(hls));
          } else {
            logger.error(
              'This is an old browser that does not support MSE https://developer.mozilla.org/en-US/docs/Web/API/Media_Source_Extensions_API',
            );
          }
        } else {
          logger.error(
            'This is an old browser that does not support MSE https://developer.mozilla.org/en-US/docs/Web/API/Media_Source_Extensions_API',
          );
        }
      };

      const muxMonitor = async () => {
        if (!muxConfig) return;

        const { env_key, player_name, meta, ...rest } = muxConfig;

        // if hls is being used, pass that to mux-embed
        if (hls) {
          (await import('mux-embed')).default.monitor(video, {
            hlsjs: hls,
            Hls: HlsConstructor,
            ...rest,
            data: {
              env_key, // required
              player_name,
              player_init_time: performance.now(),
              ...meta,
            },
          });
        } else {
          // otherwise, just monitor normally
          (await import('mux-embed')).default.monitor(video, {
            ...rest,
            data: {
              env_key, // required
              player_name,
              player_init_time: performance.now(),
              ...meta,
            },
          });
        }
      };

      applyVideoSrc()
        .then(async () => await muxMonitor())
        .catch(() => toast.error('Something went wrong with the video playback'));

      return () => {
        if (hls) {
          hls.off('hlsManifestParsed' as keyof HlsListeners, onReady);
          onError && hls.off('hlsError' as keyof HlsListeners, onError);
          onSubtitleTrackUpdate && hls.off('hlsSubtitleTracksUpdated' as keyof HlsListeners, onSubtitleTrackUpdate);

          hls.off('hlsSubtitleTrackSwitch' as keyof HlsListeners, () => hls && initSubtitleTrackSwitch(hls));

          hlsRef.current = null;
          hls.destroy();
        }
      };

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [src, videoRef]);

    // eslint-disable-next-line jsx-a11y/media-has-caption
    return <video ref={videoRef} {...props} />;
  },
);

ForwardRefPlayer.displayName = 'ForwardRefPlayer';

export const Player = React.memo(ForwardRefPlayer);
