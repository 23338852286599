import * as React from 'react';

import Script from 'next/script';

export const BrandzookaPixel = React.memo(() => {
  return (
    <Script
      id='brandzooka-pixel'
      onLoad={() => {
        (window as any).ttd_dom_ready(function () {
          if (typeof (window as any).TTDUniversalPixelApi === 'function') {
            const universalPixelApi = new (window as any).TTDUniversalPixelApi();
            universalPixelApi.init('8511u8z', ['61d3jw7'], 'https://insight.adsrvr.org/track/up');
          }
        });
      }}
      src='https://js.adsrvr.org/up_loader.1.1.0.js'
    />
  );
});

BrandzookaPixel.displayName = 'BrandzookaPixel';
